import FileUpload from "components/molecules/FileUpload/FileUpload";
import styles from "./RequestTracking.module.scss";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import React, { useEffect } from "react";
import { downloadRequestProcessedReport, RequestDataHash, requestsTrackingApi, setSort } from "slices/requestTrackingSlice";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "hooks/mixpanel_hook";
import { resetAuth } from "slices/authSlice";
import { resetFilter } from "slices/filtersSlice";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { downloadFile } from "helpers/utils";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import Loader from "components/atoms/Loader/Loader";
import TableManager from "components/organisms/TableManager/TableManager";
import { requestTrackingColumns } from "./RequestTracking.constant";

const PAGE_TITLE = 'Requests Tracking';

const RequestTracking:React.FC = () => {

  const dispatch = useAppDispatch();
  const requestTrackingData = useAppSelector((state) => state.trackRequest);

  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);

  const handleSort = (column: keyof RequestDataHash) => {
    const direction = column === requestTrackingData.sortedColumn && requestTrackingData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };

  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT, 
      {
        page_link: window.location.href,
        page_title: PAGE_TITLE
      }
    );
  }, []);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(requestsTrackingApi({
        headers: headers
      })).unwrap();
      setPage(0);
      setLoaderActive(false);
      setFilterApplied(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const downloadResultsReport = async (id: number | string) => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      const downloadApi = () => dispatch(downloadRequestProcessedReport({
        requestId: id,
        headers: headers
      }));
      const success = await downloadFile({downloadApi, fileName: 'report.csv', fileType: 'text/csv'})
      if(success){
        triggerMixpanelEvent(
          MIXPANEL_EVENT_TYPES.DOWNLOAD,
          {
            report_name: PAGE_TITLE,
            download_type: 'Processed Request Report'
          }
        );
      }
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  useEffect(() => {
    if(filterApplied){  
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label={PAGE_TITLE} />
      <Loader show={loaderActive} />
      {!loaderActive && (
        <TableManager<RequestDataHash>
          data={requestTrackingData?.requestsData || []}
          columns={requestTrackingColumns({downloadReport: downloadResultsReport})}
          sortedColumn={requestTrackingData.sortedColumn}
          handleSort={handleSort}
          sortDirection={requestTrackingData.sortDirection}
          showPagination
          totalCount={requestTrackingData?.requestsData?.length || 0}
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      )}
    </Box>
  );
}

export default RequestTracking;