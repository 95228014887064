import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { RootState } from "../../store";
import styles from "./PenaltyReports.module.scss";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import { resetAuth } from "../../slices/authSlice";
import { fetchOrderCancellationPenalty } from "../../slices/orderCancellationPenaltySlice";
import Loader from "components/atoms/Loader/Loader";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { fixedColumn, orderCancellationPenaltyColumns } from "./OrderCancellationPenalty.constant";
import { DataCol, DataRow } from "components/atoms/ChartData/ChartData";
import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableManager from "../../components/organisms/TableManager/TableManager";
import { DEFAULT_BLACK_COLOR, ROUTE_REPORT_MAP, ROUTES } from "../../constants";
import MonthSelector from "components/molecules/MonthSelector/MonthSelector";
import DateRangeSelector, {
  DateRangeLabel,
} from "components/molecules/DateRangeSelector/DateRangeSelector";
import {
  resetFilter,
  setOrderCancallationPenaltyReportMonthFilter,
} from "../../slices/filtersSlice";

const OrderCancellationPenalty: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Local state
  const [trackingProgress, setTrackingProgress] = useState<{ label: string; value: string }[]>([]);
  const [loaderActive, setLoaderActive] = useState(true);
  const [filterApplied, setFilterApplied] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [showMonthOptions, setShowMonthOptions] = useState(false);
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

    // Handlers
    const handleMonthSelection = useCallback(
      (month: number, year: number) => {
        dispatch(setOrderCancallationPenaltyReportMonthFilter([{ month, year }]));
        setFilterApplied(true);
      },
      [dispatch]
    );

    const handleClear = useCallback(() => {
      const currentDate = new Date();
      dispatch(
        setOrderCancallationPenaltyReportMonthFilter([
          {
            month: currentDate.getMonth() + 1,
            year: currentDate.getFullYear(),
          },
        ])
      );
      setFilterApplied(true);
    }, [dispatch]);

    const handleDisabledMonths = useCallback((month: number, year: number) => {
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();
      return (
        year < 2024 ||
        year > currentYear ||
        (year === currentYear && month > currentMonth)
      );
    }, []);


  // Constants
  const PAGE_TITLE = "Penalty Report";
  const TAB_OPTIONS = [
    { label: "Out of Stock", route: ROUTES.OOS_PENALTY },
    { label: "Returns", route: ROUTES.RETURN_PENALTY },
    { label: "Order Cancellation", route: ROUTES.ORDER_CANCELLATION_PENALTY },
  ];

  // Redux state
  const { summary, order_item_data, loading, error } = useAppSelector(
    (state: RootState) => state.orderCancellationPenalty
  );

  const selectedMonths = useAppSelector(
    (state: RootState) => state.filters.oosPenaltyReportMonthFilter
  );


  // Fetch data
  useEffect(() => {
    const fetchData = async () => {
      const userInfo = localStorage.getItem("userInfo");
      if (!userInfo) {
        dispatch(resetAuth());
        dispatch(resetFilter());
        return;
      }

      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

      try {
        await dispatch(
          fetchOrderCancellationPenalty({ month: selectedMonths[0].month, year: selectedMonths[0].year, headers, })
        ).unwrap();
      } catch (error) {
        console.error("Error fetching order cancellation penalties:", error);
      } finally {
        setLoaderActive(false);
      }
    };

    fetchData();
  }, [dispatch, selectedMonths]);


  const handleTabChange = useCallback(
    (newValue: string) => {
      triggerMixpanelEvent(MIXPANEL_EVENT_TYPES.REPORT_VIEWED, {
        report_name: ROUTE_REPORT_MAP[newValue],
        source: "Navigation",
      });
      navigate(newValue);
    },
    [navigate]
  );

  function getMonthAbbreviation(monthNumber: number) {
    const date = new Date(0, monthNumber - 1); // Month is zero-indexed in Date
    return date.toLocaleString("en-US", { month: "short" });
  }
  let label = `${getMonthAbbreviation(selectedMonths[0].month)} ${
    selectedMonths[0].year
  }`;


  const renderPaymentSummary = () => {
    if (loaderActive) return null;

    const summaryData = [
      ...(summary?.breakdown_by_reason?.map((reason, index) => [
        { label: reason.reason || `Reason ${index + 1}`, value: reason.count ?? "N/A" },
        { label: `${reason.reason || `Reason ${index + 1}`} Penalty`, value: reason.penalty ?? "N/A" },
      ]) ?? []).flat(),
      { label: "Total Orders Cancelled", value: summary?.total_order_items_cancelled || 0 },
      { label: "Penalty Amount", value: summary?.total_penalty_amount || 0 },
    ];

    return (
      <Box className={styles.paymentSummaryWrapper}>
        <DataRow className={styles['payment-summary']} separator>
          {summaryData.map(({ label, value }, index) => (
            <DataCol
              key={index}
              colClass={styles['individual-payment']}
              labelClass={styles["payment-cycle"]}
              valueClass={`${styles["payment-value"]} ${label === "Penalty Amount" ? styles["red-pill"] : ""}`}
              label={label}
              value={value}
            />
          ))}
        </DataRow>
      </Box>
    );
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label={PAGE_TITLE}>
        <MonthSelector
          onClick={handleMonthSelection}
          showCalendar={showMonthOptions}
          setShowCalendar={setShowMonthOptions}
          disabledMonths={handleDisabledMonths}
          selectedMonths={selectedMonths}
          onClear={handleClear}
          onSubmit={() => {
            setFilterApplied(true);
            setShowMonthOptions(false);
          }}
        >
          <DateRangeLabel
            noDateLabel={label}
            onClick={() => {
              setShowMonthOptions(true);
            }}
          />
        </MonthSelector>
      </MainHeader>
      <Box className={styles.pageTabsWrapper}>
        <Tabs
          value={window.location.pathname}
          onChange={(event, newValue) => handleTabChange(newValue)}
          TabIndicatorProps={{ sx: { backgroundColor: "#4F76FE" } }}
        >
          {TAB_OPTIONS.map(({ label, route }) => (
            <Tab
              key={route}
              label={label}
              value={route}
              style={{
                textTransform: "none",
                fontWeight: 400,
                color:
                  window.location.pathname === route
                    ? "#3361FF"
                    : DEFAULT_BLACK_COLOR,
              }}
            />
          ))}
        </Tabs>
      </Box>

      {renderPaymentSummary()}

      <Loader show={loaderActive} />

      {!loaderActive && (
        <TableManager
          columns={orderCancellationPenaltyColumns({
            showTrackingProgress: (steps) => setTrackingProgress(steps),
            isMobile,
          })}
          data={order_item_data  || [] }
          showPagination
          totalCount={order_item_data?.length || 0}
          sortedColumn={null}
          handleSort={() => {}}
          sortDirection={"desc"}
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          fixedColumn={isMobile ? fixedColumn : undefined}
        />
      )}
    </Box>
  );
};

export default OrderCancellationPenalty;
