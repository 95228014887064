import { ProductVariant } from "../../slices/oosPenaltySlice";
import { ColumnPropType, FixedColumnPropType } from "../../components/organisms/TableManager/TableManager";
import styles from './PenaltyReports.module.scss';
import ProductThumbnail, { ProductThumbnailLite } from "../../components/atoms/ProductThumbnail/ProductThumbnail";

// Type for Columns Property
type OosReportColumnsPropType = {
  isMobile: boolean;
  showTrackingProgress: (steps: { label: string; value: string }[]) => void;
};

// Helper Component for Product Thumbnails
const RenderProductThumbnail = ({
  sku,
  title = '',
  src = '',
  fbv_enabled = false,
  lite = false,
}: {
  sku: string;
  title?: string;
  src?: string;
  fbv_enabled?: boolean;
  lite?: boolean;
}) => {
  return lite ? (
    <ProductThumbnailLite sku={sku} src={src} fbv_enabled={fbv_enabled} />
  ) : (
    <ProductThumbnail sku={sku} title={title} src={src} fbv_enabled={fbv_enabled} />
  );
};

// Fixed Column Configuration
export const fixedColumn: FixedColumnPropType<ProductVariant> = {
  label: "Product / SKU",
  accessor: "sku",
  width: 120,
  maxWidth: 120,
  thresold: 170, // 290 - 120
  cell: ({ row }) => (
    <RenderProductThumbnail
      sku={row.sku || ""}
      src={row.image_src || ""}
      fbv_enabled={row.fbv_enabled}
      lite={true}
    />
  ),
};

// Dynamic Column Configuration
export const oosPenaltyColumns = (
  props: OosReportColumnsPropType
): ColumnPropType<ProductVariant>[] => [
  {
    label: "Product / SKU",
    accessor: "sku",
    minWidth: 480,
    width: 290,
    cell: ({ row }) => (
      <RenderProductThumbnail
        sku={row.sku || ""}
        title={row.title || ""}
        src={row.image_src || ""}
        fbv_enabled={row.fbv_enabled}
      />
    ),
  },
  {
    label: "Number of OOS days",
    accessor: "oos_days",
    minWidth: 390,
  },
  {
    label: "Number of OOS days in Last Month",
    accessor: "oos_days_last_month",
    minWidth: 390,
  },
];
