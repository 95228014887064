import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { StringLiteral } from "typescript";
import { sortRecords } from "../helpers/utils";

type IndividualPaymentData = {
  amount: string;
  payment_cycle: string;
}

export type VendorReconDataHash = {
  unique_key: number;
  payment_date: string;
  payment_id: string;
  payment_cycle: string;
  status: string;
  bank_reference_id: string;
  invoice_number: string
  payment_amount: number;
  awb_count: number;
  report_download_link_present: boolean;
  invoices_present: boolean;
}

type PaymentCycle = {
  start_date: string;
  end_date: string;
}

type PaymentReportDataHash = {
  last_payment_data: IndividualPaymentData;
  active_payment_data: IndividualPaymentData;
  next_payment_data: IndividualPaymentData;
  vendor_recon_data: VendorReconDataHash[];
}

type PaymentReportData = {
  payment_data: PaymentReportDataHash;
  available_cycles: PaymentCycle[];
}

type PaymentReportApiState = {
  paymentReportData?: PaymentReportData | null;
  paymentReportStatus: "idle" | "loading" | "failed";
  paymentReportError: string | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof VendorReconDataHash | null;
};

const initialState: PaymentReportApiState = {
  paymentReportData: null,
  paymentReportStatus: "loading",
  paymentReportError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};

const formatRequest = (requestFilters: any, download:boolean = false): string => {
  let url = `/forge/payments/vendor_recon_report${download ? '.csv' : ''}?`;
  url += `q[due_date_gteq]=${requestFilters.reconReportPaymentStartDateFilter}&`;
  url += `q[due_date_lteq]=${requestFilters.reconReportPaymentEndDateFilter}&`;
  for(let i = 0 ; i < requestFilters.reconReportStatusFilter.length ; i++){
    const status = requestFilters.reconReportStatusFilter[i];
    url += `q[status_in][]=${status}&`;
  }
  for(let i = 0 ; i < requestFilters.reconReportPaymentCycleStartDateFilter.length ; i++){
    const startDate = requestFilters.reconReportPaymentCycleStartDateFilter[i];
    url += `q[start_date_in][]=${startDate}&`;
  }
  url += `q[payment_id_eq]=${requestFilters.reconReportPaymentIdFilter}&`;
  url += `q[vendor_payment_histories_bank_reference_id_eq]=${requestFilters.reconReportBankReferenceIdFilter}&`;
  url += `q[vendor_payment_invoices_invoice_number_eq]=${requestFilters.reconReportInvoiceNumberFilter}`;
  return url;
}

export const paymentReportApi = createAsyncThunk(
  "paymentReportApi",
  async ({headers, ...requestFilters} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestFilters);
    try {
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const paymentReportDownloadReportApi = createAsyncThunk(
  "paymentReportDownloadReportApi",
  async ({headers, ...requestFilters} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestFilters, true);
    try {
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const downloadVendorPaymentDetailReportApi = createAsyncThunk(
  "downloadVendorPaymentDetailReportApi",
  async ({headers, vendorPaymentId} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get(`/forge/payments/vendor_payment_report_url?vendor_payment_id=${vendorPaymentId}`, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const getVendorInvoicesApi = createAsyncThunk(
  "getVendorInvoicesApi",
  async ({headers, vendorPaymentId} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get(`/forge/payments/vendor_invoice_urls?vendor_payment_id=${vendorPaymentId}`, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

const paymentReportSlice = createSlice({
  name: 'paymentReport',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof VendorReconDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.paymentReportData && column){
        const sorted = sortRecords(state.paymentReportData.payment_data.vendor_recon_data || [], column, direction);
        state.paymentReportData.payment_data.vendor_recon_data = sorted as VendorReconDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(paymentReportApi.pending, (state) => {
        state.paymentReportStatus = "loading";
        state.paymentReportError = null;
      })
      .addCase(
        paymentReportApi.fulfilled,
        (state, action: PayloadAction<PaymentReportData>) => {
          state.paymentReportStatus = "idle";
          state.paymentReportData = action.payload;
        }
      )
      .addCase(paymentReportApi.rejected, (state, action) => {
        state.paymentReportStatus = "failed";
        if (action.payload) {
          state.paymentReportError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.paymentReportError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = paymentReportSlice.actions;
export default paymentReportSlice.reducer;
