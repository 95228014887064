import { DEFAULT_BLACK_COLOR } from "../../../constants";
import { formatCurrency } from "../../../helpers/formatUtils";
import { getPercentVal } from "../../../helpers/utils";
import "./CategoryChartLegend.css"

type CategoryChartLegendPropType = {
  showRevenue: boolean;
  id: number;
  total: number;
  totalRevenue: number;
  category: string;
  revenue: number;
  count: number;
}

const chartColorPallete = ['#839A51' , '#DE8C2B' , '#4F76FE' , DEFAULT_BLACK_COLOR];

const CategoryChartLegend = (props: CategoryChartLegendPropType) => {
  const {revenue, count, category, showRevenue, id, total, totalRevenue} = props;
  const percentValue = showRevenue ?  getPercentVal(revenue, totalRevenue) : getPercentVal(count, total);
  const _value = showRevenue ? (revenue >= 1e5 ? `₹${(revenue / 1e5).toFixed(2)}L` : formatCurrency(revenue)) : count;
  return(
    <div className="custom-pie-legend-el">
      <div className="color-code" style={{backgroundColor: chartColorPallete[id % (chartColorPallete.length)]}}/>
      <div className="label-section">
        <p className="l1">{`${category.slice(0, 12) + (category.length > 12 ? '...' : '')} (${percentValue.toFixed(2)}%)`}</p>
        <p className="l2">{_value}</p>
      </div>
    </div>
  );
}


export default CategoryChartLegend;