import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";
import { setGstrReportPaymentCycleStartDateFilter } from "./filtersSlice";

type PaymentCycle = {
  start_date: string;
  end_date: string;
}

export type GSTRDataHash = {
  unique_key: string,
  payment_cycle: string | null,
  order_date: string | null,
  order_number: string | null,
  customer_name: string | null,
  customer_state: string | null ,
  customer_gst_number: number | null,
  invoice_number: string | null,
  order_status: string | null,
  sku: string | null,
  taxable_value_of_goods: number  | null,
  gst_on_goods: number  | null,
  gst_rate: number | null,
  taxable_value_of_cod: number | null,
  gst_on_cod: number | null,
  total_invoice_value: number | null,
  tds: number | null,
  tcs: number | null,
  seller_state: string | null,
  invoice_date: string | null
}

type PaymentData = {
  gstr_data: GSTRDataHash[]
}

type GSTRReportData = {
  payment_data: PaymentData;
  available_cycles: PaymentCycle[];
}

type GSTRReportApiState = {
  gstrReportData?: GSTRReportData | null;
  gstrReportStatus: "idle" | "loading" | "failed";
  gstrReportError: string | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof GSTRDataHash | null;
};

const initialState: GSTRReportApiState = {
  gstrReportData: null,
  gstrReportStatus: "loading",
  gstrReportError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};

const formatRequest = (requestFilters: any, download:boolean = false): string => {
  let url = `/forge/payments/gstr_report${download ? '.csv' : ''}?`;
  url += `q[parent_of_Invoice_type_shipping_package_shipping_order_items_order_item_order_order_number_eq]=${requestFilters.gstrReportOrderNumberFilter}&`;
  url += `q[parent_of_Invoice_type_shipping_package_invoice_code_eq]=${requestFilters.gstrReportInvoiceNumberFilter}&`;
  url += `q[parent_of_Invoice_type_shipping_package_shipping_order_items_order_item_product_variant_sku_eq]=${requestFilters.gstrReportSKUFilter}&`;

  for(let i = 0 ; i < requestFilters.gstrReportPaymentCycleStartDateFilter.length ; i++){
    const startDate = requestFilters.gstrReportPaymentCycleStartDateFilter[i];
    url += `q[vendor_payment_start_date_in][]=${startDate}&`;
  }
  return url;
}

export const gstrReportApi = createAsyncThunk(
  "gstrReportApi",
  async ({headers, ...requestFilters} : any, { rejectWithValue, dispatch }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestFilters);
    try {
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      const default_selected_cycle = response.data.default_selected_cycle as null | string;
      if(default_selected_cycle){
        dispatch(setGstrReportPaymentCycleStartDateFilter([default_selected_cycle]));
      }

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const gstrReportDownloadReportApi = createAsyncThunk(
  "gstrReportDownloadReportApi",
  async ({headers, ...requestFilters} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestFilters, true);
    try {
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

const gstrReportSlice = createSlice({
  name: 'gstrReport',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof GSTRDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.gstrReportData && column){
        const sorted = sortRecords(state.gstrReportData.payment_data.gstr_data || [], column, direction);
        state.gstrReportData.payment_data.gstr_data = sorted as GSTRDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(gstrReportApi.pending, (state) => {
        state.gstrReportStatus = "loading";
        state.gstrReportError = null;
      })
      .addCase(
        gstrReportApi.fulfilled,
        (state, action: PayloadAction<GSTRReportData>) => {
          state.gstrReportStatus = "idle";
          state.gstrReportData = action.payload;
        }
      )
      .addCase(gstrReportApi.rejected, (state, action) => {
        state.gstrReportStatus = "failed";
        if (action.payload) {
          state.gstrReportError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.gstrReportError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = gstrReportSlice.actions;
export default gstrReportSlice.reducer;
