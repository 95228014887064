import { ShippingDataHash } from "../../slices/rtoReportSlice";
import {ColumnPropType, FixedColumnPropType} from "../../components/organisms/TableManager/TableManager";
import styles from "./RTOReport.module.scss";
import ProductThumbnail, { ProductThumbnailLite } from "../../components/atoms/ProductThumbnail/ProductThumbnail";
import { lakhFormat } from "../../helpers/formatUtils";
import MultiStepProgressPopover from "../../components/molecules/MultiStepPopover/MultiStepProgressPopover";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type RtoReportColumnsPropType = {
  isMobile: boolean;
  showTrackingProgress: (steps: {label: string, value: string}[]) => void;
}


export const fixedColumn:FixedColumnPropType<ShippingDataHash> = {
  label: 'Product / SKU',
  accessor: 'product_variant_sku_code',
  width: 120,
  maxWidth: 120,
  thresold: (270 - 120), // calculated based on first colum of table
  cell: ({row}) => <ProductThumbnailLite 
    sku={row.product_variant_sku_code || ''}
    src={row.product_image_src}
    fbv_enabled={row.fbv_enabled}
    fbv_warehouse={row.fbv_warehouse}
  />
}

export const rtoReportColumns = (props: RtoReportColumnsPropType):ColumnPropType<ShippingDataHash>[] => [
  {
    label: 'Product / SKU',
    accessor: 'product_variant_sku_code',
    width: 270,
    minWidth: 270,
    cell: ({row}) => <ProductThumbnail 
      sku={row.product_variant_sku_code || ''}
      title={row.product_title}
      src={row.product_image_src}
      fbv_enabled={row.fbv_enabled}
      fbv_warehouse={row.fbv_warehouse}
    />
  },
  {
    label: 'Order No',
    accessor: 'order_number',
    minWidth: 110,
  },
  {
    label: 'Shipped On',
    accessor: 'unique_key',
    minWidth: 150,
    cell: ({row}) => {
      return <>
        {row.shipping_package_data.dispatched_at}
      </>
    }
  },
  {
    label: 'Delivered Date',
    accessor: 'unique_key',
    minWidth: 150,
    cell: ({row}) => {
      return <>
        {row.shipping_package_data.delivered_at || 'Pending'}
      </>
    }
  },
  {
    label: 'Quantity',
    accessor: 'shipping_order_item_quantity',
    minWidth: 80,
    sortable: true,
  },
  {
    label: 'Order Value',
    accessor: 'unique_key',
    minWidth: 80,
    cell: ({row}) => {
      return <>
        {lakhFormat(row.product_variant_price * row.shipping_order_item_quantity)}
      </>
    }
  },
  {
    label: 'Logistic Partner',
    accessor: 'unique_key',
    minWidth: 80,
    cell: ({row}) => {
      return <>
        {row.shipping_package_data.shipping_provider}
      </>
    }
  },
  {
    label: 'Payment',
    accessor: 'order_payment_mode',
    minWidth: 90,
  },
  {
    label: 'RTO Status',
    accessor: 'unique_key',
    minWidth: 120,
    cell: ({row}) => {
      return (
        <div className={styles.orderAWBDetails}>
          <div className={styles.awbStatus}>
            <div>{row.shipping_package_data.courier_status === 'RTO' ? 'Delivered' : 'In Transit'}</div>
            <div className={styles.awbNumber}>{'AWB: ' + row.shipping_package_data.tracking_number}</div>
          </div>
          {props.isMobile ? (
           <InfoOutlinedIcon fontSize="small" className={styles.orderStatus} onClick={() => {
            props.showTrackingProgress([
              {label: 'In Transit:', value: row.shipping_package_data.dispatched_at?.split(',')[0] || ''},
              {label: 'Delivered:', value: row.shipping_package_data.delivered_at?.split(',')[0] || ''},
            ]);
          }} />
          ): (
            <MultiStepProgressPopover
              steps={[
                {label: 'In Transit:', value: row.shipping_package_data.dispatched_at?.split(',')[0] || ''},
                {label: 'Delivered:', value: row.shipping_package_data.delivered_at?.split(',')[0] || ''},
              ]}
            >
              <InfoOutlinedIcon fontSize="small" className={styles.orderStatus} />
            </MultiStepProgressPopover>
          )}
        </div>
      );
    }
  }
];