import styles from "./Dashboard.module.scss";
import { useAppSelector, useAppDispatch } from "../../hooks/redux-hooks";
import { Link} from "react-router-dom";
import { Typography } from "@mui/material";
import LightTooltip from "../../components/atoms/LightTooltip/LightTooltip";
import { resetFilter, setEndDateFilter, setOrderReportDelayedShipmentFilter, setOrderReportOrderCreatedAtEndDateFilter, setOrderReportOrderCreatedAtStartDateFilter, setStartDateFilter } from "../../slices/filtersSlice";
import { LineChart } from "@mui/x-charts";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { csx } from "helpers/utils";
import ChartHeader from "components/atoms/ChartHeader/ChartHeader";
import { ROUTE_REPORT_MAP } from "../../constants";


const OTSChart = () => {

  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);

  const startDate = useAppSelector((state) => state.filters.startDateFilter);
  const endDate = useAppSelector((state) => state.filters.endDateFilter);
  
  const dispatch = useAppDispatch();
  const dashboardData = useAppSelector((state) => state.dashboard);

  const handleChartClick = (chart_type: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.CHART_CLICK,
      {chart_type}
    );
  }

  const triggerReportViewedEvent = (path: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_VIEWED,
      {
        report_name: ROUTE_REPORT_MAP[path],
        source: 'Dashboard'
      }
    );
  }

  const chartName= 'ots-chart';
  return (

    <div className={csx(styles['chart-report-wrapper'], styles[chartName])} onClick={() => handleChartClick('ots_summary')}>
      {dashboardData.productSalesStatus === 'loading' && <p className={styles['load-err-txt']}>Loading...</p>}
      {dashboardData.productSalesStatus === 'failed' && <p className={styles['load-err-txt']}>Error: {dashboardData.otsDataError}</p>}
      {dashboardData.productSalesStatus === 'idle' && dashboardData.otsData && (
        <>
          <ChartHeader
            headerTxt={'On-Time Shipment Rate'}
            tooltipTxt={'Percentage of shipments that are shipped in expected time frame.'}
            link={'/order-tracking'}
            onClick={() => {
              dispatch(resetFilter());
              dispatch(setStartDateFilter(startDate));
              dispatch(setEndDateFilter(endDate));
              dispatch(setOrderReportOrderCreatedAtStartDateFilter(startDate));
              dispatch(setOrderReportOrderCreatedAtEndDateFilter(endDate));
              dispatch(setOrderReportDelayedShipmentFilter(true));
              triggerReportViewedEvent('/order-tracking');
            }}
          />
          {dashboardData.otsData?.otsDays.length > 0 ? (
            <div className={styles['chart-total_percent']}>
              <div>
                <div className={csx(styles['color-code'], styles['seller-color-code'])} />
                <p>Seller: <b>{(dashboardData.otsData?.totalSellerOtsPercentage)}</b></p>  
              </div>
              {fbv && (
                <div>
                  <div className={csx(styles['color-code'], styles['fbv-color-code'])} />
                  <p>FBV: <b>{(dashboardData.otsData?.totalFbvOtsPercentage)}</b></p>  
                </div>
              )}
            </div>
          ): (
            <p className={styles['no-ots-data-txt']}>Shipment in the selected date range are not yet shipped and hence the OTS report is still being calculated.</p>
          )}
          {dashboardData.otsData?.otsDays.length > 0 && (
            <LineChart
              xAxis={[{ data: dashboardData.otsData?.otsDays, scaleType: 'band' }]}
              series={[
                {
                  data: dashboardData.otsData.onTimeSellerOtsData,
                  valueFormatter : (v) => {
                    return v === null ? 'NA' : `${v}%`
                  },
                  showMark: dashboardData.otsData.sellerDaysCount === 1,
                  connectNulls: true,
                  color: '#33BFFF',
                },
                ...(fbv ? [{
                  data: dashboardData.otsData.onTimeFbvOtsData,
                  valueFormatter : (v: any) => {
                    return v === null ? 'NA' : `${v}%`
                  },
                  showMark: dashboardData.otsData.fbvDaysCount === 1,
                  connectNulls: true,
                  color: '#FEA252',
                }] : [])
              ]}
              height={300}
            />
          )}
        </>
      )}
    </div>
  );
}

export default OTSChart;