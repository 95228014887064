import styles from "./Dashboard.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Link} from "react-router-dom";
import { Typography } from "@mui/material";
import LightTooltip from "../../components/atoms/LightTooltip/LightTooltip";
import HazardIcon from "../../images/hazard.png";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { csx } from "helpers/utils";
import ChartHeader from "components/atoms/ChartHeader/ChartHeader";
import { DataCol, DataRow } from "components/atoms/ChartData/ChartData";
import { RootState } from "store";
import { resetFilter, setEndDateFilter, setStartDateFilter } from "slices/filtersSlice";
import { ROUTE_REPORT_MAP } from "../../constants";


const InventoryChart = () => {

  const dispatch = useAppDispatch();

  const startDateFilter = useAppSelector((state: RootState) => state.filters.startDateFilter);
  const endDateFilter = useAppSelector((state: RootState) => state.filters.endDateFilter);
  
  const dashboardData = useAppSelector((state) => state.dashboard);
  
  const handleChartClick = (chart_type: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.CHART_CLICK,
      {chart_type}
    );
  }

  const triggerReportViewedEvent = (path: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_VIEWED,
      {
        report_name: ROUTE_REPORT_MAP[path],
        source: 'Dashboard'
      }
    );
  }

  const chartName = 'inventory-chart';

  const oos_count = dashboardData.inventorySummary?.oos_count || 0;
  const near_to_oos_count = dashboardData.inventorySummary?.near_to_oos_count || 0;

  return (
    <div className={csx(styles['chart-report-wrapper'], styles[chartName])} onClick={() => handleChartClick('inventory_summary')}>
      {dashboardData.inventorySummaryStatus === 'loading' && <p className={styles['load-err-txt']}>Loading...</p>}
      {dashboardData.inventorySummaryStatus === 'failed' && <p className={styles['load-err-txt']}>Error: {dashboardData.inventorySummaryError}</p>}
      {dashboardData.inventorySummaryStatus === 'idle' && dashboardData.inventorySummary && (
        <>
          <ChartHeader
            headerTxt={'Inventory Summary'}
            tooltipTxt={'Snapshot of the current inventory, including details such as out of stock products, products passed holding quantity.'}
            link={'/soh-report'}
            onClick={() => {
              dispatch(resetFilter());
              dispatch(setStartDateFilter(startDateFilter));
              dispatch(setEndDateFilter(endDateFilter));
              triggerReportViewedEvent('/soh-report')
            }}
          />
          <DataRow
            className={styles['data-row']}
            children={[
              <DataCol
                valueClass={oos_count > 0 ? styles['data-value'] : undefined}
                value={
                  <>
                    {dashboardData.inventorySummary?.oos_count}
                    {oos_count > 0 && <img className={styles['hazard-logo']} src={HazardIcon} alt="Hazard Logo" />}
                  </>
                }
                label={'Number of Out Of Stock (OOS) products'}
              />,
              <DataCol
                valueClass={near_to_oos_count > 0 ? styles['data-value']: undefined}
                value={
                  <>
                    {dashboardData.inventorySummary?.near_to_oos_count}
                    {near_to_oos_count > 0 && <img className={styles['hazard-logo']} src={HazardIcon} alt="Hazard Logo" /> }
                  </>
                }
                label={'Products passed holding quantity'}
              />
            ]}
          />
        </>
      )}
    </div>
  );
}

export default InventoryChart;