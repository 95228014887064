import { LorReportDataHash } from "../../slices/lorReportSlice";
import {ColumnPropType, FixedColumnPropType} from "../../components/organisms/TableManager/TableManager";
import ProductThumbnail, { ProductThumbnailLite } from "../../components/atoms/ProductThumbnail/ProductThumbnail";
import { lakhFormat } from "helpers/formatUtils";
import styles from "./LorReport.module.scss";
import { csx } from "helpers/utils";
import ModalBox from "components/atoms/ModalBox/ModalBox";
import { Box, Button } from "@mui/material";
import ColorButton from "components/atoms/ColorButton/ColorButton";

export const fixedColumn:FixedColumnPropType<LorReportDataHash> = {
  label: 'Product / SKU',
  accessor: 'product_variant_sku_code',
  width: 120,
  maxWidth: 120,
  thresold: (250 - 120), // calculated based on first colum of table
  cell: ({row}) => <ProductThumbnailLite 
    sku={row.product_variant_sku_code || ''}
    src={row.product_image}
    fbv_enabled={row.fbv_enabled}
    fbv_warehouse={row.fbv_warehouse}
  />
}

type LorReportColumnsPropType = {
  isMultiSelect: boolean;
  setPvIds: (selection: (number|string)[]) => void;
  setShowConfirmationPopup: (show: boolean) => void;
}

export const lorReportColumns = (props: LorReportColumnsPropType):ColumnPropType<LorReportDataHash>[] => [
  {
    label: 'Product / SKU',
    accessor: 'product_variant_sku_code',
    minWidth: 250,
    cell: ({row}) => <ProductThumbnail 
      sku={row.product_variant_sku_code || ''}
      title={row.product_title}
      src={row.product_image}
      fbv_enabled={row.fbv_enabled}
      fbv_warehouse={row.fbv_warehouse}
    />
  },
  {
    label: 'ROS / Day (Quantity)',
    accessor: 'rate_of_sales_per_day_quantity',
    minWidth: 200,
    sortable: true,
    align: 'center'
  },
  {
    label: 'ROS / Day (Amount)',
    accessor: 'rate_of_sales_per_day_value',
    minWidth: 200,
    sortable: true,
    align: 'center'
  },
  {
    label: 'Loss Of Revenue',
    accessor: 'lor',
    minWidth: 200,
    sortable: true,
    align: 'center',
    cell: ({value}) => {
      return <>
        {lakhFormat(value)}
      </>
    }
  },
  {
    label: 'OOS Days',
    accessor: 'loss_of_days',
    minWidth: 200,
    sortable: true,
    align: 'center',
    cell: ({value}) => {
      return <>
        {value + ' Days'}
      </>
    }
  },
  {
    label: 'Action',
    accessor: 'unique_key',
    minWidth: 120,
    align: 'center',
    cell: ({value: product_variant_id}) => {
      const {isMultiSelect, setPvIds, setShowConfirmationPopup} = props;
      return (
        <p
          className={csx(styles.removeAction, isMultiSelect ? styles.disabled : undefined)}
          onClick={() => {
            !isMultiSelect && setPvIds([product_variant_id]);
            !isMultiSelect && setShowConfirmationPopup(true);
          }}
        >
          Remove
        </p>
      );
    }
  }
];


type ConfirmationModalPropType = {
  open: boolean;
  onClose: () => void;
  onDiscard: () => void;
  onSubmit: () => void;
  isSingleProduct: boolean;
}

export const ConfirmationModal:React.FC<ConfirmationModalPropType> = (props) => {
  const {open, onClose, onDiscard, onSubmit, isSingleProduct} = props;
  return (
    <ModalBox
        open={open}
        onClose={onClose}
        className={styles.confirmationModal}
      >
        <Box className={styles.modalContent}>
          <p className={styles.txt}>{
            isSingleProduct ? `Are you sure you want to mark this product as Non-Replenishable? Marking this SKU as Non-Replenishable will remove the product from inventory.`
            : `Are you sure you want to mark these products as Non-Replenishable? Marking these SKUs as Non-Replenishable will remove the product from inventory.`
          }</p>
          <div className={styles.confirmFooter}>
            <ColorButton bgColor="#FFF" className={styles.discardBtn} variant="outlined" onClick={onDiscard}>
              Discard
            </ColorButton>
            <ColorButton bgColor="#3361FF" bgHoverColor="#2E58E8" className={styles.submitBtn} onClick={onSubmit}>
              Submit
            </ColorButton>
          </div>
        </Box>
    </ModalBox>
  );
}

type TableActionBarPropType = {
  handleRemoveAction: () => void;
}

export const TableActionBar:React.FC<TableActionBarPropType> = ({handleRemoveAction}) => {
  return (
    <Box className={styles.actionBarContent}>
      <Button className={styles.removeBtn} onClick={handleRemoveAction} variant="outlined">
        Remove
      </Button>
    </Box>
  );
}