import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {Box,  Button,  Fab, useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './IssueTracking.module.scss';
import { IssueDataHash, setSort, trackIssueApi } from "../../slices/issueTrackingSlice";
import Loader from "components/atoms/Loader/Loader";
import { 
  resetFilter,
  setIssueTrackingTicketIdFilter,
  setIssueTrackingTitleFilter,
  setIssueTrackingStatusFilter,
  setIssueTrackingDelayedFilter,
  setIssueTrackingIssueRaisedEndDateFilter,
  setIssueTrackingIssueResolvedStartDateFilter,
  setIssueTrackingIssueResolvedEndDateFilter,
  setIssueTrackingIssueRaisedStartDateFilter,
} from "../../slices/filtersSlice";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { resetAuth } from "../../slices/authSlice";
import TableManager from "../../components/organisms/TableManager/TableManager";
import { issueTrackingColumns } from "./IssueTracking.constant";
import { DateRangeType } from "../../constants";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import DateRangeSelector, { DateRangeLabel } from "components/molecules/DateRangeSelector/DateRangeSelector";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "components/molecules/SearchByFilter/SearchByFilter";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import MultiSelectFilter from "components/molecules/MultiSelectFilter/MultiSelectFilter";
import SwipeableFilters from "components/organisms/SwipeableFilter/SwipeableFilter";
import NamedToggle from "components/atoms/NamedToggle/NamedToggle";
import GetHelpModal from "components/molecules/GetHelpModal/GetHelpModal";
import { useNavigate } from "react-router-dom";
import CheckboxFilter from "components/atoms/CheckboxFilter/CheckboxFilter";
import help_circle_iocn from "images/help_circle_icon.png"

const statusOptions = [
  {label: 'Todo', value: 'todo'},
  {label: 'In Progress', value: 'in_progress'},
  {label: 'On Hold', value: 'on_hold'},
  {label: 'Resolved ', value: 'resolved'},
];


const searchByOptions = [
  {id: 'ticketId', label: 'Ticket ID'},
  {id: 'title', label: 'Title'},
]

const PAGE_TITLE = 'Issue Tracking'

const IssueTracking = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const isTabMode = useMediaQuery(theme.breakpoints.down('md'));

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const trackIssueData = useAppSelector((state) => state.trackIssue);
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [showGetHelpPopup, setGetHelpPopup] = useState<boolean>(false);
  const [showRaisedDate, setShowRaisedDate] = useState<boolean>(true);


  const issueTrackingIssueRaisedStartDateFilter = useAppSelector((state: RootState) => state.filters.issueTrackingIssueRaisedStartDateFilter)
  const issueTrackingIssueRaisedEndDateFilter = useAppSelector((state: RootState) => state.filters.issueTrackingIssueRaisedEndDateFilter)
  const issueTrackingIssueResolvedStartDateFilter = useAppSelector((state: RootState) => state.filters.issueTrackingIssueResolvedStartDateFilter)
  const issueTrackingIssueResolvedEndDateFilter = useAppSelector((state: RootState) => state.filters.issueTrackingIssueResolvedEndDateFilter)
  const issueTrackingTicketIdFilter = useAppSelector((state: RootState) => state.filters.issueTrackingTicketIdFilter)
  const issueTrackingTitleFilter = useAppSelector((state: RootState) => state.filters.issueTrackingTitleFilter)
  const issueTrackingStatusFilter = useAppSelector((state: RootState) => state.filters.issueTrackingStatusFilter)
  const issueTrackingDelayedFilter = useAppSelector((state: RootState) => state.filters.issueTrackingDelayedFilter)

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

  const handleSort = (column: keyof IssueDataHash) => {
    const direction = column === trackIssueData.sortedColumn && trackIssueData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };

  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT, 
      {
        page_link: window.location.href, 
        page_title: PAGE_TITLE
      }
    );
  }, []);

  useEffect(() => {
    if (!issueTrackingIssueRaisedStartDateFilter){
      setShowRaisedDate(false);
    }
  }, []);


  const appliedSearchFilters:AppliedFilterType[] = [
    ...(!!issueTrackingTicketIdFilter ? [{id: 'ticketId', label: 'Ticket ID', value: issueTrackingTicketIdFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!issueTrackingStatusFilter.length ? [{id: 'status', label: 'Status', value: issueTrackingStatusFilter, type: 'multi-select', options: statusOptions}] : []) as AppliedFilterType[],
    ...(!!issueTrackingTitleFilter ? [{id: 'title', label: 'Title', value: issueTrackingTitleFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!issueTrackingDelayedFilter ? [{id: 'delayedIssue', label: 'Delayed Issue', value: issueTrackingDelayedFilter, type: 'checkbox'}] : []) as AppliedFilterType[],
  ];

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(trackIssueApi({
        headers: headers,
        issueTrackingIssueRaisedStartDateFilter,
        issueTrackingIssueRaisedEndDateFilter,
        issueTrackingIssueResolvedStartDateFilter,
        issueTrackingIssueResolvedEndDateFilter,
        issueTrackingTicketIdFilter,
        issueTrackingTitleFilter,
        issueTrackingStatusFilter,
        issueTrackingDelayedFilter
      })).unwrap();
      setPage(0);
      setLoaderActive(false);
      setFilterApplied(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const trackFilterEvent = async () => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_INTERACTION,
      {
        report_name: PAGE_TITLE,
        filter: [
          (showRaisedDate ? 'Issue Raised Date Range' : 'Issue Resolved Date Range'),
          ...appliedSearchFilters.map(f => f.label)
        ]
      }
    )
  }

  const redirectToIssuePage = (id: string | number) => {
    navigate(`/issue-tracking/${id}/show`);
  }

  useEffect(() => {
    if(filterApplied){
      trackFilterEvent();
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  
  const handleStartEndDateSelect = (ranges: DateRangeType) => {
    if(showRaisedDate){
      dispatch(setIssueTrackingIssueRaisedStartDateFilter(ranges.startDate as Date));
      dispatch(setIssueTrackingIssueRaisedEndDateFilter(ranges.endDate as Date));
    }else{
      dispatch(setIssueTrackingIssueResolvedStartDateFilter(ranges.startDate as Date));
      dispatch(setIssueTrackingIssueResolvedEndDateFilter(ranges.endDate as Date));
    }
    setFilterApplied(true);
  };

  const updateDisplayDate = () => {
    if(showRaisedDate){
      setShowRaisedDate(false);
      dispatch(setIssueTrackingIssueRaisedStartDateFilter(undefined));
      dispatch(setIssueTrackingIssueRaisedEndDateFilter(undefined));
      dispatch(setIssueTrackingIssueResolvedStartDateFilter(issueTrackingIssueRaisedStartDateFilter));
      dispatch(setIssueTrackingIssueResolvedEndDateFilter(issueTrackingIssueRaisedEndDateFilter));
    }else{
      setShowRaisedDate(true);
      dispatch(setIssueTrackingIssueRaisedStartDateFilter(issueTrackingIssueResolvedStartDateFilter));
      dispatch(setIssueTrackingIssueRaisedEndDateFilter(issueTrackingIssueResolvedEndDateFilter));
      dispatch(setIssueTrackingIssueResolvedStartDateFilter(undefined));
      dispatch(setIssueTrackingIssueResolvedEndDateFilter(undefined));
    }
    setFilterApplied(true);
  }

  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string[]}) => {
    switch(id){
      case 'ticketId': {
        dispatch(setIssueTrackingTicketIdFilter(value as string)); break;
      }
      case 'title': {
        dispatch(setIssueTrackingTitleFilter(value as string)); break;
      }
      case 'status' : {
        dispatch(setIssueTrackingStatusFilter(value as string[])); break;
      }
      case 'delayedIssue': {
        dispatch(setIssueTrackingDelayedFilter(value as boolean)); break;
      }
    }
    setFilterApplied(true);
  }

  const handleSwipeableDrawerFilters = (idValueMap: Record<string, (string | boolean| string[])>) => {
    for(const [key, value] of Object.entries(idValueMap)){
      switch(key){
        case 'status' : {
          dispatch(setIssueTrackingStatusFilter(value as string[])); break;
        }
        case 'delayedIssue' : {
          dispatch(setIssueTrackingDelayedFilter(value as boolean)); break;
        }
      }
    }
    setFilterApplied(true);
  }

  const onSearchByClear = (id: string) => {
    if(id === 'ticketId' || id === 'title')
      handleFilterValueChange({id, value: ''});
    else if (id === 'status')
      handleFilterValueChange({id, value: []});
    else 
      handleFilterValueChange({id, value: false});
  }

  const onSearchByAllClear = () => {
    dispatch(setIssueTrackingTicketIdFilter(''));
    dispatch(setIssueTrackingTitleFilter(''));
    dispatch(setIssueTrackingStatusFilter([]));
    dispatch(setIssueTrackingDelayedFilter(false));
    setFilterApplied(true);
  }

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="Track Issues" allowBack={isTabMode} >
        {!isMobile && (
          <NamedToggle
            trueLabel={'Issue Resolved'} 
            falseLabel={'Issue Raised'}
            onChange={updateDisplayDate}
            value={!showRaisedDate}
          />
        )}
        <DateRangeLabel
          startDate={showRaisedDate ? issueTrackingIssueRaisedStartDateFilter : issueTrackingIssueResolvedStartDateFilter}
          endDate={showRaisedDate ? issueTrackingIssueRaisedEndDateFilter : issueTrackingIssueResolvedEndDateFilter}
          onClick={() => {setShowDateRangeFilter(true)}}
          of={isMobile ? (showRaisedDate ? 'Issue Raised' : 'Issue Resolved'): undefined}
        />
      </MainHeader>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />

          {!isMobile? (
            <>
              <MultiSelectFilter 
                label={'Status'}
                values={issueTrackingStatusFilter}
                options={statusOptions}
                onSubmit={(values) => {
                  dispatch(setIssueTrackingStatusFilter(values));
                  setFilterApplied(true);
                }}
              />
              <CheckboxFilter
                checked={issueTrackingDelayedFilter} 
                label={<p className={styles.checkboxTxt}>Delayed Issues</p>}
                onChange={(val) => {
                  dispatch(setIssueTrackingDelayedFilter(val));
                  setFilterApplied(true);
                }}
              />
            </>
          ): (
            <Fab variant="extended" size="small" className={styles.filterFAB} onClick={() => setFilterOpen(true)}>
              <FilterAltOutlinedIcon fontSize="small" />
              Filter
            </Fab>
          )}
        </Box>
        {!isMobile ? (
          <Button
            id="download-button"
            className={styles.downloadBtn}
            onClick={() => setGetHelpPopup(true)}
          >
            Raise Issue
          </Button>
        ):(
          <Fab className={styles.downloadFAB} onClick={() => setGetHelpPopup(true)} size='medium' id="download-button">
            <img src={help_circle_iocn} alt="Help Circle Icon" />
          </Fab>
        )}
      </Box>
        <SwipeableFilters
          open={filterOpen}
          onOpen={() => setFilterOpen(true)}
          onClose={() => setFilterOpen(false)}
          onAction={handleSwipeableDrawerFilters}
          multiSelectFilters={[
            {
              id: 'status',
              label: 'Status',
              type: 'multiSelect',
              value: issueTrackingStatusFilter,
              options: statusOptions,
            },
          ]}
          checkboxFilters={[
            {
              id: 'delayedIssue',
              label: 'Delayed Issue',
              type: 'checkbox',
              value: issueTrackingDelayedFilter,
            }
          ]}
        />
      {appliedSearchFilters.length ? (
        <SearchSelections
          appliedFilters={appliedSearchFilters} 
          allClear={onSearchByAllClear} 
          onClear={onSearchByClear}
        />
      ): null}

      <Loader show={loaderActive} />

      {!loaderActive && (
        <TableManager<IssueDataHash>
          data={trackIssueData.trackIssueData || []}
          columns={issueTrackingColumns({redirectToIssuePage})}
          sortedColumn={trackIssueData.sortedColumn}
          handleSort={handleSort}
          sortDirection={trackIssueData.sortDirection}
          showPagination
          totalCount={trackIssueData.trackIssueData?.length || 0}
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={showRaisedDate ? issueTrackingIssueRaisedStartDateFilter: issueTrackingIssueResolvedStartDateFilter}
        endDate={showRaisedDate ? issueTrackingIssueRaisedEndDateFilter: issueTrackingIssueResolvedEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={
          isMobile ? 
          <NamedToggle
            trueLabel={'Issue Resolved'} 
            falseLabel={'Issue Raised'}
            onChange={updateDisplayDate}
            value={!showRaisedDate}
          /> : 'Select Date Range'
        }
        onOpen={() => {setShowDateRangeFilter(true)}}
      />

      <GetHelpModal
        open={showGetHelpPopup}
        onClose={() => setGetHelpPopup(false)}
        onOpen={() => setGetHelpPopup(true)}
      />
    </Box>
  );
};
  
export default IssueTracking;
