import { Box } from "@mui/material";
import styles from "./SelectFilter.module.scss";
import { useState } from "react";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ClickTooltip from "components/atoms/ClickTooltip/ClickTooltip";

type Option = {label: string, value: string};

type PropType  = {
  label: React.ReactNode | JSX.Element;
  value: string;
  options: Option[];
  onChange: (value: string) => void;
}


const SelectFilter:React.FC<PropType> = ({label, value, options, onChange}) => {

  const [showOptions, setShowOptions] = useState(false);

  const optionLabel = options.find(op => op.value === value)?.label || '';

  return (
    <Box className={styles['select-filter']}>
      <ClickTooltip
        className={styles['popover-style']}
        show={showOptions}
        showPopper={setShowOptions}
        placement="bottom-start"
        title={
          <>
            {options.map((option) => {
              return (
                <div className={styles['filter-label']} 
                  onClick={() => {
                    onChange(option.value);
                    setShowOptions(false);
                  }}
                >{option.label}</div>
              )
            })}
          </>
        }
      >
        <Box className={styles['select-button']} onClick={() => options.length > 0 && setShowOptions((prevVal) => !prevVal)}>
          <p>{label}{optionLabel ? ': ': ''}<span>{optionLabel}</span></p>
          {showOptions ? <ExpandLessOutlinedIcon fontSize="small" /> : <ExpandMoreOutlinedIcon fontSize="small" />}
        </Box>
      </ClickTooltip>
    </Box>
  );
}

export default SelectFilter;