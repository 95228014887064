export const formatCurrency = (amount: number, decimals: number = 2): string => {
  const formattedAmount = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: decimals,
  }).format(amount);

  return formattedAmount;
};


export const lakhFormat = (num: number, decimals: number = 2) => {
  if(num >= 1e5){
    return `₹${(num/1e5).toFixed(decimals)}L`
  }
  return formatCurrency(num, decimals);
}
