import styles from "./Dashboard.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import HazardIcon from "../../images/hazard.png";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { csx } from "helpers/utils";
import ChartHeader from "components/atoms/ChartHeader/ChartHeader";
import { DataCol, DataRow } from "components/atoms/ChartData/ChartData";
import { resetFilter, setEndDateFilter, setStartDateFilter } from "slices/filtersSlice";
import { ROUTE_REPORT_MAP } from "../../constants";


const LORChart = () => {
  
  const dashboardData = useAppSelector((state) => state.dashboard);

  const dispatch = useAppDispatch();

  const startDate = useAppSelector((state) => state.filters.startDateFilter);
  const endDate = useAppSelector((state) => state.filters.endDateFilter);

  const handleChartClick = (chart_type: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.CHART_CLICK,
      {chart_type}
    );
  }

  const triggerReportViewedEvent = (path: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_VIEWED,
      {
        report_name: ROUTE_REPORT_MAP[path],
        source: 'Dashboard'
      }
    );
  }

  const chartName = 'lor-chart';

  const oos_count = dashboardData.lossOfRevenueSummary?.oos_count || 0;
  const lor = dashboardData.lossOfRevenueSummary?.lor || "₹0";

  const is0Value = +(lor.replaceAll(/[^\d.]/g, ''));

  return (
    <div className={csx(styles['chart-report-wrapper'], styles[chartName])} onClick={() => handleChartClick('lor_summary')}>
      {dashboardData.lossOfRevenueSummaryStatus === 'loading' && <p className={styles['load-err-txt']}>Loading...</p>}
      {dashboardData.lossOfRevenueSummaryStatus === 'failed' && <p className={styles['load-err-txt']}>Error: {dashboardData.lossOfRevenueSummaryError}</p>}
      {dashboardData.lossOfRevenueSummaryStatus === 'idle' && dashboardData.inventorySummary && (
        <>
          <ChartHeader
            headerTxt={'Loss of Revenue Summary'}
            tooltipTxt={'Concised view in Loss of revenue happened due to the out of stock products.'}
            link={'/lor-report'}
            onClick={() => {
              dispatch(resetFilter());
              dispatch(setStartDateFilter(startDate));
              dispatch(setEndDateFilter(endDate));
              triggerReportViewedEvent('/lor-report');
            }}
          />

          <DataRow
            className={styles['data-row']}
            children={[
              <DataCol
                valueClass={oos_count > 0 ? styles['data-value']: undefined}
                value={
                  <>
                    {oos_count}
                    {oos_count > 0 && <img className={styles['hazard-logo']} src={HazardIcon} alt="Hazard Logo" />}
                  </>
                }
                label={'Out of Stock Products (100 days)'}
              />,
              <DataCol
                valueClass={is0Value > 0 ? styles['data-value']: undefined}
                value={
                  <>
                    {lor}
                    {is0Value > 0 && <img className={styles['hazard-logo']} src={HazardIcon} alt="Hazard Logo" /> }
                  </>
                }
                label={'Loss of Revenue'}
              />
            ]}
          />
        </>
      )}
    </div>
  )
}

export default LORChart;