import { AppBar, Box, ClickAwayListener, useMediaQuery, useTheme } from "@mui/material";
import styles from "./ForgeHeader.module.scss";
import forgeIcon from "../../../images/forge_icon.png";
import forgeByVaareeDesktop from "images/forge_by_vaaree_desktop.png";
import forgeByVaareeMobile from "images/forge_by_vaaree_mobile.png";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import LogoutModal from "../LogoutModal/LogoutModal";

import { useState } from "react";
import LightTooltip from "../LightTooltip/LightTooltip";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { useDispatch } from "react-redux";
import { resetAuth } from "../../../slices/authSlice";
import { resetFilter } from "slices/filtersSlice";
import { useNavigate } from "react-router-dom";
import {ROUTE_REPORT_MAP, ROUTES} from "../../../constants";
import { csx } from "helpers/utils";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "hooks/mixpanel_hook";
import { RootState } from "store";

const ForgeHeader = () => {

  const navigate = useNavigate();

  const theme = useTheme();

  const dispatch = useDispatch();

  const [showUserOp, setShowUserOp] = useState<boolean>(false);

  const [showLogoutPopup, setShowLogoutPopup] = useState<boolean>(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const partnerFulfillmentPolicy = useAppSelector((state: RootState) => state.auth.basicUserInfo?.partner_fulfillment_policy_url) || '';

  const handleLogout = () => {
    dispatch(resetAuth());
    dispatch(resetFilter());
  }

  const downloadPartnerFulfillmentPolicy = () => {
    const link = document.createElement('a');
    link.href = partnerFulfillmentPolicy;
    link.target = '_blank';
    link.download = 'Partner Fulfillment Policy.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const triggerReportViewedEvent = (path: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_VIEWED, 
      {
        report_name: ROUTE_REPORT_MAP[path],
        source: 'Navigation'
      }
    );
  }

  const user_logo = useAppSelector((state) => state.auth.basicUserInfo?.logo_url) || null;

  return (
    <>
      <Box className={styles.wrapper} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <div className={styles.brandInfo}>
          <img className={styles.forgeIcon} src={isMobile ? forgeByVaareeMobile : forgeByVaareeDesktop} alt={'Forge Icon'} />
        </div>
        <div className={styles.userInfo}>
          {user_logo && <img src={user_logo} className={styles.supplierLogo} alt={'Supplier Logo'} /> }
          <ClickAwayListener onClickAway={() => setShowUserOp(false)}>
            <div>
              <LightTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                placement="bottom-end"
                onClose={() => {setShowUserOp(false)}}
                open={showUserOp}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <>
                    <p className={styles.actionButton} onClick={() => {
                      triggerReportViewedEvent(ROUTES.REQUEST_TRACKING);
                      navigate(ROUTES.REQUEST_TRACKING);
                      setShowUserOp(false);
                    }}>Requests</p>
                    <p className={styles.actionButton} onClick={() => {
                      triggerReportViewedEvent(ROUTES.MRP_LABEL);
                      navigate(ROUTES.MRP_LABEL);
                      setShowUserOp(false);
                    }}>MRP Label</p>
                    <p className={styles.actionButton} onClick={() => {
                      triggerReportViewedEvent(ROUTES.ISSUE_TRACKING);
                      navigate(ROUTES.ISSUE_TRACKING);
                      setShowUserOp(false);
                    }}>Help Center</p>
                    <p className={styles.actionButton} onClick={() => {
                      downloadPartnerFulfillmentPolicy();
                      setShowUserOp(false);
                    }}>Download Fulfilment Policy</p>
                    <p className={csx(styles.actionButton, styles.red)} onClick={() => {
                      setShowLogoutPopup(true);
                      setShowUserOp(false);
                    }}>Logout</p>
                  </>
                }
              >
                <div className={styles.userIcon} onClick={() => setShowUserOp((prevVal) => !prevVal)}>
                  <PersonOutlineOutlinedIcon fontSize="small" />
                  {showUserOp ? <ExpandLessOutlinedIcon fontSize="small" /> : <ExpandMoreOutlinedIcon fontSize="small" />}
                </div>
              </LightTooltip>
            </div>
          </ClickAwayListener>
        </div>
      </Box>
      <LogoutModal
        open={showLogoutPopup}
        onClose={() => setShowLogoutPopup(false)}
        onLogout={handleLogout}
      />
    </>
  )
}

export default ForgeHeader;