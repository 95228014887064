import { OrderItem } from "../../slices/orderCancellationPenaltySlice";
import { ColumnPropType, FixedColumnPropType } from "../../components/organisms/TableManager/TableManager";
import styles from './PenaltyReports.module.scss';
import ProductThumbnail, { ProductThumbnailLite } from "../../components/atoms/ProductThumbnail/ProductThumbnail";
import { lakhFormat } from "../../helpers/formatUtils";

// Type for columns property
type OrderCancellationPenaltyColumnsPropType = {
  isMobile: boolean;
  showTrackingProgress: (steps: { label: string; value: string }[]) => void;
};

// Helper function for rendering cancellation pills
const renderCancellationPill = (value: string): JSX.Element => {
  const pillStyles: Record<string, string> = {
    Partner: styles["orange-pill"],
    System: styles["red-pill"],
  };

  return (
    <span className={pillStyles[value] || ""}>
      {value === "System" ? "Auto" : value}
    </span>
  );
};

// Fixed Column Configuration
export const fixedColumn: FixedColumnPropType<OrderItem> = {
  label: "Product / SKU",
  accessor: "product_variant_sku_code",
  width: 120,
  maxWidth: 120,
  thresold: 170, // 290 - 120
  cell: ({ row }) => {
    const { product_image_src, product_variant_sku_code: sku, fbv_details } = row || {};
    const { enabled = false, warehouse_name = "" } = fbv_details || {};
    return (
      <ProductThumbnailLite
        sku={sku || ""}
        src={product_image_src || ""}
        fbv_enabled={enabled}
        fbv_warehouse={warehouse_name}
      />
    );
  },
};

// Dynamic Column Configuration
export const orderCancellationPenaltyColumns = (
  props: OrderCancellationPenaltyColumnsPropType
): ColumnPropType<OrderItem>[] => [
  {
    label: "Product / SKU",
    accessor: "product_variant_sku_code",
    minWidth: 290,
    width: 290,
    cell: ({ row }) => {
      const { product_variant_sku_code: sku, product_title, product_image_src, fbv_details } = row || {};
      const { enabled = false, warehouse_name = "" } = fbv_details || {};
      return (
        <ProductThumbnail
          sku={sku || ""}
          title={product_title || ""}
          src={product_image_src || ""}
          fbv_enabled={enabled}
          fbv_warehouse={warehouse_name}
        />
      );
    },
  },
  {
    label: "Order Date",
    accessor: "order_created_at",
    minWidth: 120,
  },
  {
    label: "Order No",
    accessor: "order_number",
    minWidth: 120,
  },
  {
    label: "Quantity",
    accessor: "quantity",
    minWidth: 80,
  },
  {
    label: "Order Value",
    accessor: "order_value",
    minWidth: 80,
    cell: ({ value }) => <>{lakhFormat(value || 0)}</>, // Safeguard against undefined values
  },
  {
    label: "Cancelled By",
    accessor: "cancelled_by",
    cell: ({ value }) => renderCancellationPill(value || ""),
    minWidth: 80,
  },
];
