import { Box, Button, createTheme, Divider, ThemeProvider} from '@mui/material';
import { useAppSelector, useAppDispatch } from 'hooks/redux-hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from "./IssueTracking.module.scss";
import { IssueDataHash, updateVendorSatisfactionApi } from 'slices/issueTrackingSlice';
import MainHeader from 'components/atoms/MainHeader/MainHeader';
import { pillClass } from './IssueTracking.constant';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { csx } from 'helpers/utils';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { useDispatch } from 'react-redux';
import { resetAuth } from 'slices/authSlice';
import { resetFilter } from 'slices/filtersSlice';
import PreviewModal from 'components/atoms/PreviewModal/PreviewModal';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1CA129',
      contrastText: '#fff',
    },
    secondary: {
      main: '#E62E2E',
      contrastText: '#fff',
    },
  },
});

const Issue:React.FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [previewUrl, setShowPreviewUrl] = useState<string|undefined>(undefined);

  const [issue, setIssue] = React.useState<IssueDataHash | null | undefined>(undefined);
  const trackIssueData = useAppSelector((state) => state.trackIssue);

  useEffect(() => {
    let goBack = false;
    if(!params.id || !trackIssueData?.trackIssueData?.length) goBack = true;
    const _issue = trackIssueData?.trackIssueData?.find((ith) => ith.id.toString() === params.id?.toString());
    if(goBack || !_issue) {
      window.history.back();
      return;
    }
    setIssue(_issue);
  }, [params.id, trackIssueData?.trackIssueData]);

  const setCustomerSatisfactionStatus = async (status: boolean) => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(updateVendorSatisfactionApi({headers, id: issue?.id, vendorSatisfied: status}))
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
    window.history.back();  
  }

  return (
    <>
      {issue ? (
        <ThemeProvider theme={theme}>
          <Box className={styles.reportWrapper}>
            <MainHeader label={`#${issue.id}`} allowBack />
            <Box className={styles.issueWrapper}>
              <Box className={styles.mainContent}>
                
                <Box className={styles.statusSection}>
                  <Box className={styles.leftSection}>
                    <p className={pillClass(issue.status)}>{issue.status}</p>
                    {issue.sla_breached && issue.status !== 'Resolved' && <p className={styles.red}>Delayed</p>}
                  </Box>
                  <Box className={styles.rightSection}>
                    <LabelValueBlock
                      label='Created Date'
                      value={issue.created_at}
                    />
                    {issue.resolved_at && (
                      <LabelValueBlock
                        label='Resolved Date'
                        value={issue.resolved_at}
                      />
                    )}
                  </Box>
                </Box>
                <Divider sx={{mb: 2.5}} />
                <LabelValueBlock
                  label='Reason'
                  value={issue.reason}
                />
                <LabelValueBlock
                  label='Summary'
                  value={issue.summary}
                />
                <LabelValueBlock
                  label='Description'
                  value={issue.description}
                />
                {!!issue.image_urls.length && (
                  <Box className={styles.labelValueBlock}>
                    <p className={styles.label}>
                      Attached Image
                    </p>
                    <Box className={styles.imgList}>
                      {issue.image_urls.map((img, i) => {
                        return <img src={img} alt={`Issue Image ${i + 1}`} onClick={() => {
                          setShowPreviewUrl(img);
                          setShowPreview(true);
                        }}/>
                      })}
                    </Box>
                  </Box>
                )}
                <Divider sx={{mb: 2.5}} />
                {(issue.status === 'Resolved' || issue.status === 'On Hold' )&& !!issue.vendor_remark && (
                  <LabelValueBlock 
                    label={'Remark'}
                    value={issue.vendor_remark || ''}
                    className={styles.remarkBlock}
                  />
                )}
                {(issue.status === 'Resolved')&& (
                  <Box className={styles.satisfactionStatus}>
                    <p className={styles.label}>
                      Are you satisfied with the solution?
                    </p>
                    <Box className={styles.actionBtns}>
                      <Button color='primary' variant={issue.vendor_satisfied === true ? 'contained' : 'outlined'} className={styles.btn} onClick={() => setCustomerSatisfactionStatus(true)}>
                        <ThumbUpOffAltIcon fontSize='small' /> Satisfied
                      </Button>
                      <Button color='secondary' variant={issue.vendor_satisfied === false ? 'contained' : 'outlined'} className={styles.btn} onClick={() => setCustomerSatisfactionStatus(false)}>
                        <ThumbDownOffAltIcon fontSize='small' /> Not Satisfied
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      ) : null}
      <PreviewModal
        open={showPreview}
        onClose={() => {setShowPreview(false)}}
        src={previewUrl}
        alt='Issue Image'
      />
    </>
  )
}

export default Issue;


type LabelValueBlockPropType = {
  label: string;
  value: string;
  className?: string | undefined;
}

const LabelValueBlock:React.FC<LabelValueBlockPropType> = ({label, value, className = undefined}) => {
  return (
    <Box className={csx(styles.labelValueBlock, className)}>
      <p className={styles.label}>{label}</p>
      <p className={styles.value}>{value}</p>
    </Box>
  );
}