import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { RootState } from "../../store";
import styles from "./PenaltyReports.module.scss";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import { resetAuth } from "../../slices/authSlice";
import Loader from "components/atoms/Loader/Loader";
import { fetchOosPenalties } from "../../slices/oosPenaltySlice";
import { fixedColumn, oosPenaltyColumns } from "./OosPenalty.constant";
import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableManager from "../../components/organisms/TableManager/TableManager";
import MonthSelector from "components/molecules/MonthSelector/MonthSelector";
import {
  resetFilter,
  setOosPenaltyReportMonthFilter,
} from "../../slices/filtersSlice";
import DateRangeSelector, {
  DateRangeLabel,
} from "components/molecules/DateRangeSelector/DateRangeSelector";
import {
  MIXPANEL_EVENT_TYPES,
  triggerMixpanelEvent,
} from "../../hooks/mixpanel_hook";
import { DEFAULT_BLACK_COLOR, ROUTE_REPORT_MAP, ROUTES } from "../../constants";
import { DataRow, DataCol } from "components/atoms/ChartData/ChartData";

const OosPenalty: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Constants
  const PAGE_TITLE = "Penalty Report";

  // Redux state
  const { product_variants, summary } = useAppSelector(
    (state: RootState) => state.oosPenalty
  );

  const selectedMonths = useAppSelector(
    (state: RootState) => state.filters.oosPenaltyReportMonthFilter
  );

  // Local state
  const [loaderActive, setLoaderActive] = useState(true);
  const [filterApplied, setFilterApplied] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [showMonthOptions, setShowMonthOptions] = useState(false);
  const [trackingProgress, setTrackingProgress] = useState<
    { label: string; value: string }[]
  >([]);

  // Handlers
  const handleMonthSelection = useCallback(
    (month: number, year: number) => {
      dispatch(setOosPenaltyReportMonthFilter([{ month, year }]));
      setFilterApplied(true);
    },
    [dispatch]
  );

  const handleClear = useCallback(() => {
    const currentDate = new Date();
    dispatch(
      setOosPenaltyReportMonthFilter([
        {
          month: currentDate.getMonth() + 1,
          year: currentDate.getFullYear(),
        },
      ])
    );
    setFilterApplied(true);
  }, [dispatch]);

  const handleDisabledMonths = useCallback((month: number, year: number) => {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    return (
      year < 2024 ||
      year > currentYear ||
      (year === currentYear && month > currentMonth)
    );
  }, []);

  const fetchData = useCallback(async () => {
    const userInfo = localStorage.getItem("userInfo");
    if (!userInfo) {
      dispatch(resetAuth());
      dispatch(resetFilter());
      return;
    }

    const token = JSON.parse(userInfo).token;
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    try {
      await dispatch(
        fetchOosPenalties({
          month: selectedMonths[0].month,
          year: selectedMonths[0].year,
          headers,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error fetching OOS penalties:", error);
    } finally {
      setFilterApplied(false);
      setLoaderActive(false);
    }
  }, [dispatch, selectedMonths]);

  useEffect(() => {
    if (filterApplied) {
      setLoaderActive(true);
      fetchData();
    }
  }, [filterApplied, fetchData]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    triggerMixpanelEvent(MIXPANEL_EVENT_TYPES.REPORT_VIEWED, {
      report_name: ROUTE_REPORT_MAP[newValue],
      source: "Navigation",
    });
    navigate(newValue);
  };

  function getMonthAbbreviation(monthNumber: number) {
    const date = new Date(0, monthNumber - 1); // Month is zero-indexed in Date
    return date.toLocaleString("en-US", { month: "short" });
  }
  let label = `${getMonthAbbreviation(selectedMonths[0].month)} ${
    selectedMonths[0].year
  }`;

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label={PAGE_TITLE}>
        <MonthSelector
          onClick={handleMonthSelection}
          showCalendar={showMonthOptions}
          setShowCalendar={setShowMonthOptions}
          disabledMonths={handleDisabledMonths}
          selectedMonths={selectedMonths}
          onClear={handleClear}
          onSubmit={() => {
            setFilterApplied(true);
            setShowMonthOptions(false);
          }}
        >
          <DateRangeLabel
            noDateLabel={label}
            onClick={() => {
              setShowMonthOptions(true);
            }}
          />
        </MonthSelector>
      </MainHeader>

      <Box className={styles.pageTabsWrapper}>
        <Tabs
          value={window.location.pathname}
          onChange={handleTabChange}
          TabIndicatorProps={{ sx: { backgroundColor: "#4F76FE" } }}
        >
          {[
            { label: "Out of Stock", route: ROUTES.OOS_PENALTY },
            { label: "Returns", route: ROUTES.RETURN_PENALTY },
            {
              label: "Order cancellation",
              route: ROUTES.ORDER_CANCELLATION_PENALTY,
            },
          ].map(({ label, route }) => (
            <Tab
              key={route}
              label={label}
              value={route}
              style={{
                textTransform: "none",
                fontWeight: 400,
                color:
                  window.location.pathname === route
                    ? "#3361FF"
                    : DEFAULT_BLACK_COLOR,
              }}
            />
          ))}
        </Tabs>
      </Box>

      {loaderActive ? (
        <Loader show={loaderActive} />
      ) : (
        <>
          <Box className={styles.paymentSummaryWrapper}>
            <DataRow className={styles["payment-summary"]} separator>
              {[
                { label: "SKUs Live", value: summary?.total_products || 0 },
                {
                  label: "Out of Stock days",
                  value: `${summary?.oos_percentage || 0}%`,
                },
                {
                  label: "Total Stock time (SKU X 1 month)",
                  value: summary?.total_stock_days_last_month || 0,
                },
                {
                  label: "Penalty Amount",
                  value: summary?.penalty?.penalty_amount || 0,
                },
              ].map(({ label, value }, index) => (
                <DataCol
                  key={index}
                  colClass={styles["individual-payment"]}
                  labelClass={styles["payment-cycle"]}
                  valueClass={`${styles["payment-value"]} ${label === "Penalty Amount" ? styles["red-pill"] : ""}`}
                  label={label}
                  value={value}
                />
              ))}
            </DataRow>
          </Box>

          <TableManager
            columns={oosPenaltyColumns({
              showTrackingProgress: (steps) => setTrackingProgress(steps),
              isMobile,
            })}
            data={product_variants}
            showPagination
            totalCount={product_variants.length || 0}
            currentPage={page}
            sortedColumn={null}
            handleSort={() => {}}
            sortDirection={"desc"}
            rowPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowPerPageChange={setRowsPerPage}
            rowsPerPageOptions={[10, 25, 100]}
            fixedColumn={isMobile ? fixedColumn : undefined}
          />
        </>
      )}
    </Box>
  );
};

export default OosPenalty;
