import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";
import { SelectedMonth } from "components/molecules/MonthSelector/MonthSelector";

export type FbvStorageChargesDataHash = {
  unique_key: number;
  sku: string;
  name: string;
  img: string;
  net_quantity: number;
  date_quantity_map: Record<string,number>;
  volumetric_size: number;
  net_storage_charges: number;
  net_sale_quantity: number;
  pickpack_charges: number;
  total: number;
  soh: number;
  fbv_warehouse: string;
}

type FbvStorageChargesData = {
  total_pickpack_charges: number | null;
  total_storage_charges: number | null;
  total_charges: number | null;
  from_date: Date | null;
  to_date: Date | null;
  fbv_stock_fee_report: FbvStorageChargesDataHash[];
  pickpack_fee: number | null;
  storage_fee: number | null;
}

type FbvStorageChargesApiState = {
  fbvStockChargesReportData?: FbvStorageChargesData | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof FbvStorageChargesDataHash | null;
  fbvStockChargesReportStatus: "idle" | "loading" | "failed";
  fbvStockChargesReportError: string | null;
};

const initialState: FbvStorageChargesApiState = {
  fbvStockChargesReportData: null,
  fbvStockChargesReportStatus: "loading",
  fbvStockChargesReportError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};

const formatRequest = (requestData: any, download:boolean = false): string => {
  let url = `/forge/stocks/fbv_stock_fee_report${download ? '.csv' : ''}?`;
  url += `from_date=${requestData.fbvStorageChargesReportStartDateFilter}&`;
  url += `to_date=${requestData.fbvStorageChargesReportEndDateFilter}&`;
  url += `product_id=${requestData.fbvStorageChargesReportProductIdFilter}&`;
  url += `product_title=${requestData.fbvStorageChargesReportProductTitleFilter}`;
  requestData.fbvStorageChargesReportFbvWarehouseFilter.forEach((warehouseName: string) => {
    url += `&fbv_warehouse[]=${warehouseName}`;
  });
  return url;
}

const formatInvoiceDownloadRequest = (requestData: any, download:boolean = false): string => {
  let url = `/forge/stocks/download_storage_charges_invoices?`;
  for(let i = 0 ; i < requestData.selectedMonths.length ; i++){
    const { month, year } = requestData.selectedMonths[i] as SelectedMonth;
    const startDate = `1-${month}-${year}`;
    url += `[selected_dates][]=${startDate}&`;
  }
  return url;
}

export const fbvStockChargesReportApi = createAsyncThunk(
  "fbvStockChargesReportApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData);
    try {
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;
      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const fbvStockChargesReportDownloadReportApi = createAsyncThunk(
  "fbvStockChargesReportDownloadReportApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData, true);
    try {
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const fbvStockChargesDownloadInvoicesApi = createAsyncThunk(
  "fbvStockChargesDownloadInvoicesApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatInvoiceDownloadRequest(requestData);
    try {
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;
      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); 
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)


const fbvStockChargesReportSlice = createSlice({
  name: 'fbvStockChargesReport',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof FbvStorageChargesDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.fbvStockChargesReportData && column){
        const sorted = sortRecords(state.fbvStockChargesReportData?.fbv_stock_fee_report || [], column, direction);
        state.fbvStockChargesReportData.fbv_stock_fee_report = sorted as FbvStorageChargesDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fbvStockChargesReportApi.pending, (state) => {
        state.fbvStockChargesReportStatus = "loading";
        state.fbvStockChargesReportError = null;
      })
      .addCase(
        fbvStockChargesReportApi.fulfilled,
        (state, action: PayloadAction<FbvStorageChargesData>) => {
          state.fbvStockChargesReportStatus = "idle";
          state.fbvStockChargesReportData = action.payload;
        }
      )
      .addCase(fbvStockChargesReportApi.rejected, (state, action) => {
        state.fbvStockChargesReportStatus = "failed";
        if (action.payload) {
          state.fbvStockChargesReportError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.fbvStockChargesReportError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = fbvStockChargesReportSlice.actions;
export default fbvStockChargesReportSlice.reducer;
