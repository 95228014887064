import styles from "./ProductThumbnail.module.scss";
import FbvIcon from "images/fbv-icon.png";
import { useAppSelector } from 'hooks/redux-hooks';

type ProductThumbnailPropType = {
  sku?: string;
  title?: string;
  src?: string;
  fbv_enabled?: boolean;
  fbv_warehouse?: string;
}

const ProductThumbnail = (props:ProductThumbnailPropType) => {
  const {sku, title, src, fbv_enabled, fbv_warehouse} = props;
  
  const fbvWarehouseList = useAppSelector((state) => state.filters.fbvWarehouseFilter || []);
  const badgeUrl = fbv_warehouse ? 
    fbvWarehouseList.find(warehouse => warehouse.name === fbv_warehouse)?.badge_url || FbvIcon 
    : FbvIcon;
  
  return (
    <div className={styles.productdetails}>
      {src ? (
        <img
          src={src}
          alt={"Product Image - " + (title || '')}
          className={styles.productImgTag}
        />
      ): null}
      <div className={styles.productNameSKUcode}>
        <div className={styles.skuCode}>
          {sku || ''}
          {fbv_enabled && (
            <img className={styles.fbvIcon} src={badgeUrl} alt="FBV Icon"/>
          )}
        </div>
        <div className={styles.productName}>
          {title || ''}
        </div>
      </div>
    </div>
  );
}

export default ProductThumbnail;

export const ProductThumbnailLite:React.FC<Omit<ProductThumbnailPropType, 'title'>> = ({sku, fbv_enabled, src, fbv_warehouse}) => {
  const fbvWarehouseList = useAppSelector((state) => state.filters.fbvWarehouseFilter || []);
  const badgeUrl = fbv_warehouse ? 
    fbvWarehouseList.find(warehouse => warehouse.name === fbv_warehouse)?.badge_url || FbvIcon 
    : FbvIcon;

  return <div className={styles.productThumbnailLite}>
    {src ? (
      <img
        src={src}
        alt={"Product Image - " + (sku || '')}
        className={styles.productImgTag}
      />
    ): null}
    <div className={styles.skuCode}>
      <span>{sku || ''}</span>
      {fbv_enabled && (
        <img className={styles.fbvIcon} src={badgeUrl} alt="FBV Icon"/>
      )}
    </div>
  </div>
}