import { Box, Button, createTheme, Divider, ThemeProvider } from "@mui/material";
import Img from "../Img/Img";
import ModalBox from "../ModalBox/ModalBox";
import styles from "./PreviewModal.module.scss";

type PreviewModalPropType = {
  open: boolean;
  onClose: () => void;
  src: string | undefined;
  alt: string | undefined;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#3361FF',
      contrastText: '#FFFFFF',
    },
  },
});

const PreviewModal:React.FC<PreviewModalPropType> = (props) => {
  const {open, onClose, src, alt} = props;
  return (
    <ModalBox className={styles.previewModal} open={open} onClose={onClose}>
      <>
        <Box className={styles.body}>
          <Img src={src} alt={alt} />
        </Box>
        <Divider sx={{mt: 2}}/>
        <Box className={styles.footer}>
          <ThemeProvider theme={theme}>
            <Button variant="contained" className={styles.closeBtn} onClick={onClose}>Close</Button>
          </ThemeProvider>
        </Box>
      </>
    </ModalBox>
  );
}

export default PreviewModal;