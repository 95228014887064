import styles from "./CustomMultiStepProgressBar.module.scss"

type Step = {
  color: string,
  percent: number,
}

type CustomMultiStepProgressBarType = {
  height?: number,
  backgroundColor?: string
  steps: Step[]
}

const CustomMultiStepProgressBar = ({steps, height = 16, backgroundColor = '#E5E5E5'}: CustomMultiStepProgressBarType) => {

  return <div className={styles['multi-step-progress-bar']} style={{height, backgroundColor}}>
    {steps.map(step => {
      return step.percent === 0 ? null : <div className={styles['progress-step']} style={{backgroundColor: step.color, width: `${step.percent}%`}} />
    })}
  </div>
}

export default CustomMultiStepProgressBar;