import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

type ShippingPackageData = {
  shipping_provider: string;
  tracking_number: string;
  courier_status: string;
  created_at: string;
  dispatched_at: string;
  delivered_at: string;
}

export type ShippingDataHash = {
  unique_key: number;
  product_title: string;
  product_variant_sku_code: string;
  product_image_src: string;
  order_created_at: string;
  order_number: string;
  shipping_order_item_quantity: number;
  product_variant_price: number;
  order_payment_mode: string;
  delayed_dispatch: boolean;
  fbv_enabled: boolean;
  fbv_warehouse: string;
  shipping_package_data: ShippingPackageData;
}

type RTOTrackingData = {
  rto_data: ShippingDataHash[];
}

type OrderTrackingApiState = {
  rtoTrackingData?: RTOTrackingData | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof ShippingDataHash  | null;
  rtoTrackingStatus: "idle" | "loading" | "failed";
  rtoTrackingError: string | null;
};

const initialState: OrderTrackingApiState = {
  rtoTrackingData: null,
  rtoTrackingStatus: "loading",
  rtoTrackingError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};

const formatRequest = (requestData: any, download:boolean = false): string => {
  let url = `/forge/rtos/rto_report${download ? '.csv' : ''}?`;
  url += `q[created_at_gteq]=${requestData.startDateFilter}&`;
  url += `q[created_at_lteq]=${requestData.endDateFilter}&`;
  url += `q[order_order_number_eq]=${requestData.orderNo}&`;
  url += `q[shipping_packages_tracking_number_eq]=${requestData.rtoReportTrackingNumberFilter}&`;
  if(!!requestData.rtoReportFbvWarehouseFilter.length){
    url += `q[vendor_location_id_null]=false&`;
  }
  url += `shipping_packages_delivered_at_gteq=${requestData.rtoReportDeliveredAtStartDateFilter}&`;
  url += `shipping_packages_delivered_at_lteq=${requestData.rtoReportDeliveredAtEndDateFilter}&`;
  requestData.rtoReportFbvWarehouseFilter.forEach((warehouseName: string) => {
    url += `&q[vendor_location_warehouse_name_in][]=${warehouseName}`;
  });
  return url;
}


export const rtoTrackingApi = createAsyncThunk(
  "rtoTrackingApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData);
    try {
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const rtoTrackingDownloadReportApi = createAsyncThunk(
  "rtoTrackingDownloadReportApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData, true);
    try {
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

const rtoTrackingSlice = createSlice({
  name: 'rtoTracking',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof ShippingDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      // Sorting logic
      if(state.rtoTrackingData && column){
        const sorted = sortRecords(state.rtoTrackingData.rto_data, column, direction);
        state.rtoTrackingData.rto_data = sorted as ShippingDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(rtoTrackingApi.pending, (state) => {
        state.rtoTrackingStatus = "loading";
        state.rtoTrackingError = null;
      })
      .addCase(
        rtoTrackingApi.fulfilled,
        (state, action: PayloadAction<RTOTrackingData>) => {
          state.rtoTrackingStatus = "idle";
          state.rtoTrackingData = action.payload;
        }
      )
      .addCase(rtoTrackingApi.rejected, (state, action) => {
        state.rtoTrackingStatus = "failed";
        if (action.payload) {
          state.rtoTrackingError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.rtoTrackingError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = rtoTrackingSlice.actions;
export default rtoTrackingSlice.reducer;
