import { ReturnDataHash } from "../../slices/returnsTrackingSlice";
import {ColumnPropType, FixedColumnPropType} from "../../components/organisms/TableManager/TableManager";
import styles from './ReturnExchangeReport.module.scss';
import ProductThumbnail, { ProductThumbnailLite } from "../../components/atoms/ProductThumbnail/ProductThumbnail";
import { lakhFormat } from "../../helpers/formatUtils";
import MultiStepProgressPopover from "../../components/molecules/MultiStepPopover/MultiStepProgressPopover";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type ReturnsReportColumnsPropType = {
  isMobile: boolean;
  showTrackingProgress: (steps: {label: string, value: string}[]) => void;
}

export const fixedColumn:FixedColumnPropType<ReturnDataHash> = {
  label: 'Product / SKU',
  accessor: 'sku_list',
  width: 120,
  maxWidth: 120,
  thresold: (290 - 120), // calculated based on first colum of table
  cell: ({row}) => <ProductThumbnailLite 
    sku={row.sku_list || ''}
    src={row.thumbnail}
    fbv_enabled={row.fbv_enabled}
    fbv_warehouse={row.fbv_warehouse}
  />
}

export const returnsReportColumns = (props: ReturnsReportColumnsPropType):ColumnPropType<ReturnDataHash>[] => [
  {
    label: 'Product / SKU',
    accessor: 'sku_list',
    minWidth: 290,
    width: 290,
    cell: ({row}) => <ProductThumbnail 
      sku={row.sku_list || ''}
      title={row.product_title}
      src={row.thumbnail}
      fbv_enabled={row.fbv_enabled}
      fbv_warehouse={row.fbv_warehouse}
    />
  },
  {
    label: 'Quantity',
    accessor: 'order_quantity',
    minWidth: 80,
    sortable: true,
  },
  {
    label: 'Order Value',
    accessor: 'order_value',
    minWidth: 80,
    sortable: true,
    cell: ({value}) => {
      return <>
        {lakhFormat(value)}
      </>
    }
  },
  {
    label: 'Return Date',
    accessor: 'return_date',
    minWidth: 120,
  },
  {
    label: 'Return Delivered',
    accessor: 'return_delivered',
    minWidth: 120,
  },
  {
    label: 'Order No',
    accessor: 'order_number',
    minWidth: 80,
  },
  {
    label: 'Reason',
    accessor: 'reason',
    minWidth: 160,
  },
  {
    label: 'Return Status',
    accessor: 'tracking_number',
    minWidth: 150,
    cell: ({row}) => {
      return (
        <div className={styles.orderAWBDetails}>
          <div className={styles.awbStatus}>
            <div>{row.return_delivered === 'Pending' ? 'In Transit' : 'Delivered'}</div>
            <div className={styles.awbNumber}>{'AWB: ' + row.tracking_number}</div>
          </div>
          {props.isMobile ? (
           <InfoOutlinedIcon fontSize="small" className={styles.orderStatus} onClick={() => {
            props.showTrackingProgress([
              {label: 'In Transit:', value: row.return_date || ''},
              {label: 'Delivered:', value: row.return_delivered === 'Pending' ? '' : row.return_delivered},
            ]);
          }} />
          ): (
            <MultiStepProgressPopover
              steps={[
                {label: 'In Transit:', value: row.return_date || ''},
                {label: 'Delivered:', value: row.return_delivered === 'Pending' ? '' : row.return_delivered},
              ]}
            >
              <InfoOutlinedIcon fontSize="small" className={styles.orderStatus} />
            </MultiStepProgressPopover>
          )}
        </div>
      );
    }
  },
];