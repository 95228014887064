import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";

// Types
export type PenaltyBreakdown = {
  reason: string;
  count: number | null;
  penalty: number;
};

export type PenaltyDetails = {
  penalty_amount_breakdown: {
    auto_cancelled_count: number;
    partner_cancelled_count: number;
    auto_cancel_penalty: number;
    partner_penalty: number;
    total_penalty: number;
  };
  penalty_reason: string | null;
};

export type Summary = {
  total_order_items_cancelled: number;
  total_penalty_amount: number;
  breakdown_by_reason: PenaltyBreakdown[];
  penalty: PenaltyDetails;
};

export type OrderItem = {
  unique_key: number;
  id: number;
  product_title: string;
  product_variant_sku_code: string;
  product_image_src: string;
  order_created_at: string;
  order_number: string;
  quantity: number;
  order_value: number;
  product_variant_price: number;
  order_payment_mode: string;
  cancelled_by: string;
  fbv_details: {
    enabled: boolean;
    warehouse_name: string;
    warehouse_icon_url: string;
  };
};

export type PenaltyData = {
  summary: Summary;
  order_item_data: OrderItem[];
};

export type OrderCancellationPenaltyState = {
  summary: Summary | null;
  order_item_data: OrderItem[];
  loading: boolean;
  error: string | null;
};

// Initial State
const initialState: OrderCancellationPenaltyState = {
  summary: null,
  order_item_data: [],
  loading: false,
  error: null,
};

// Async Thunk
export const fetchOrderCancellationPenalty = createAsyncThunk<
  PenaltyData,
  { headers?: Record<string, string>; month: number; year: number },
  { rejectValue: string }
>(
  "orderCancellationPenalty/fetch",
  async ({ headers = {}, month, year }, { rejectWithValue }) => {
    const config: AxiosRequestConfig = { headers };
    const url = `forge/penalties/order_cancellation_penalty?month=${month}&year=${year}`;

    try {
      const response = await axiosInstance.get<{ data: PenaltyData }>(url, config);
      return response.data.data; // Return nested data directly
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(
          error.response?.status === 401
            ? "Unauthorized! Login again."
            : error.response?.data?.message || "An unexpected error occurred."
        );
      }
      return rejectWithValue("An unexpected error occurred.");
    }
  }
);

// Slice
const orderCancellationPenaltySlice = createSlice({
  name: "orderCancellationPenalty",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderCancellationPenalty.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchOrderCancellationPenalty.fulfilled,
        (state, action: PayloadAction<PenaltyData>) => {
          state.loading = false;
          state.summary = action.payload.summary;
          state.order_item_data = action.payload.order_item_data;
        }
      )
      .addCase(fetchOrderCancellationPenalty.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "An unexpected error occurred.";
      });
  },
});

export default orderCancellationPenaltySlice.reducer;
