import { Box } from "@mui/material";
import styles from "./MontlyDataTable.module.scss";

type PropType = {
  year?: number;
  month: number;
  dateValueHash: Record<string, (number)>;
}

const weekDays = ['Sun', 'Mon' , 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const today = new Date();

const isValidDatePt = (ith: number, totalDays: number) => {
  if(ith <= 0) return false;
  if(ith > totalDays) return false;
  return true;
}

const MonthlyDataTable:React.FC<PropType> = (props) => {
  const {year = today.getFullYear(), month, dateValueHash} = props;

  const monthStr = new Date(year, month, 1).toLocaleString('en-us',{ month: 'short', year:'numeric'});
  
  const fillUpDays = new Date(year, month, 1).getDay();
  const totalDays = new Date(year, month + 1, 0).getDate();
  const totalPts = fillUpDays + totalDays;
  const totalRows = Math.floor(totalPts / 7) + +(totalPts % 7 !== 0);

  return (
    <Box className={styles.wrapper}>
      <p className={styles.monthStr}>{monthStr}</p>

      <Box className={styles.weekDays}>
        {weekDays.map((wd) => (
          <p className={styles.dayTxt}>{wd}</p>
        ))}
      </Box>

      <Box className={styles.daysTable}>
        {(new Array(totalRows).fill(null)).map((_, ri) => 
          <Box key={ri} className={styles.weekRow}>
            {weekDays.map((_, ci) => {
              const date = ri * 7 + ci + 1 - fillUpDays;
              const key = `${date.toString().padStart(2, '0')}-${(month + 1).toString().padStart(2, '0')}-${year}`;
              const value = dateValueHash[key] || 0;
              return (
                <Box key={`${ri}-${ci}`} className={styles.dateCell}>
                {isValidDatePt(date, totalDays) ? (
                  <>
                    <p className={styles.date}>{date}</p>
                    <p className={styles.value}>{value}</p>
                  </>
                ): (
                  null
                )}
              </Box>
              )
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default MonthlyDataTable;