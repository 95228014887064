import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import styles from "./PolicyModal.module.scss";
import { RootState } from "store";
import { useMemo } from "react";
import ModalBox from "../ModalBox/ModalBox";
import { acceptPolicyApi, resetAuth } from "slices/authSlice";
import { resetFilter } from "slices/filtersSlice";
import { Box, Button, createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFC845',
      contrastText: '#000',
    },
  },
});

const PolicyModal:React.FC = () => {

  const dispatch = useAppDispatch();
  const basicUserInfo = useAppSelector((state: RootState) => state.auth.basicUserInfo);
  const showPolicyModal = useMemo(() => {
    return !basicUserInfo?.policy_accepted && basicUserInfo?.user_type === 'live';
  }, [basicUserInfo]);

  const handleAcceptPolicy = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      try {
        await dispatch(acceptPolicyApi({headers: headers})).unwrap();
      }catch(e) {
        console.error(e);
      }
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  }

  const pdfUrl = `${basicUserInfo?.partner_fulfillment_policy_url || ''}#toolbar=0&navpanes=0&scrollbar=0`;

  return (
    <ModalBox
      className={styles.policyModal}
      open={showPolicyModal}
      onClose={() => {}}
    >
      <ThemeProvider theme={theme}>
        <Box className={styles.container}>
          <iframe src={pdfUrl} title="PDF Viewer"></iframe>
          <Box className={styles.footer}>
            <p>By clicking "I Agree," you accept the terms of this Partner Fulfilment Policy.</p>
            <Button className={styles.actionBtn} color={'primary'} variant="contained" onClick={handleAcceptPolicy}>
              I Agree
            </Button>
          </Box>
        </Box>
      </ThemeProvider>
    </ModalBox>
  )
}

export default PolicyModal;