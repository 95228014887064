import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {Box,  Button,  Fab, useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './PaymentReport.module.scss';
import { setSort, paymentReportApi, paymentReportDownloadReportApi, downloadVendorPaymentDetailReportApi, getVendorInvoicesApi } from "../../slices/paymentReportSlice";
import Loader from "components/atoms/Loader/Loader";
import { 
  setReconReportStatusFilter,
  setReconReportPaymentStartDateFilter,
  setReconReportPaymentEndDateFilter,
  setReconReportPaymentIdFilter,
  setReconReportBankReferenceIdFilter,
  setReconReportInvoiceNumberFilter,
  setReconReportPaymentCycleStartDateFilter,
  resetFilter,
} from "../../slices/filtersSlice";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { resetAuth } from "../../slices/authSlice";
import { disablePaymentReconFutureDates, downloadFile } from "../../helpers/utils";
import TableManager from "../../components/organisms/TableManager/TableManager";
import {VendorReconDataHash} from "../../slices/paymentReportSlice";
import { paymentReportColumns } from "./PaymentReport.constant";
import { DateRangeType } from "../../constants";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import DateRangeSelector, { DateRangeLabel } from "components/molecules/DateRangeSelector/DateRangeSelector";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "components/molecules/SearchByFilter/SearchByFilter";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import MultiSelectFilter from "components/molecules/MultiSelectFilter/MultiSelectFilter";
import ColorButton from "components/atoms/ColorButton/ColorButton";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SwipeableFilters from "components/organisms/SwipeableFilter/SwipeableFilter";
import { DataCol, DataRow } from "components/atoms/ChartData/ChartData";

const statusOptions = [
  {label: 'Upcoming', value: 'upcoming'},
  {label: 'Active', value: 'active'},
  {label: 'On Hold', value: 'on_hold'},
  {label: 'Failed', value: 'failed'},
  {label: 'Paid', value: 'paid'},
  {label: 'Partially Paid', value: 'partially_paid'},
];

const searchByOptions = [
  {id: 'paymentId', label: 'Payment ID'},
  {id: 'bankReferenceId', label: 'Bank Reference ID'},
  {id: 'invoiceNumber', label: 'Invoice Number'},
]

const PAGE_TITLE = 'Payments Report'

const PaymentReport = () => {
  const dispatch = useAppDispatch();

  const paymentReportData = useAppSelector((state) => state.paymentReport);
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  const avaialbleCycles = paymentReportData.paymentReportData?.available_cycles || [];
  const availableCyclesOptions = avaialbleCycles.map((cy) => ({
    label: `${cy.start_date} - ${cy.end_date}`,
    value: cy.start_date,
  }));


  const reconReportStatusFilter = useAppSelector((state: RootState) => state.filters.reconReportStatusFilter);
  const reconReportPaymentStartDateFilter = useAppSelector((state: RootState) => state.filters.reconReportPaymentStartDateFilter);
  const reconReportPaymentEndDateFilter = useAppSelector((state: RootState) => state.filters.reconReportPaymentEndDateFilter);
  const reconReportPaymentIdFilter = useAppSelector((state: RootState) => state.filters.reconReportPaymentIdFilter);
  const reconReportBankReferenceIdFilter = useAppSelector((state: RootState) => state.filters.reconReportBankReferenceIdFilter);
  const reconReportInvoiceNumberFilter = useAppSelector((state: RootState) => state.filters.reconReportInvoiceNumberFilter);
  const reconReportPaymentCycleStartDateFilter = useAppSelector((state: RootState) => state.filters.reconReportPaymentCycleStartDateFilter);

  const handleSort = (column: keyof VendorReconDataHash) => {
    const direction = column === paymentReportData.sortedColumn && paymentReportData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };

  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT, 
      {
        page_link: window.location.href, 
        page_title: PAGE_TITLE
      }
    );
  }, []);

  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById('download-button');
    if (button) {
      if(!fab) button.innerHTML = 'Downloading...';
      const userInfo = localStorage.getItem('userInfo');
      if(userInfo) {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        const downloadApi = () => dispatch(paymentReportDownloadReportApi({
          headers: headers,
          reconReportStatusFilter,
          reconReportPaymentStartDateFilter,
          reconReportPaymentEndDateFilter,
          reconReportPaymentIdFilter,
          reconReportBankReferenceIdFilter,
          reconReportInvoiceNumberFilter,
          reconReportPaymentCycleStartDateFilter,
        }));
        const success = await downloadFile({downloadApi, fileName: 'report.csv', fileType: 'text/csv'})
        if(success){
          triggerMixpanelEvent(
            MIXPANEL_EVENT_TYPES.DOWNLOAD,
            {
              report_name: PAGE_TITLE,
              download_type: 'Report'
            }
          );
        }
      }
      if(!fab) button.innerHTML = 'Download';
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const downloadVendorPaymentDetailReport = async (vendorPaymentId: number) => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      const data = await dispatch(downloadVendorPaymentDetailReportApi({
        headers: headers,
        vendorPaymentId,
      }));
      if(data) {
        const link = document.createElement('a');
        link.href = data.payload.url;
        link.download = 'vendor_payment_report.csv';
        triggerMixpanelEvent(
          MIXPANEL_EVENT_TYPES.DOWNLOAD,
          {
            download_type: 'Payout Details',
            report_name: PAGE_TITLE
          }
        );
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const downloadVendorInvoices = async (vendorPaymentId: number) => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      const data = await dispatch(getVendorInvoicesApi({
        headers: headers,
        vendorPaymentId,
      }));
      if(data) {
        const urls = data.payload?.urls || [];
        triggerMixpanelEvent(
          MIXPANEL_EVENT_TYPES.DOWNLOAD,
          {
            download_type: 'Invoices',
            report_name: PAGE_TITLE
          }
        );
        urls.forEach((url:string) => {
          const decodedUrl = decodeURIComponent(url);
          const match = decodedUrl.match(/(?<=filename=").*?(?=")/);
          const link = document.createElement('a');
          const fileName = match ? match[0] : 'vendor-payment-invoice.pdf';
          link.href = url;
          link.target = "_blank";
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    }
  }

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(paymentReportApi({
        headers: headers,
        reconReportStatusFilter,
        reconReportPaymentStartDateFilter,
        reconReportPaymentEndDateFilter,
        reconReportPaymentIdFilter,
        reconReportBankReferenceIdFilter,
        reconReportInvoiceNumberFilter,
        reconReportPaymentCycleStartDateFilter,
      })).unwrap();
      setPage(0);
      setLoaderActive(false);
      setFilterApplied(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const trackFilterEvent = async () => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_INTERACTION,
      {
        report_name: PAGE_TITLE,
        filter: ['Due Date Range' , ...appliedSearchFilters.map(f => f.label)]
      }
    )
  }

  useEffect(() => {
    if(filterApplied){
      trackFilterEvent();
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const clearFilters = () => {
    dispatch(resetFilter());
    setFilterApplied(true);
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string[]}) => {
    switch(id){
      case 'paymentId': {
        dispatch(setReconReportPaymentIdFilter(value as string)); break;
      }
      case 'bankReferenceId': {
        dispatch(setReconReportBankReferenceIdFilter(value as string)); break;
      }
      case 'invoiceNumber': {
        dispatch(setReconReportInvoiceNumberFilter(value as string)); break;
      }
      case 'status' : {
        dispatch(setReconReportStatusFilter(value as string[])); break;
      }
      case 'paymentCycle' : {
        dispatch(setReconReportPaymentCycleStartDateFilter(value as string[])); break;
      }
    }
    setFilterApplied(true);
  }

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSwipeableDrawerFilters = (idValueMap: Record<string, (string | boolean| string[])>) => {
    for(const [key, value] of Object.entries(idValueMap)){
      switch(key){
        case 'status' : {
          dispatch(setReconReportStatusFilter(value as string[])); break;
        }
        case 'paymentCycle' : {
          dispatch(setReconReportPaymentCycleStartDateFilter(value as string[])); break;
        }
      }
    }
    setFilterApplied(true);
  }

  const onSearchByClear = (id: string) => {
    if(id === 'status' || id === 'paymentCycle')
      handleFilterValueChange({id, value: []});
    else 
      handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setReconReportPaymentIdFilter(''));
    dispatch(setReconReportBankReferenceIdFilter(''));
    dispatch(setReconReportInvoiceNumberFilter(''));
    dispatch(setReconReportStatusFilter([]));
    dispatch(setReconReportPaymentCycleStartDateFilter([]));
    setFilterApplied(true);
  }

  const appliedSearchFilters:AppliedFilterType[] = [
    ...(!!reconReportPaymentIdFilter ? [{id: 'paymentId', label: 'Payment ID', value: reconReportPaymentIdFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!reconReportBankReferenceIdFilter ? [{id: 'bankReferenceId', label: 'Bank Reference ID', value: reconReportBankReferenceIdFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!reconReportInvoiceNumberFilter ? [{id: 'invoiceNumber', label: 'Invoice Number', value: reconReportInvoiceNumberFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!reconReportStatusFilter.length ? [{id: 'status', label: 'Status', value: reconReportStatusFilter, type: 'multi-select', options: statusOptions}] : []) as AppliedFilterType[],
    ...(!!reconReportPaymentCycleStartDateFilter.length ? [{id: 'paymentCycle', label: 'Payment Cycle', value: reconReportPaymentCycleStartDateFilter, type: 'multi-select', options: availableCyclesOptions}] : []) as AppliedFilterType[],
  ];

  const handleStartEndDateSelect = (ranges: DateRangeType) => {
    dispatch(setReconReportPaymentStartDateFilter(ranges.startDate as Date));
    dispatch(setReconReportPaymentEndDateFilter(ranges.endDate as Date));
    dispatch(setReconReportPaymentCycleStartDateFilter([]));
    setFilterApplied(true);
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="Payments">
        <DateRangeLabel
          startDate={reconReportPaymentStartDateFilter}
          endDate={reconReportPaymentEndDateFilter}
          onClick={() => {setShowDateRangeFilter(true)}}
        />
      </MainHeader>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />

          {!isMobile? (
            <>
              <MultiSelectFilter 
                label={'Status'}
                values={reconReportStatusFilter}
                options={statusOptions}
                onSubmit={(values) => {
                  dispatch(setReconReportStatusFilter(values));
                  setFilterApplied(true);
                }}
              />
              <MultiSelectFilter 
                label={'Payment Cycle'}
                values={reconReportPaymentCycleStartDateFilter}
                options={availableCyclesOptions}
                onSubmit={(values) => {
                  dispatch(setReconReportPaymentCycleStartDateFilter(values));
                  setFilterApplied(true);
                }}
              />
            </>
          ): (
            <Fab variant="extended" size="small" className={styles.filterFAB} onClick={() => setFilterOpen(true)}>
              <FilterAltOutlinedIcon fontSize="small" />
              Filter
            </Fab>
          )}
        </Box>
        {!isMobile ? (
          <Button
            id="download-button"
            className={styles.downloadBtn}
            onClick={() => downloadReport()}
          >
            Download
          </Button>
        ):(
          <Fab className={styles.downloadFAB} onClick={() => downloadReport(true)} size='medium' id="download-button">
            <FileDownloadOutlinedIcon fontSize='small' />
          </Fab>
        )}
      </Box>
        <SwipeableFilters
          open={filterOpen}
          onOpen={() => setFilterOpen(true)}
          onClose={() => setFilterOpen(false)}
          onAction={handleSwipeableDrawerFilters}
          multiSelectFilters={[
            {
              id: 'status',
              label: 'Status',
              type: 'multiSelect',
              value: reconReportStatusFilter,
              options: statusOptions,
            },
            {
              id: 'paymentCycle',
              label: 'Payment Cycle',
              type: 'multiSelect',
              value: reconReportPaymentCycleStartDateFilter,
              options: availableCyclesOptions
            },
          ]}
      />
      {appliedSearchFilters.length ? (
        <SearchSelections
          appliedFilters={appliedSearchFilters} 
          allClear={onSearchByAllClear} 
          onClear={onSearchByClear}
        />
      ): null}

      <Loader show={loaderActive} />

      {!loaderActive && (
        <Box className={styles.paymentSummaryWrapper}>
          <DataRow 
            className={styles['payment-summary']}
            separator
            children={[
              <DataCol 
                colClass={styles['individual-payment']}
                labelClass={styles["payment-cycle"]}
                valueClass={styles['payment-value']}
                label={"Last Payment (" + paymentReportData.paymentReportData?.payment_data.last_payment_data.payment_cycle + ")"} 
                value={paymentReportData.paymentReportData?.payment_data.last_payment_data.amount}
              />,
              <DataCol 
                colClass={styles['individual-payment']}
                labelClass={styles["payment-cycle"]}
                valueClass={styles['payment-value']}
                label={"Upcoming Payment (" + paymentReportData.paymentReportData?.payment_data.active_payment_data.payment_cycle + ")"} 
                value={paymentReportData.paymentReportData?.payment_data.active_payment_data.amount}
              />,
              <DataCol 
                colClass={styles['individual-payment']}
                labelClass={styles["payment-cycle"]}
                valueClass={styles['payment-value']}
                label={"Forthcoming Payment (" + paymentReportData.paymentReportData?.payment_data.next_payment_data.payment_cycle + ")"} 
                value={paymentReportData.paymentReportData?.payment_data.next_payment_data.amount}
              />,
            ]}
          />
        </Box>
      )}

      {!loaderActive && (
        <TableManager<VendorReconDataHash>
          data={paymentReportData?.paymentReportData?.payment_data?.vendor_recon_data || []}
          columns={paymentReportColumns({downloadVendorPaymentDetailReport, downloadVendorInvoices})}
          sortedColumn={paymentReportData.sortedColumn}
          handleSort={handleSort}
          sortDirection={paymentReportData.sortDirection}
          showPagination
          totalCount={paymentReportData?.paymentReportData?.payment_data?.vendor_recon_data.length || 0}
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={reconReportPaymentStartDateFilter}
        endDate={reconReportPaymentEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={'Select Date Range'}
        onOpen={() => {setShowDateRangeFilter(true)}}
        disabledDates={disablePaymentReconFutureDates}
      />
      
    </Box>
  );
};
  
export default PaymentReport;
