import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

export type LorReportDataHash = {
  product_variant_id: number;
  product_title: string;
  product_variant_title: string;
  product_image: string;
  product_variant_sku_code: string;
  rate_of_sales_per_day_quantity: number;
  rate_of_sales_per_day_value: number;
  lor: number;
  loss_of_days: number;
  unique_key: number;
  fbv_enabled: boolean;
  fbv_warehouse: string;
}

type LorReportData = {
  lor_report_data: LorReportDataHash[];
}

type LorReportApiState = {
  lorReportData?: LorReportData | null;
  lorReportStatus: "idle" | "loading" | "failed";
  lorReportError: string | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof LorReportDataHash | null;
};

const initialState: LorReportApiState = {
  lorReportData: null,
  lorReportStatus: "loading",
  lorReportError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};

export const lorReportApi = createAsyncThunk(
  "lorReportApi",
  async ({productName, productId, selectDays, fbvEnabled, fbvWarehouse, lorReportShowHistoricalDataFilter, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      let url = `/forge/inventories/lor_report?product_id=${productId}&product_name=${productName}&select_days=${selectDays}&fbv_enabled=${!!fbvWarehouse.length}&show_historical_data=${lorReportShowHistoricalDataFilter}`;
      fbvWarehouse.forEach((warehouseName: string) => {
        url += `&fbv_warehouse[]=${warehouseName}`;
      });
      const response = await axiosInstance.get(url, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const lorReportDownloadReportApi = createAsyncThunk(
  "lorReportDownloadReportApi",
  async ({productName, productId, selectDays, fbvEnabled, fbvWarehouse, lorReportShowHistoricalDataFilter, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      let url = `/forge/inventories/lor_report.csv?product_id=${productId}&product_name=${productName}&select_days=${selectDays}&fbv_enabled=${!!fbvWarehouse.length}&show_historical_data=${lorReportShowHistoricalDataFilter}`;
      fbvWarehouse.forEach((warehouseName: string) => {
        url += `&fbv_warehouse[]=${warehouseName}`;
      });
      const response = await axiosInstance.get(url, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);



export const markNonReplenishableProductsApi = createAsyncThunk(
  "markNonReplenishableProductsApi",
  async ({headers, payload} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.put(`/forge/inventories/mark_non_replenishable_products`, payload, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);


export const bulkMarkNonReplenishableProductsApi = createAsyncThunk(
  "bulkMarkNonReplenishableProductsApi",
  async ({payload, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.post(`/forge/requests/bulk_mark_non_replenishable_products`, payload, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);


const lorReportSlice = createSlice({
  name: 'lorReport',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof LorReportDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      // Sorting logic
      if(state.lorReportData && column){
        const sorted = sortRecords(state.lorReportData.lor_report_data, column, direction);
        state.lorReportData.lor_report_data = sorted as LorReportDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(lorReportApi.pending, (state) => {
        state.lorReportStatus = "loading";
        state.lorReportError = null;
      })
      .addCase(
        lorReportApi.fulfilled,
        (state, action: PayloadAction<LorReportData>) => {
          state.lorReportStatus = "idle";
          state.lorReportData = action.payload;
        }
      )
      .addCase(lorReportApi.rejected, (state, action) => {
        state.lorReportStatus = "failed";
        if (action.payload) {
          state.lorReportError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.lorReportError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = lorReportSlice.actions;
export default lorReportSlice.reducer;
