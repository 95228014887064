import styles from './MRPLabel.module.scss';
import { useAppDispatch, useAppSelector } from 'hooks/redux-hooks';
import React, { useEffect, useState } from 'react';
import {
  downloadProcessedFile,
  downloadMRPLabels,
  MRPLabelDataHash,
  MRPLabelsTrackingApi,
  setSort,
  bulkGenerateMrpLabelsApi,
} from 'slices/mrpLabelSlice';
import {
  MIXPANEL_EVENT_TYPES,
  triggerMixpanelEvent,
} from 'hooks/mixpanel_hook';
import { resetAuth } from 'slices/authSlice';
import { resetFilter } from 'slices/filtersSlice';
import {
  Box,
  useMediaQuery,
  useTheme,
  BottomNavigation,
  Paper,
} from '@mui/material';
import { downloadFile } from 'helpers/utils';
import MainHeader from 'components/atoms/MainHeader/MainHeader';
import Loader from 'components/atoms/Loader/Loader';
import TableManager from 'components/organisms/TableManager/TableManager';
import { MRPLabelColumns } from './MRPLabel.constant';
import ButtonX from 'components/atoms/ButtonX/ButtonX';
import { DEFAULT_BLACK_COLOR, ROUTES } from '../../constants';
import FileUpload from 'components/molecules/FileUpload/FileUpload';
import { NotificationType, showNotification } from 'slices/notificationSlice';
import MRPLabelModal from 'components/molecules/MRPLabelModal/MRPLabelModal';

const PAGE_TITLE = 'MRP Label';

const MRPLabel: React.FC = () => {
  const dispatch = useAppDispatch();
  const mrpLabelTrackingData = useAppSelector((state) => state.mrpLabel);
  const isDemoUser = useAppSelector((state) => state.auth.basicUserInfo?.email || '').includes('demo');

  const [loaderActive, setLoaderActive] = useState<Boolean>(true);
  const [showFileUploadPopup, setShowFileUploadPopup] =
    useState<boolean>(false);
  const [showMRPLabelPopup, setMRPLabelPopup] = useState<boolean>(false);

  const handleSort = (column: keyof MRPLabelDataHash) => {
    const direction =
      column === mrpLabelTrackingData.sortedColumn &&
      mrpLabelTrackingData.sortDirection === 'asc'
        ? 'desc'
        : 'asc';
    dispatch(setSort({ column, direction }));
  };

  useEffect(() => {
    triggerMixpanelEvent(MIXPANEL_EVENT_TYPES.PAGE_VISIT, {
      page_link: window.location.href,
      page_title: PAGE_TITLE,
    });
  }, []);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabMode = useMediaQuery(theme.breakpoints.down('md'));

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(
        MRPLabelsTrackingApi({
          headers: headers,
        })
      ).unwrap();
      setPage(0);
      setLoaderActive(false);
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const downloadMrpLabelsFile = async (id: number | string) => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      const downloadApi = () =>
        dispatch(
          downloadMRPLabels({
            requestId: id,
            headers: headers,
          })
        );
      const success = await downloadFile({
        downloadApi,
        fileName: 'MRP_Labels.pdf',
        fileType: 'application/pdf',
      });
      if (success) {
        triggerMixpanelEvent(MIXPANEL_EVENT_TYPES.DOWNLOAD, {
          report_name: PAGE_TITLE,
          download_type: 'Processed MRP Labels',
        });
      }
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };
  const downloadUploadedProcessedReport = async (id: number | string) => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      const downloadApi = () =>
        dispatch(
          downloadProcessedFile({
            requestId: id,
            headers: headers,
          })
        );
      const success = await downloadFile({
        downloadApi,
        fileName: 'report.csv',
        fileType: 'text/csv',
      });
      if (success) {
        triggerMixpanelEvent(MIXPANEL_EVENT_TYPES.DOWNLOAD, {
          report_name: PAGE_TITLE,
          download_type: 'Processed MRP Labels Uploaded File',
        });
      }
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  useEffect(() => {
    setLoaderActive(true);
    fetchData();
  }, []);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

  const downloadSampleFile = () => {
    const csvContent =
      'SKUID,MRP,WEIGHT,DIMENSIONS,MATERIAL,INCLUDES\n' +
      'SKU-1,100,1.5,14cm*14cm*14cm,Ceramic,Pot Without Plants\n' +
      'SKU-2,150,2.0,15cm*27cm*13cm,Ceramic,Pot With Plants\n' +
      'SKU-3,200,2.5,12cm*17cm*23cm,Ceramic,Bottle';
    const csvBlob = new Blob([csvContent], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    link.setAttribute('href', csvUrl);
    link.setAttribute('download', 'sample.csv');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function generateFileName(isBulk = true) {
    const serialNumber = Date.now().toString().slice(-4);
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = String(now.getFullYear());
    const dateFormatted = `${day}${month}${year}`;
    const type = isBulk ? 'Multiple' : 'Single';
    const fileName = `${serialNumber}${dateFormatted}${type}`;

    return fileName;
  }

  const uploadBulkGenerateMrpLabel = async (file: File, isBulk: boolean) => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      try {
        dispatch(
          showNotification({
            type: NotificationType.Info,
            message: 'Uploading file...',
          })
        );
        const token = JSON.parse(userInfo).token;
        const headers = token ? { Authorization: `${token}` } : undefined;
        const payload = new FormData();
        // Generate filename on the frontend
        const filename = generateFileName(isBulk);
        payload.set('csv_file', file);
        payload.set('filename', filename);
        await dispatch(bulkGenerateMrpLabelsApi({ payload, headers })).unwrap();
        setLoaderActive(true);
        await fetchData(); // Re-fetch data after updating
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label={PAGE_TITLE} allowBack={isTabMode}>
        {!isMobile ? (
          <>
            <ButtonX
              className={styles.generateSingleBtn}
              color={DEFAULT_BLACK_COLOR}
              variant='outlined'
              size='small'
              onClick={() => setMRPLabelPopup(true)}
              disabled={isDemoUser}
            >
              Generate Single
            </ButtonX>
            <ButtonX
              size='small'
              className={styles.generateMultipleBtn}
              onClick={() => setShowFileUploadPopup(true)}
              disabled={isDemoUser}
            >
              Generate Multiple
            </ButtonX>
          </>
        ) : (
          <></>
        )}
      </MainHeader>

      <Loader show={loaderActive} />
      {!loaderActive && (
        <TableManager<MRPLabelDataHash>
          data={mrpLabelTrackingData?.mrpLabelsData || []}
          columns={MRPLabelColumns({
            downloadReport: downloadUploadedProcessedReport,
            downloadMrpLabelsReport: downloadMrpLabelsFile,
          })}
          sortedColumn={mrpLabelTrackingData.sortedColumn}
          handleSort={handleSort}
          sortDirection={mrpLabelTrackingData.sortDirection}
          showPagination
          totalCount={mrpLabelTrackingData?.mrpLabelsData?.length || 0}
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      )}

      {isMobile ? (
        <Paper className={styles.bottomBarWrapper} elevation={3}>
          <BottomNavigation className={styles.bottomBar}>
            <ButtonX
              className={styles.generateSingleBtn}
              color={DEFAULT_BLACK_COLOR}
              variant='outlined'
              size='large'
              onClick={() => setMRPLabelPopup(true)}
              disabled={isDemoUser}
            >
              Generate Single
            </ButtonX>
            <ButtonX
              className={styles.generateMultipleBtn}
              size='large'
              onClick={() => setShowFileUploadPopup(true)}
              disabled={isDemoUser}
            >
              Generate Multiple
            </ButtonX>
          </BottomNavigation>
        </Paper>
      ) : (
        <></>
      )}

      <FileUpload
        open={showFileUploadPopup}
        label={'Generate Multiple MRP Label (Bulk)'}
        sampleFileHandler={downloadSampleFile}
        fileType={['text/csv']}
        multiple={false}
        onDiscard={() => setShowFileUploadPopup(false)}
        onSubmit={(files) => {
          uploadBulkGenerateMrpLabel(files[0], true);
        }}
      />

      <MRPLabelModal
        open={showMRPLabelPopup}
        onClose={() => setMRPLabelPopup(false)}
        onOpen={() => setMRPLabelPopup(true)}
        onSubmit={(files) => {
          const file = files[0];
          uploadBulkGenerateMrpLabel(file, false);
        }}
      />
    </Box>
  );
};

export default MRPLabel;
