import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box, Button, Fab,  Tab,  Tabs,  useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './ReturnExchangeReport.module.scss';
import { setSort, returnsTrackingApi, ReturnDataHash, returnTrackingDownloadReportApi } from "../../slices/returnsTrackingSlice";
import FbvIcon from '../../images/fbv-icon.png';
import Loader from "components/atoms/Loader/Loader";
import { 
  setReturnsReportReceivedAtStartDateFilter,
  setReturnsReportReceivedAtEndDateFilter,
  setReturnsReportOrderNumberFilter,
  setReturnsReportSKUFilter,
  setReturnsReportReasonsFilter,
  setReturnsReportTrackingNumberFilter,
  setReturnsReportFbvEnabledFilter,
  setReturnsReportReturnedAtStartDateFilter,
  setReturnsReportReturnedAtEndDateFilter,
  setReturnsReportFbvWarehouseFilter,
  resetFilter,
} from "../../slices/filtersSlice";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { resetAuth } from "../../slices/authSlice";
import { fixedColumn, returnsReportColumns } from "./ReturnsReport.constant";
import TableManager from "../../components/organisms/TableManager/TableManager";
import SwipeableFilters from "components/organisms/SwipeableFilter/SwipeableFilter";
import { downloadFile } from "helpers/utils";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import DateRangeSelector, { DateRangeLabel } from "components/molecules/DateRangeSelector/DateRangeSelector";
import NamedToggle from "components/atoms/NamedToggle/NamedToggle";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "components/molecules/SearchByFilter/SearchByFilter";
import CheckboxFilter from "components/atoms/CheckboxFilter/CheckboxFilter";

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import MultiStepProgressDrawer from "components/molecules/MultiStepProgressDrawer/MultiStepProgressDrawer";
import ColorButton from "components/atoms/ColorButton/ColorButton";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { DateRangeType, DEFAULT_BLACK_COLOR, ROUTE_REPORT_MAP, ROUTES } from "../../constants";
import MultiSelectFilter from "components/molecules/MultiSelectFilter/MultiSelectFilter";
import { useNavigate } from "react-router-dom";

const reasons = [
  'Changed my mind',
  'Received damaged products',
  'Received wrong or incorrect products',
  'Product not same as image on website',
  'Quality issues with product',
];

const PAGE_TITLE = 'Returns Report'

const ReturnsReport = () => {
  const dispatch = useAppDispatch();

  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);

  const returnsReportData = useAppSelector((state) => state.returnsReport);

  const navigate = useNavigate();

  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);

  const handleSort = (column: keyof ReturnDataHash) => {
    const direction = column === returnsReportData.sortedColumn && returnsReportData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };

  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT, 
      {
        page_link: window.location.href,
        page_title: PAGE_TITLE
      }
    );
  }, []);

  const returnsReportReturnedAtStartDateFilter = useAppSelector((state: RootState) => state.filters.returnsReportReturnedAtStartDateFilter);
  const returnsReportReturnedAtEndDateFilter = useAppSelector((state: RootState) => state.filters.returnsReportReturnedAtEndDateFilter);
  const returnsReportReceivedAtStartDateFilter = useAppSelector((state: RootState) => state.filters.returnsReportReceivedAtStartDateFilter);
  const returnsReportReceivedAtEndDateFilter = useAppSelector((state: RootState) => state.filters.returnsReportReceivedAtEndDateFilter);
  const returnsReportManufactureErrorFilter = useAppSelector((state: RootState) => state.filters.returnsReportManufactureErrorFilter);
  const returnsReportOrderNumberFilter = useAppSelector((state: RootState) => state.filters.returnsReportOrderNumberFilter);
  const returnsReportSKUFilter = useAppSelector((state: RootState) => state.filters.returnsReportSKUFilter);
  const returnsReportReasonsFilter = useAppSelector((state: RootState) => state.filters.returnsReportReasonsFilter);
  const returnsReportTrackingNumberFilter = useAppSelector((state: RootState) => state.filters.returnsReportTrackingNumberFilter);
  const returnsReportFbvEnabledFilter = useAppSelector((state: RootState) => state.filters.returnsReportFbvEnabledFilter);  
  const returnsReportFbvWarehouseFilter = useAppSelector((state: RootState) => state.filters.returnsReportFbvWarehouseFilter)
  
  const warehouseOptions = useAppSelector((state) => (state.filters.fbvWarehouseFilter || []).map((c) => ({ label: c.name, value: c.name })));
  
  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(returnsTrackingApi({
        startDateFilter: returnsReportReturnedAtStartDateFilter,
        endDateFilter: returnsReportReturnedAtEndDateFilter,
        returnsReportReceivedAtStartDateFilter,
        returnsReportReceivedAtEndDateFilter,
        returnsReportManufactureErrorFilter,
        returnsReportOrderNumberFilter,
        returnsReportSKUFilter,
        returnsReportReasonsFilter,
        returnsReportTrackingNumberFilter,
        returnsReportFbvEnabledFilter,
        returnsReportFbvWarehouseFilter,
        headers,
      })).unwrap();
      setPage(0);
      setFilterApplied(false);
      setLoaderActive(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };


  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById('download-button');
    if (button) {
      if(!fab) button.innerHTML = 'Downloading...';
      const userInfo = localStorage.getItem('userInfo');
      if(userInfo) {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        let downloadApi: () => Promise<any>  = () => dispatch(returnTrackingDownloadReportApi({
          startDateFilter: returnsReportReturnedAtStartDateFilter,
          endDateFilter: returnsReportReturnedAtEndDateFilter,
          returnsReportReceivedAtStartDateFilter,
          returnsReportReceivedAtEndDateFilter,
          returnsReportOrderNumberFilter,
          returnsReportSKUFilter,
          returnsReportReasonsFilter,
          returnsReportTrackingNumberFilter,
          returnsReportFbvEnabledFilter,
          returnsReportFbvWarehouseFilter,
          headers,
        }));
        const success = await downloadFile({downloadApi, fileName: 'report.csv', fileType: 'text/csv'})
        if(success){
          triggerMixpanelEvent(
            MIXPANEL_EVENT_TYPES.DOWNLOAD,
            {
              report_name: PAGE_TITLE,
              download_type: 'Report'
            }
          );
        }
      }else{
        dispatch(resetAuth());
        dispatch(resetFilter());
      }
      if(!fab) button.innerHTML = 'Download';
    }
  };  

  const trackFilterEvent = async () => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_INTERACTION,
      {
        report_name: PAGE_TITLE,
        filter: [
          (returnsReportReturnedAtStartDateFilter ? 'Return Approved Date Range' : 'Return Deliverd Date Range'),
          ...appliedSearchFilters.map(f => f.label)
        ]
      }
    )
  }

  useEffect(() => {
    if(filterApplied){
      trackFilterEvent();
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const clearFilters = () => {
    dispatch(resetFilter());
    setFilterApplied(true);
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };


  const [showReturnDate, setShowReturnDate] = useState(true);
  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);

  useEffect(() => {
    if(!!returnsReportReturnedAtStartDateFilter){
      setShowReturnDate(true);
    }else{
      setShowReturnDate(false);      
    }
  }, []);

  const updateDisplayDate = () => {
    if(showReturnDate){
      setShowReturnDate(false);
      dispatch(setReturnsReportReturnedAtStartDateFilter(undefined));
      dispatch(setReturnsReportReturnedAtEndDateFilter(undefined));
      dispatch(setReturnsReportReceivedAtStartDateFilter(returnsReportReturnedAtStartDateFilter));
      dispatch(setReturnsReportReceivedAtEndDateFilter(returnsReportReturnedAtEndDateFilter));
    }else{
      setShowReturnDate(true);
      dispatch(setReturnsReportReturnedAtStartDateFilter(returnsReportReceivedAtStartDateFilter));
      dispatch(setReturnsReportReturnedAtEndDateFilter(returnsReportReceivedAtEndDateFilter));
      dispatch(setReturnsReportReceivedAtStartDateFilter(undefined));
      dispatch(setReturnsReportReceivedAtEndDateFilter(undefined));
    }
    setFilterApplied(true);
  }

  const searchByOptions = [
    {id: 'sku', label: 'SKU'},
    {id: 'orderNumber', label: 'Order Number'},
    {id: 'awbNumber', label: 'AWB No'},
  ]

  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string []}) => {
    switch(id){
      case 'sku': {
        dispatch(setReturnsReportSKUFilter(value as string)); break;
      }
      case 'orderNumber': {
        dispatch(setReturnsReportOrderNumberFilter(value as string)); break;
      }
      case 'awbNumber': {
        dispatch(setReturnsReportTrackingNumberFilter(value as string)); break;
      }
      case 'reasons': {
        dispatch(setReturnsReportReasonsFilter(value as string [])); break;
      }
      case 'fbvEnabled': {
        dispatch(setReturnsReportFbvEnabledFilter(value as boolean)); break;
      }
      case 'fbvWarehouse' :{
        dispatch(setReturnsReportFbvWarehouseFilter(value as string[])); break;
      }
    }
    setFilterApplied(true);
  }

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSwipeableDrawerFilters = (idValueMap: Record<string, (string | boolean| string[])>) => {
    for(const [key, value] of Object.entries(idValueMap)){
      switch(key){
        case 'fbvEnabled' : {
          dispatch(setReturnsReportFbvEnabledFilter(value as boolean)); break;
        }
        case 'reasons' : {
          dispatch(setReturnsReportReasonsFilter(value as string[])); break;
        }
        case 'fbvWarehouse' :{
          dispatch(setReturnsReportFbvWarehouseFilter(value as string[])); break;
        }
      }
    }
    setFilterApplied(true);
  }

  const onSearchByClear = (id: string) => {
    if(id === 'reasons')
      handleFilterValueChange({id, value: []});
    else if(id === 'fbvEnabled')
      handleFilterValueChange({id, value: false});
    else if(id === 'fbvWarehouse')
      handleFilterValueChange({id, value: []});
    else
      handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setReturnsReportSKUFilter(''));
    dispatch(setReturnsReportOrderNumberFilter(''));
    dispatch(setReturnsReportTrackingNumberFilter(''));
    dispatch(setReturnsReportReasonsFilter([]));
    dispatch(setReturnsReportFbvEnabledFilter(false));
    dispatch(setReturnsReportFbvWarehouseFilter([]));
    setFilterApplied(true);
  }

  const reasonOptions = reasons.map((r => ({label: r, value: r})));

  const appliedSearchFilters: AppliedFilterType[] = [
    ...(!!returnsReportSKUFilter ? [{id: 'sku', label: 'SKU', value: returnsReportSKUFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!returnsReportOrderNumberFilter ? [{id: 'orderNumber', label: 'Order Number', value: returnsReportOrderNumberFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!returnsReportTrackingNumberFilter ? [{id: 'awbNumber', label: 'AWB No', value: returnsReportTrackingNumberFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!returnsReportReasonsFilter.length ? [{id: 'reasons', label: 'Reasons', value: returnsReportReasonsFilter, type: 'multi-select', options: reasonOptions}] : []) as AppliedFilterType[],
    ...(!!returnsReportFbvEnabledFilter ? [{id: 'fbvEnabled', label: 'FBV', value: returnsReportFbvEnabledFilter, type: 'checkbox'}] : []) as AppliedFilterType[],
    ...(!!returnsReportFbvWarehouseFilter.length ? [{id: 'fbvWarehouse', label: 'FBV Warehouse', value: returnsReportFbvWarehouseFilter, type: 'multi-select', options: warehouseOptions}] : []) as AppliedFilterType[],
  ];

  const [showProgressDrawer, setShowProgressDrawer] = React.useState(false);
  const [trackingProgress, setTrackingProgress] = React.useState<{label: string, value: string}[]>([]);

  const showTrackingProgress = (steps: {label: string, value: string}[]) => {
    setTrackingProgress(steps);
    setShowProgressDrawer(true);
  }

  const handleStartEndDateSelect = (ranges: DateRangeType) => {
    if(showReturnDate){
      dispatch(setReturnsReportReturnedAtStartDateFilter(ranges.startDate as Date));
      dispatch(setReturnsReportReturnedAtEndDateFilter(ranges.endDate as Date));
    }else{
      dispatch(setReturnsReportReceivedAtStartDateFilter(ranges.startDate as Date));
      dispatch(setReturnsReportReceivedAtEndDateFilter(ranges.endDate as Date));
    }
    setFilterApplied(true);
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="Returns & Exchange Report">
        {!isMobile && (
          <NamedToggle
            trueLabel={'Return delivered'} 
            falseLabel={'Return date'}
            onChange={updateDisplayDate}
            value={!showReturnDate}
          />
        )}
        <DateRangeLabel
          startDate={showReturnDate ? returnsReportReturnedAtStartDateFilter : returnsReportReceivedAtStartDateFilter}
          endDate={showReturnDate ? returnsReportReturnedAtEndDateFilter : returnsReportReceivedAtEndDateFilter}
          onClick={() => {setShowDateRangeFilter(true)}}
          of={isMobile ? (showReturnDate ? 'Return Date' : 'Return Delivered'): undefined}
        />
      </MainHeader>

      <Box className={styles.pageTabsWrapper}>
        <Tabs
          value={window.location.pathname}
          onChange={() => {
            triggerMixpanelEvent(
              MIXPANEL_EVENT_TYPES.REPORT_VIEWED, 
              {
                report_name: ROUTE_REPORT_MAP[ROUTES.EXCHANGES],
                source: 'Navigation'
              }
            );
            navigate(ROUTES.EXCHANGES);
          }}
          TabIndicatorProps={{
            sx: {
              backgroundColor: '#4F76FE',
            }
          }}
        >
          <Tab label="Returns" value={ROUTES.RETURNS} style={{ textTransform: 'none', fontWeight:400, color: window.location.pathname === ROUTES.RETURNS ? '#3361FF' : DEFAULT_BLACK_COLOR}}/>
          <Tab label="Exchanges" value={ROUTES.EXCHANGES} style={{ textTransform: 'none', fontWeight: 400, color: window.location.pathname === ROUTES.EXCHANGES ? '#3361FF' : DEFAULT_BLACK_COLOR}}/>
        </Tabs>
      </Box>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />

          {!isMobile? (
            <>
              <MultiSelectFilter 
                label={'Reasons'}
                values={returnsReportReasonsFilter}
                options={reasonOptions}
                onSubmit={(values) => {
                  dispatch(setReturnsReportReasonsFilter(values));
                  setFilterApplied(true);
                }}
              />
              {fbv && <MultiSelectFilter
                  label={'FBV Warehouse'}
                  values={returnsReportFbvWarehouseFilter}
                  options={warehouseOptions}
                  allowAll={true}
                  onSubmit={(val) => {
                    dispatch(setReturnsReportFbvWarehouseFilter(val));
                    setFilterApplied(true);
                  }}
                />
              }
            </>
          ): (
            <Fab variant="extended" size="small" className={styles.filterFAB} onClick={() => setFilterOpen(true)}>
              <FilterAltOutlinedIcon fontSize="small" />
              Filter
            </Fab>
          )}
        </Box>
        {!isMobile ? (
          <Button
            id="download-button"
            className={styles.downloadBtn}
            onClick={() => downloadReport()}
          >
            Download
          </Button>
        ):(
          <Fab className={styles.downloadFAB} onClick={() => downloadReport(true)} size='medium' id="download-button">
            <FileDownloadOutlinedIcon fontSize='small' />
          </Fab>
        )}
      </Box>
        <SwipeableFilters
          open={filterOpen}
          onOpen={() => setFilterOpen(true)}
          onClose={() => setFilterOpen(false)}
          onAction={handleSwipeableDrawerFilters}
          multiSelectFilters={[
            ...(fbv ? [
              {
                id: 'fbvWarehouse',
                label: 'FBV Warehouse',
                type: 'multiSelect' as 'multiSelect',
                value: returnsReportFbvWarehouseFilter,
                options: warehouseOptions,
              }
            ] : []),
            {
              id: 'reasons',
              label: 'Reasons',
              type: 'multiSelect',
              value: returnsReportReasonsFilter,
              options: reasonOptions,
            },
          ]}
      />
      {appliedSearchFilters.length ? (
        <SearchSelections
          appliedFilters={appliedSearchFilters} 
          allClear={onSearchByAllClear} 
          onClear={onSearchByClear}
        />
      ): null}

      <Loader show={loaderActive} />

      {!loaderActive && (
        <TableManager<ReturnDataHash>
          data={returnsReportData?.returnsTrackingData?.returns_data || []}
          columns={returnsReportColumns({showTrackingProgress, isMobile})}
          sortedColumn={returnsReportData.sortedColumn}
          handleSort={handleSort}
          sortDirection={returnsReportData.sortDirection}
          showPagination
          totalCount={returnsReportData?.returnsTrackingData?.returns_data?.length || 0}
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          fixedColumn={isMobile ? fixedColumn: undefined}
        />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={showReturnDate ? returnsReportReturnedAtStartDateFilter: returnsReportReceivedAtStartDateFilter}
        endDate={showReturnDate ? returnsReportReturnedAtEndDateFilter: returnsReportReceivedAtEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={
          isMobile ? 
          <NamedToggle
            trueLabel={'Return delivered'} 
            falseLabel={'Return date'}
            onChange={updateDisplayDate}
            value={!showReturnDate}
          /> : 'Select Date Range'
        }
        onOpen={() => {setShowDateRangeFilter(true)}}
      />

      <MultiStepProgressDrawer
        steps={trackingProgress}
        open={showProgressDrawer}
        onOpen={() => setShowProgressDrawer(true)}
        onClose={() => setShowProgressDrawer(false)}
      />
    </Box>
  );

};
  
export default ReturnsReport;
