import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./FileUpload.module.scss";
import ModalBox from "components/atoms/ModalBox/ModalBox";
import { Box } from "@mui/material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ColorButton from "components/atoms/ColorButton/ColorButton";
import uplodedFileIcon from "images/uploaded_file_icon.png";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

type FileUploadPropType = {
  label: JSX.Element | React.ReactNode;
  sampleFileHandler?: () => void;
  fileType: string[];
  multiple: boolean;
  onDiscard: () => void;
  onSubmit: (file: File[]) => void;
  open: boolean;
}

const FileUpload:React.FC<FileUploadPropType> = (props) => {
  const {open, label, sampleFileHandler, fileType, multiple, onDiscard, onSubmit} = props;
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const uploadRef = useRef<HTMLInputElement|null>(null);

  useEffect(() => {
    setSelectedFiles([]);
  }, [open]);


  const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const {classList} = e.target as HTMLDivElement;
    classList.add(styles.withDrag);
  }, [styles]);

  const onDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const {classList} = e.target as HTMLDivElement;
    classList.remove(styles.withDrag);
  }, [styles]);

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const {classList} = e.target as HTMLDivElement;
    classList.remove(styles.withDrag);
    if(e.dataTransfer?.files?.length){
      const files = e.dataTransfer.files;
      if(!multiple && files.length > 1){
        return;
      }
      const filteredFiles:File[] = [];
      for(let i = 0 ; i < files.length ; i += 1){
        if(fileType.includes(files[i].type)){
          filteredFiles.push(files[i]);
        }
      }
      setSelectedFiles([...filteredFiles]);
    }
  };


  const handleUpload = () => {
    if(uploadRef.current){
      uploadRef.current.click();
    }
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setSelectedFiles([...e.target.files]);
    }
    e.target.value = "";
  }

  const deleteFile = (ith: number) => {
    setSelectedFiles((prev) => {
      return  prev.filter((_, index) => index !== ith);
    });
  }

  const handleSubmit = () => {
    onSubmit(selectedFiles);
    onDiscard();
  }

  return (
    <ModalBox open={open} onClose={onDiscard} className={styles.fileUploadModal}>
      <p className={styles.label}>{label}</p>
      {sampleFileHandler && (
        <Box className={styles.sampleFile}>
          <p className={styles.defaultTxt}>Sample File:</p>
          <p className={styles.downloadSampleIcon} onClick={sampleFileHandler}>
            <FileDownloadOutlinedIcon fontSize='small' /> Download
          </p>
        </Box>
      )}
      {selectedFiles.length > 0 ? (
        <Box className={styles.uploadedFiles}>
          {selectedFiles.map((file, idx) => {
            return <FileItem file={file} onDelete={() => deleteFile(idx)} />
          })}
        </Box>
      ): (
        <Box className={styles.dragArea} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
          <FileUploadOutlinedIcon />
          <p className={styles.uploadDefaultTxt}>
            Drag & Drop or <span onClick={handleUpload}>Choose</span> file to upload
          </p>
        </Box>
      )}
      <Box className={styles.actionBtns}>
        <ColorButton bgColor="#FFF" className={styles['clear-button']} variant="outlined" onClick={onDiscard}>
          Discard
        </ColorButton>
        <ColorButton bgColor="#3361FF" bgHoverColor="#2E58E8" className={styles['submit-button']} onClick={handleSubmit} disabled={!selectedFiles.length}>
          Submit
        </ColorButton>
      </Box>
      <input ref={uploadRef}
        type="file"
        multiple={multiple}
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        accept={fileType.join(', ')}
      />
    </ModalBox>
  );
}

export default FileUpload;

type FileItemType = {
  file: File;
  onDelete: () => void;
}

const FileItem:React.FC<FileItemType> = (props) => {
  const {file, onDelete} = props;
  return (
    <Box className={styles.fileItem}>
      <img src={uplodedFileIcon} alt="Uploaded File Icon" />
      <Box className={styles.fileInfo}>
        <p className={styles.fileName}>{file.name}</p>
        <p className={styles.fileSizeAndType}>
          {`${(file.size / 1000).toFixed(3)}KB`}  •  {file.type}
        </p>
      </Box>
      <CloseOutlinedIcon
        fontSize="small"
        className={styles.deleteIcon}
        onClick={onDelete}
      />
    </Box>
  );
}