import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box, Button, Fab, useMediaQuery, useTheme } from '@mui/material';
import { RootState } from '../../store';
import { setSort, fbvStockChargesReportDownloadReportApi, fbvStockChargesReportApi, fbvStockChargesDownloadInvoicesApi } from "../../slices/fbvStockChargesReportSlice";
import styles from "./FbvStockChargesReport.module.scss";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  setFbvStorageChargesReportStartDateFilter,
  setFbvStorageChargesReportEndDateFilter,
  resetFilter,
  setFbvStorageChargesReportProductIdFilter,
  setFbvStorageChargesReportProductTitleFilter,
  setFbvStorageChargesReportFbvWarehouseFilter
} from "../../slices/filtersSlice";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { resetAuth } from "../../slices/authSlice";
import TableManager from "../../components/organisms/TableManager/TableManager";
import { fixedColumn, fbvStockChargesReportColumns } from "./FbvStockChargesReport.constant";
import { checkCurrencyNA, downloadFile } from "../../helpers/utils";
import SwipeableFilters from "components/organisms/SwipeableFilter/SwipeableFilter";
import SelectFilter from "components/molecules/SelectFilter/SelectFilter";
import MultiSelectFilter from 'components/molecules/MultiSelectFilter/MultiSelectFilter';
import MainHeader from "components/atoms/MainHeader/MainHeader";
import Loader from "components/atoms/Loader/Loader";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "components/molecules/SearchByFilter/SearchByFilter";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { FbvStorageChargesDataHash } from "slices/fbvStockChargesReportSlice";
import { DataCol, DataRow } from "components/atoms/ChartData/ChartData";
import { formatCurrency } from "helpers/formatUtils";
import cloud_download_icon from "images/cloud_download_icon_white.svg";
import MonthSelector, { SelectedMonth } from "components/molecules/MonthSelector/MonthSelector";

const searchByOptions = [
  { id: 'productId', label: 'Product ID' },
  { id: 'productName', label: 'Product Name' },
]

const PAGE_TITLE = 'FBV Storage Fees Report'
const FbvStockChargesReport = () => {

  const dispatch = useAppDispatch();
  const storage_fee = useAppSelector((state) => state.fbvStockChargesReport?.fbvStockChargesReportData?.storage_fee) || 'NA';
  const pickpack_fee = useAppSelector((state) => state.fbvStockChargesReport?.fbvStockChargesReportData?.pickpack_fee) || 'NA';
  const total_pickpack_charges = useAppSelector((state) => state.fbvStockChargesReport?.fbvStockChargesReportData?.total_pickpack_charges);
  const total_storage_charges = useAppSelector((state) => state.fbvStockChargesReport?.fbvStockChargesReportData?.total_storage_charges);
  const total_charges = useAppSelector((state) => state.fbvStockChargesReport?.fbvStockChargesReportData?.total_charges);
  const fromDate = useAppSelector((state) => state.fbvStockChargesReport?.fbvStockChargesReportData?.from_date);
  const toDate = useAppSelector((state) => state.fbvStockChargesReport?.fbvStockChargesReportData?.to_date);

  const fbvStockChargesReportReport = useAppSelector((state) => state.fbvStockChargesReport);
  const fbvStorageChargesReportStartDateFilter = useAppSelector((state: RootState) => state.filters.fbvStorageChargesReportStartDateFilter);
  const fbvStorageChargesReportEndDateFilter = useAppSelector((state: RootState) => state.filters.fbvStorageChargesReportEndDateFilter);
  const fbvStorageChargesReportProductIdFilter = useAppSelector((state: RootState) => state.filters.fbvStorageChargesReportProductIdFilter);
  const fbvStorageChargesReportProductTitleFilter = useAppSelector((state: RootState) => state.filters.fbvStorageChargesReportProductTitleFilter);
  const fbvStorageChargesReportFbvWarehouseFilter = useAppSelector((state: RootState) => state.filters.fbvStorageChargesReportFbvWarehouseFilter);
  const monthValue = `${fbvStorageChargesReportStartDateFilter.getMonth()}`;

  const [filterOpen, setFilterOpen] = useState(false);
  const [showMonthOptions, setShowMonthOptions] = useState<boolean>(false);
  const [selectedMonths, setSelectedMonths] = useState<SelectedMonth[]>([]);

  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(false);
  const [monthOptions, setMonthOptions] = useState<{ label: string, value: string }[]>([]);

  const handleSort = (column: keyof FbvStorageChargesDataHash) => {
    const direction = column === fbvStockChargesReportReport.sortedColumn && fbvStockChargesReportReport.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };

  // TODO: Need to update
  useEffect(() => {
    const today = new Date();
    const options = [];
    for (let i = 3; i <= today.getMonth(); i += 1) {
      options.push({
        label: (new Date(today.getFullYear(), i, 1)).toLocaleString('default', { month: 'long' }),
        value: `${i}`,
      });
    }
    setMonthOptions(options);
  }, []);

  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT,
      {
        page_link: window.location.href,
        page_title: PAGE_TITLE
      }
    );
  }, []);

  const warehouseOptions = useAppSelector((state) => (state.filters.fbvWarehouseFilter || []).map((c) => ({ label: c.name, value: c.name })));

  const appliedSearchFilters: AppliedFilterType[] = [
    ...(!!fbvStorageChargesReportProductIdFilter ? [{id: 'productId', label: 'Product ID', value: fbvStorageChargesReportProductIdFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!fbvStorageChargesReportProductTitleFilter ? [{id: 'productName', label: 'Product Name', value: fbvStorageChargesReportProductTitleFilter, type: 'search'}] : []) as AppliedFilterType[] ,
    ...(!!fbvStorageChargesReportFbvWarehouseFilter.length ? [{id: 'fbvWarehouse', label: 'FBV Warehouse', value: fbvStorageChargesReportFbvWarehouseFilter, type: 'multi-select', options: warehouseOptions}] : []) as AppliedFilterType[],
  ];

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(fbvStockChargesReportApi({
        fbvStorageChargesReportStartDateFilter,
        fbvStorageChargesReportEndDateFilter,
        fbvStorageChargesReportProductIdFilter,
        fbvStorageChargesReportProductTitleFilter,
        fbvStorageChargesReportFbvWarehouseFilter,
        headers: headers
      })).unwrap();
      setPage(0);
      setLoaderActive(false);
      setFilterApplied(false);
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const handleStartEndDateSelect = (month: number) => {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), month, 1);
    const endDate = new Date(today.getFullYear(), month + 1, 0);
    dispatch(setFbvStorageChargesReportStartDateFilter(startDate));
    dispatch(setFbvStorageChargesReportEndDateFilter(endDate));
  };

  const trackFilterEvent = async () => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_INTERACTION,
      {
        report_name: PAGE_TITLE,
        filter: ['Month', ...appliedSearchFilters.map(f => f.label)]
      }
    )
  }

  useEffect(() => {
    if (filterApplied) {
      trackFilterEvent();
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied,]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById('download-button');
    if (button) {
      if (!fab) button.innerHTML = 'Downloading...';
      const userInfo = localStorage.getItem('userInfo');
      if (userInfo) {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        const downloadApi = () => dispatch(fbvStockChargesReportDownloadReportApi({
          fbvStorageChargesReportStartDateFilter,
          fbvStorageChargesReportEndDateFilter,
          fbvStorageChargesReportProductIdFilter,
          fbvStorageChargesReportProductTitleFilter,
          fbvStorageChargesReportFbvWarehouseFilter,
          headers: headers
        }));
        const success = await downloadFile({ downloadApi, fileName: 'report.csv', fileType: 'text/csv' })
        if (success) {
          triggerMixpanelEvent(
            MIXPANEL_EVENT_TYPES.DOWNLOAD,
            {
              download_type: 'Report',
              report_name: PAGE_TITLE,
            }
          );
        }
      } else {
        dispatch(resetAuth());
        dispatch(resetFilter());
      }
      if (!fab) button.innerHTML = 'Download';
    }
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

  const handleFilterValueChange = ({ id, value }: { id: string, value: string | boolean | string[] }) => {
    switch (id) {
      case 'productId': {
        dispatch(setFbvStorageChargesReportProductIdFilter(value as string)); break;
      }
      case 'productName': {
        dispatch(setFbvStorageChargesReportProductTitleFilter(value as string)); break;
      }
      case 'fbvWarehouse' :{
        dispatch(setFbvStorageChargesReportFbvWarehouseFilter(value as string[])); break;
      }
    }
    setFilterApplied(true);
  }

  const onSearchByClear = (id: string) => {
    if(id === 'fbvWarehouse')
      handleFilterValueChange({id, value: []});
    else
      handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setFbvStorageChargesReportProductIdFilter(''));
    dispatch(setFbvStorageChargesReportProductTitleFilter(''));
    dispatch(setFbvStorageChargesReportFbvWarehouseFilter([]));
    setFilterApplied(true);
  }

  const handleSwipeableDrawerFilters = (idValueMap: Record<string, (string | boolean| string[])>) => {
    for(const [key, value] of Object.entries(idValueMap)){
      switch(key){
        case 'monthOfCharges' : {
          handleStartEndDateSelect(+(value as string)); break;
        }
        case 'fbvWarehouse' :{
          dispatch(setFbvStorageChargesReportFbvWarehouseFilter(value as string[])); break;
        }
      }
    }
    setFilterApplied(true);
  }

  const downloadStorageInvoices = async () => {
    setShowMonthOptions(false);
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      try {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        const data = await dispatch(fbvStockChargesDownloadInvoicesApi({
          selectedMonths,
          headers: headers
        }));
        if (data) {
          const urls = data.payload?.invoice_urls || [];
          urls.forEach((url: string) => {
            const decodedUrl = decodeURIComponent(url);
            const match = decodedUrl.match(/(?<=filename=").*?(?=")/);
            const link = document.createElement('a');
            const fileName = match ? match[0] : 'vendor-storage-charges-invoice.pdf';
            link.href = url;
            link.target = "_blank";
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  }

  const handleMonthSelection = (month: number, year: number) => {
    const present = selectedMonths.findIndex(
      (op) => op.month === month && op.year === year
    );
    if (present !== -1) {
      setSelectedMonths((prev) => prev.filter((op) => !(op.month === month && op.year === year)));
    } else {
      setSelectedMonths((prev) => [...prev, { month, year }]);
    }
  };

  const handleDisabledMonths = (month: number, year: number): boolean => {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    // Disable months from the current month onwards in the same year
    if (year === currentYear && month >= currentMonth) return true;
    // Disable all months in future years
    if (year > currentYear) return true;
    return false;
  };

  return (
    <Box className={styles.reportWrapper}>
      {/* Header Section */}
      <MainHeader label="FBV Storage Charges" allowBack={isMobile}>
        {!isMobile && (
          <ChargesHeaderSection
            data={[
              { label: 'FBV storage fees:', value: storage_fee === 'NA' ? 'NA' : `${formatCurrency(storage_fee)}/m` },
              { label: 'Pick & Pack Fees:', value: pickpack_fee === 'NA' ? 'NA' : `${formatCurrency(pickpack_fee)}/qty` },
            ]}
          />
        )}
      </MainHeader>

      <Box className={styles.totalChargeDetails}>
        <p>Total FBV Storage Charges</p>
        <MonthSelector
          onClick={handleMonthSelection}
          showCalendar={showMonthOptions}
          disabledMonths={handleDisabledMonths}
          setShowCalendar={setShowMonthOptions}
          selectedMonths={selectedMonths}
          onClear={() => setSelectedMonths([])}
          onSubmit={downloadStorageInvoices}
        >
          <Button
            className={styles.invoiceDownload}
            onClick={() => setShowMonthOptions(true)}
          >
            <img src={cloud_download_icon} alt="Invoice" />
            {isMobile ? "Invoice" : "Download Invoice"}
            {isMobile ? "" : <KeyboardArrowDownIcon />}
          </Button>
        </MonthSelector>
      </Box>

      {/* Pick Pack and Storage Summary Section */}
      <Box className={styles.pickpackStorageSummaryWrapper}>
        <DataRow
          className={styles['pickpack-storage-summary']}
          separator
          children={[
            <DataCol
              colClass={styles['individual-fee']}
              labelClass=""
              valueClass={styles['fee-value']}
              label="For the period of"
              value={`${fromDate} - ${toDate}`}
            />,
            <DataCol
              colClass={styles['individual-fee']}
              labelClass=""
              valueClass={styles['fee-value']}
              label="Pick Pack Charges"
              value={checkCurrencyNA(total_pickpack_charges)}
            />,
            <DataCol
              colClass={styles['individual-fee']}
              labelClass=""
              valueClass={styles['fee-value']}
              label="Storage Charges"
              value={checkCurrencyNA(total_storage_charges)}
            />,
            <DataCol
              colClass={styles['individual-fee']}
              labelClass=""
              valueClass={styles['fee-value']}
              label="Total Charges"
              value={checkCurrencyNA(total_charges)}
            />,
          ]}
        />
      </Box>

      {/* Charges Section for Mobile */}
      {isMobile && (
        <ChargesHeaderSection
          className={styles.chargesWrapper}
          data={[
            { label: 'FBV storage fees:', value: storage_fee === 'NA' ? 'NA' : `${formatCurrency(storage_fee)}/m` },
            { label: 'Pick & Pack Fees:', value: pickpack_fee === 'NA' ? 'NA' : `${formatCurrency(pickpack_fee)}/qty` },
          ]}
        />
      )}

      {/* Filter and Download Section */}
      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          {!isMobile ? (
            <>
              <SelectFilter
                label="Month"
                value={monthValue}
                options={monthOptions}
                onChange={(value) => {
                  handleStartEndDateSelect(+value);
                  setFilterApplied(true);
                }}
              />
              <MultiSelectFilter
                label={'FBV Warehouse'}
                values={fbvStorageChargesReportFbvWarehouseFilter}
                options={warehouseOptions}
                allowAll={true}
                onSubmit={(val) => {
                  dispatch(setFbvStorageChargesReportFbvWarehouseFilter(val));
                  setFilterApplied(true);
                }}
              />
            </>
          ) : (
            <Fab
              variant="extended"
              size="small"
              className={styles.filterFAB}
              onClick={() => setFilterOpen(true)}
            >
              <FilterAltOutlinedIcon fontSize="small" />
              Filter
            </Fab>
          )}

          <SearchByFilter filters={searchByOptions} onSearch={handleFilterValueChange} />
        </Box>
        {!isMobile ? (
          <Button
            id="download-button"
            className={styles.downloadBtn}
            onClick={() => downloadReport()}
          >
            Download
          </Button>
        ) : (
          <Fab
            className={styles.downloadFAB}
            onClick={() => downloadReport(true)}
            size="medium"
            id="download-button"
          >
            <FileDownloadOutlinedIcon fontSize="small" />
          </Fab>
        )}
      </Box>

      {/* Swipeable Filters */}
      <SwipeableFilters
        open={filterOpen}
        onOpen={() => setFilterOpen(true)}
        onClose={() => setFilterOpen(false)}
        onAction={handleSwipeableDrawerFilters}
        selectFilters={[
          {
            id: 'monthOfCharges',
            label: 'Month',
            type: 'select',
            value: monthValue,
            options: monthOptions,
          },
        ]}
        multiSelectFilters={[
          {
            id: 'fbvWarehouse',
            label: 'FBV Warehouse',
            type: 'multiSelect' as 'multiSelect',
            value: fbvStorageChargesReportFbvWarehouseFilter,
            options: warehouseOptions,
          }
        ]}
      />

      {/* Search Selections */}
      {appliedSearchFilters.length ? (
        <SearchSelections
          appliedFilters={appliedSearchFilters}
          allClear={onSearchByAllClear}
          onClear={onSearchByClear}
        />
      ) : null}

      {/* Loader */}
      <Loader show={loaderActive} />

      {/* Data Table */}
      {!loaderActive && (
        <TableManager<FbvStorageChargesDataHash>
          data={fbvStockChargesReportReport?.fbvStockChargesReportData?.fbv_stock_fee_report || []}
          columns={fbvStockChargesReportColumns(
            fbvStorageChargesReportStartDateFilter.getMonth()
          )}
          sortedColumn={fbvStockChargesReportReport.sortedColumn}
          handleSort={handleSort}
          sortDirection={fbvStockChargesReportReport.sortDirection}
          showPagination
          currentPage={page}
          totalCount={fbvStockChargesReportReport?.fbvStockChargesReportData?.fbv_stock_fee_report.length || 0}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          fixedColumn={isMobile ? fixedColumn : undefined}
        />
      )}
    </Box>
  );
};

export default FbvStockChargesReport;

type ChargesHeaderSectionPropType = {
  className?: string;
  data: { label: string, value: string }[]
}

const ChargesHeaderSection: React.FC<ChargesHeaderSectionPropType> = (props) => {
  const { data, className } = props;
  return (
    <DataRow
      className={className}
      separator
      children={data.map(item => {
        return (
          <Box className={styles.chargeData}>
            <p className={styles.chargeLabel}>{item.label}</p>
            <p className={styles.chargeVal}>{item.value}</p>
          </Box>
        )
      })}
    />
  )
}