import ColorButton from "components/atoms/ColorButton/ColorButton";
import styles from "./SwipeableFilter.module.scss";
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, SwipeableDrawer } from "@mui/material";
import { csx } from "helpers/utils";
import { useEffect, useState } from "react";

type CheckBoxFilter = {
  id: string;
  label: string;
  type: 'checkbox';
  value: boolean;
}

type SelectFilter = {
  id: string;
  label: string;
  type: 'select';
  value: string;
  options: {label: string, value: string}[];
}

type MultiSelectFilter = {
  id: string;
  label: string;
  type: 'multiSelect';
  value: string[];
  options: {label: string, value: string}[];
}

type PropType = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  onAction: (idValueMap: Record<string, (string| boolean | string[])>) => void;
  checkboxFilters?: CheckBoxFilter[];
  selectFilters?: SelectFilter[];
  multiSelectFilters?: MultiSelectFilter[];
}

const SwipeableFilters:React.FC<PropType> = ({open, onOpen, onClose, checkboxFilters = [], selectFilters = [], multiSelectFilters = [], onAction}) => {


  const [currentCheckboxFilters, setCurrentCheckboxFilters] = useState<CheckBoxFilter[]>([]);
  const [currentSelectFilters, setCurrentSelectFilters] = useState<SelectFilter[]>([]);
  const [currentMultiSelectFilters, setCurrentMultiSelectFilters] = useState<MultiSelectFilter[]>([]);

  const [selectedFilter, setSelectedFilter] = useState<SelectFilter|MultiSelectFilter|null>(null);

  const [selectedValues, setSelectedValues] = useState<Record<string,(string|boolean|string[])>>({});

  const handleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }
    open ? onOpen() : onClose();
  };

  useEffect(() => {
    if(open){
      setCurrentCheckboxFilters(checkboxFilters);
      setCurrentSelectFilters(selectFilters);
      setCurrentMultiSelectFilters(multiSelectFilters);
      const idValueMap: Record<string,(string|boolean|string[])> = {};
      [...checkboxFilters, ...selectFilters, ...multiSelectFilters].forEach((f) => {
        idValueMap[f.id] = f.value;
      });
      setSelectedValues(idValueMap);
      const filter = [...selectFilters, ...multiSelectFilters][0];
      setSelectedFilter(filter || null);
    }else{
      setCurrentCheckboxFilters([]);
      setCurrentSelectFilters([]);
      setCurrentMultiSelectFilters([])
      setSelectedValues({});
      setSelectedFilter(null);
    }
  }, [open]);

  const updateCheckboxSelectedValue = (id: string, value: boolean) => {
    setSelectedValues((prevVal) => {
      return {...prevVal, [id]: value};
    });
  }

  const updateSelectSelectedValue = (id: string, value: string) => {
    setSelectedValues((prevVal) => {
      return {...prevVal, [id]: value};
    });
  }

  const updateMultiSelectSelectedValue = (id: string, value: string) => {
    const currVal = selectedValues[id] as string[];
    setSelectedValues((prevVal) => {
      if(currVal.includes(value)){
        return {...prevVal, [id]: currVal.filter(cv => cv !== value)};
      }else{
        return {...prevVal, [id]: [...currVal, value]};
      }
    });
  }

  const toggleSelectAll = (id: string) => {
    const filter = currentMultiSelectFilters.find(f => f.id === id);
    if (!filter) return;
  
    const allValues = filter.options.map(option => option.value);
    const currentValues = selectedValues[id] as string[];
  
    if (currentValues.length === allValues.length) {
      // Deselect All
      setSelectedValues((prevVal) => ({ ...prevVal, [id]: [] }));
    } else {
      // Select All
      setSelectedValues((prevVal) => ({ ...prevVal, [id]: allValues }));
    }
  };

  const onSubmit = () => {
    onAction(selectedValues);
    onClose();
  }
  
  const onClear = () => {
    const _selectedValues:Record<string,(string|boolean|string[])> = {}; 
    for (const [key, value] of Object.entries(selectedValues)) {
      if(typeof value === 'boolean'){
        _selectedValues[key] = false;
      }else if(typeof value === 'string'){
        _selectedValues[key] = '';
      }else{
        _selectedValues[key] = [];
      }
    }
    onAction(_selectedValues);
    onClose();
  }

  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={open}
      onClose={handleDrawer(false)}
      onOpen={handleDrawer(true)}
    >
      <div className={styles['swipeable-mobile-drawer']}>
        <div className={styles['filters-section']}>
          <div className={styles['filter-type']}>
            <div className={styles['select-type-filter']}>
              {[...currentSelectFilters, ...currentMultiSelectFilters].map((sf) => {
                return (
                  <div className={csx(styles['filter-item'], sf.id === selectedFilter?.id ? styles['selected'] : undefined)} onClick={() => setSelectedFilter(sf)}>
                    {sf.label}
                  </div>
                );
              })}
            </div>
            <div className={styles['checkbox-type-filter']}>
              {currentCheckboxFilters.map((cf) => {
                return (
                  <div className={csx(styles['filter-item'], styles['checkbox-item'], selectedValues[cf.id] === true ? styles['selected'] : undefined)} onClick={() => updateCheckboxSelectedValue(cf.id, !(selectedValues[cf.id] as boolean))}>
                    <div>{cf.label}</div>
                    <Checkbox checked={selectedValues[cf.id] as boolean} className={styles['checkbox']} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles['filter-options']}>
            {(selectedFilter)&&(
              <>
                <p className={styles['filter-label']}>{selectedFilter.label}</p>
                {selectedFilter.type === 'multiSelect' && (
                  <div className={styles['selected-options']}>
                    <div 
                      className={csx(styles['option'], 
                        (selectedValues[selectedFilter.id] as string[]).length === selectedFilter.options.length ? styles['selected'] : undefined)} 
                      onClick={() => toggleSelectAll(selectedFilter.id)}
                    >
                      <Checkbox
                        checked={(selectedValues[selectedFilter.id] as string[]).length === selectedFilter.options.length}
                        className={styles['checkbox']}
                      />
                      <div className={styles['label']}>Select All</div>
                    </div>
                    {selectedFilter.options.map((sf) => {
                      const label = sf.label;
                      const value = sf.value;
                      const selected = (selectedValues[selectedFilter.id] as string[]).includes(value);
                      return <div className={csx(styles['option'], selected ? styles['selected'] : undefined)}>
                          {<Checkbox checked={selected} className={styles['checkbox']} onClick={() => {updateMultiSelectSelectedValue(selectedFilter.id, value)}}/>}
                          <div className={styles['label']}>{label}</div>
                        </div>
                    })}
                  </div>
                )}
                {selectedFilter.type === 'select' && (
                  <div className={styles['selected-options']}>
                    <FormControl>
                      <RadioGroup
                        value={selectedValues[selectedFilter.id] as string}
                        onChange={(_, value) => {updateSelectSelectedValue(selectedFilter.id, value)}}
                      >
                        {selectedFilter.options.map((op) => {
                          return <FormControlLabel className={styles['radio-label']} value={op.value} control={<Radio className={styles['radio-button']} />} label={op.label} />
                        })}
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles['picker-footer']}>
          <ColorButton bgColor="#FFF" className={styles['clear-button']} variant="outlined" onClick={onClear}>
            Clear
          </ColorButton>
          <ColorButton bgColor="#3361FF" bgHoverColor="#2E58E8" className={styles['submit-button']} onClick={onSubmit}>
              Apply
          </ColorButton>
        </div>
      </div>
    </SwipeableDrawer>
  );
}

export default SwipeableFilters;