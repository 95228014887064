import {ColumnPropType} from "../../components/organisms/TableManager/TableManager"
import styles from "./IssueTracking.module.scss";
import { IssueDataHash } from "slices/issueTrackingSlice";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button } from "@mui/material";
import ColorButton from "components/atoms/ColorButton/ColorButton";

type TrackIssueColumnsPropType = {
  redirectToIssuePage: (id: string | number) => void;
}

export const issueTrackingColumns = (props: TrackIssueColumnsPropType): ColumnPropType<IssueDataHash>[]  =>  [
  {
    label: 'Ticket ID',
    accessor: 'id',
    minWidth: 125
  },
  {
    label: 'Issue',
    accessor: 'summary',
    maxWidth: 250,
    cell: ({value}) => {
      return <p className={styles.issueTxt}>
        {value}
      </p>
    }
  },
  {
    label: 'Created Date',
    accessor: 'created_at',
    minWidth: 150,
  },
  {
    label: 'Resolved Date',
    accessor: 'resolved_at',
    cell: ({value}) => value || '-',
    minWidth: 150,
  },
  {
    label: 'Status',
    accessor: 'status',
    minWidth: 250,
    cell: ({ row}) => {
      const {status, sla_breached, id} = row;
      const pillStyleClass = pillClass(status);
      return <div className={styles.statusCell}>
        <p className={pillStyleClass}>{status}</p>
        {sla_breached && status !== 'Resolved' && <p className={styles.red}>Delayed</p>}
        <ColorButton bgColor="#FFF" variant="outlined" className={styles.viewBtn} onClick={() => props.redirectToIssuePage(id)}>
          View
        </ColorButton>
      </div>
    }
  },
]


export const pillClass = (status: string) => {
  switch(status.toLowerCase()){
    case 'todo':
      return styles['gray-pill'];
    case 'resolved':
      return styles['green-pill'];
    default:
      return styles['orange-pill'];
  }
}