import "./MultiStepProgressPopover.scss";
import { Box } from "@mui/material";
import LightTooltip, { TooltipPlacementType } from "../../atoms/LightTooltip/LightTooltip";

import Typography from '@mui/material/Typography';

type StepType = {
  label: string;
  value: string;
}

type MultiStepProgressPopoverPropType = {
  steps: StepType[];
  children: JSX.Element;
  placement?: TooltipPlacementType;
}

const MultiStepProgressPopover = (props: MultiStepProgressPopoverPropType) => {

  const {steps, children, placement} = props;

  return (
    <LightTooltip 
      className="long-width"
      placement={placement}
      title={
        <Box className="popover-content">
          {steps.map((step, idx) => {
            return (
              <Box className="progress-item">
                <Typography className="label">
                  {step.label}
                </Typography>
                <Typography className="value">
                  {step.value || (idx === 0 ? 'NA' : '')}
                </Typography>
                {steps.length > 1 && <span className="step-col" />}
                {steps.length > 1 && <span className="step-bar" />}
              </Box>
            )
          })}
        </Box>
      }
    >
      {children}
    </LightTooltip>
  );
}

export default MultiStepProgressPopover;