import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import dashboardReducer from "./slices/dashboardSlice";
import orderTrackingReducer from "./slices/orderTrackingSlice";
import createIssuePostReducer from "./slices/createIssuePostSlice";
import sohReportReducer from "./slices/sohReportSlice";
import lorReportReducer from "./slices/lorReportSlice";
import salesReportReducer from "./slices/salesReportSlice";
import filtersSliceReducer from "./slices/filtersSlice";
import returnsSliceReducer from "./slices/returnsTrackingSlice";
import exchangesSliceReducer from "./slices/exchangesTrackingSlice";
import rtoReportSlice from "./slices/rtoReportSlice";
import paymentReportReducer from "./slices/paymentReportSlice";
import notificationReducer from "./slices/notificationSlice";
import resetPasswordSlice from "./slices/resetPasswordSlice";
import forgotPasswordSlice from "./slices/forgotPasswordSlice";
import fbvStockChargesReportReducer from "./slices/fbvStockChargesReportSlice";
import gstrSliceReducer from "./slices/gstrSlice";
import trackIssueReducer from "slices/issueTrackingSlice";
import productCatalogueReducer from "slices/productCatalogueSlice";
import requestTrackingSlice from "slices/requestTrackingSlice";
import mrpLabelSlice from "slices/mrpLabelSlice";
import returnPenaltySlice from "slices/returnsPenaltySlice";
import oosPenaltySlice from "slices/oosPenaltySlice";
import orderCancellationPenaltySlice from "slices/orderCancellationPenaltySlice";

import { axiosMiddleware } from "./api/middleware";

const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    orderTracking: orderTrackingReducer,
    sohReport: sohReportReducer,
    lorReport: lorReportReducer,
    salesReport: salesReportReducer,
    returnsReport: returnsSliceReducer,
    exchangesReport: exchangesSliceReducer,
    paymentReport: paymentReportReducer,
    rtoReport: rtoReportSlice,
    filters: filtersSliceReducer,
    notification: notificationReducer,
    resetPassword: resetPasswordSlice,
    forgotPassword: forgotPasswordSlice,
    gstrReport: gstrSliceReducer,
    createIssuePost: createIssuePostReducer,
    fbvStockChargesReport: fbvStockChargesReportReducer,
    trackIssue: trackIssueReducer,
    productCatalogue: productCatalogueReducer,
    trackRequest: requestTrackingSlice,
    mrpLabel: mrpLabelSlice,
    returnPenalty: returnPenaltySlice,
    oosPenalty: oosPenaltySlice,
    orderCancellationPenalty: orderCancellationPenaltySlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}).concat(axiosMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
