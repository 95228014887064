import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

export type SohReportDataHash = {
  product_variant_id: number;
  product_title: string;
  product_variant_title: string;
  product_image: string;
  product_variant_sku_code: string;
  product_soh: number;
  product_holding_days: number;
  product_soh_threshold: number;
  product_sold_quantity: number;
  product_sales_rate: number;
  fbv_enabled: boolean;
  unique_key: number;
  fbv_warehouse: string;
}

type SohReportData = {
  soh_report_data: SohReportDataHash[];
}

type SohReportApiState = {
  sohReportData?: SohReportData | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof SohReportDataHash | null;
  sohReportStatus: "idle" | "loading" | "failed";
  sohReportError: string | null;
};

const initialState: SohReportApiState = {
  sohReportData: null,
  sortDirection: 'asc',
  sortedColumn: null,
  sohReportStatus: "loading",
  sohReportError: null
};

type ErrorResponse = {
  errors: string;
};

export const sohReportApi = createAsyncThunk(
  "sohReportApi",
  async ({productName, productId, include0Ros, fbvEnabled, fbvWarehouse, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      let url = `/forge/inventories/soh_report?product_id=${productId}&product_name=${productName}&include0Ros=${include0Ros}&fbv_enabled=${!!fbvWarehouse.length}`;
      fbvWarehouse.forEach((warehouseName: string) => {
        url += `&fbv_warehouse[]=${warehouseName}`;
      });
      const response = await axiosInstance.get(url, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const sohReportDownloadReportApi = createAsyncThunk(
  "sohReportDownloadReportApi",
  async ({productName, productId, include0Ros, fbvEnabled, fbvWarehouse, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      let url = `/forge/inventories/soh_report.csv?product_id=${productId}&product_name=${productName}&include0Ros=${include0Ros}&fbv_enabled=${!!fbvWarehouse.length}`;
      fbvWarehouse.forEach((warehouseName: string) => {
        url += `&fbv_warehouse[]=${warehouseName}`;
      });
      const response = await axiosInstance.get(url, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

const sohReportSlice = createSlice({
  name: 'sohReport',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof SohReportDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;

      if(state.sohReportData && column){
        const sorted = sortRecords(state.sohReportData.soh_report_data, column, direction);
        state.sohReportData.soh_report_data = sorted as SohReportDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sohReportApi.pending, (state) => {
        state.sohReportStatus = "loading";
        state.sohReportError = null;
      })
      .addCase(
        sohReportApi.fulfilled,
        (state, action: PayloadAction<SohReportData>) => {
          state.sohReportStatus = "idle";
          state.sohReportData = action.payload;
        }
      )
      .addCase(sohReportApi.rejected, (state, action) => {
        state.sohReportStatus = "failed";
        if (action.payload) {
          state.sohReportError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.sohReportError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = sohReportSlice.actions;
export default sohReportSlice.reducer;
