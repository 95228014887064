import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

export type ReturnDataHash = {
  return_id: number,
  return_date: string,
  return_delivered: string,
  order_number: string,
  sku_list: string,
  reason: string,
  tracking_number: string,
  thumbnail: string,
  product_title: string,
  order_value: number,
  order_quantity: number,
  unique_key: number,
  fbv_enabled: boolean,
  fbv_warehouse: string
}

type ReturnsApiResponse = {
  returns_data: ReturnDataHash[]
}

type ReturnsApiState = {
  returnsTrackingData?: ReturnsApiResponse | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof ReturnDataHash | null;
  returnsTrackingStatus: "idle" | "loading" | "failed";
  returnsTrackingError: string | null;
};

const initialState: ReturnsApiState = {
  returnsTrackingData: null,
  returnsTrackingStatus: "loading",
  returnsTrackingError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};

const formatRequest = (requestData: any, download:boolean = false): string => {
  let url = `/forge/returns/returns_tracking${download ? '.csv' : ''}?`;
  url += `q[requested_at_gteq]=${requestData.startDateFilter}&`;
  url += `q[requested_at_lteq]=${requestData.endDateFilter}&`;
  url += `q[delivered_at_gteq]=${requestData.returnsReportReceivedAtStartDateFilter}&`;
  url += `q[delivered_at_lteq]=${requestData.returnsReportReceivedAtEndDateFilter}&`;
  url += `q[order_number_eq]=${requestData.returnsReportOrderNumberFilter}&`;
  url += `q[return_items_sku_eq]=${requestData.returnsReportSKUFilter}&`;
  if(!!requestData.returnsReportFbvWarehouseFilter.length){
    url += `q[return_items_order_item_vendor_location_id_null]=false&`;
  }
  for(let i = 0 ; i < requestData.returnsReportReasonsFilter.length ; i++){
    const reason = requestData.returnsReportReasonsFilter[i];
    url += `q[reason_in][]=${reason}&`;
  }
  url += `q[pickup_tracking_number_eq]=${requestData.returnsReportTrackingNumberFilter}`;
  requestData.returnsReportFbvWarehouseFilter.forEach((warehouseName: string) => {
    url += `&q[return_items_order_item_vendor_location_warehouse_name_in][]=${warehouseName}`;
  });
  return url;
}

export const returnsTrackingApi = createAsyncThunk(
  "returnsTrackingApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData);
    try {
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;
      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const returnTrackingDownloadReportApi = createAsyncThunk(
  "returnTrackingDownloadReportApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData, true);
    try {
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

const returnsTrackingSlice = createSlice({
  name: 'returnsTracking',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof ReturnDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.returnsTrackingData && column){
        const sorted = sortRecords(state.returnsTrackingData.returns_data, column, direction);
        state.returnsTrackingData.returns_data = sorted as ReturnDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(returnsTrackingApi.pending, (state) => {
        state.returnsTrackingStatus = "loading";
        state.returnsTrackingError = null;
      })
      .addCase(
        returnsTrackingApi.fulfilled,
        (state, action: PayloadAction<ReturnsApiResponse>) => {
          state.returnsTrackingStatus = "idle";
          state.returnsTrackingData = action.payload;
        }
      )
      .addCase(returnsTrackingApi.rejected, (state, action) => {
        state.returnsTrackingStatus = "failed";
        if (action.payload) {
          state.returnsTrackingError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.returnsTrackingError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = returnsTrackingSlice.actions;
export default returnsTrackingSlice.reducer;
