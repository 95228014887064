import {useState } from "react";
import styles from "./SearchByFilter.module.scss";
import { Box, ClickAwayListener} from "@mui/material";
import LightTooltip from "components/atoms/LightTooltip/LightTooltip";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ClickTooltip from "components/atoms/ClickTooltip/ClickTooltip";

type SearchByOptionType = {
  id: string;
  label: string;
}

type PropType = {
  filters: SearchByOptionType[];
  onSearch: (searchBy: SearchByOptionType & {value: string}) => void;
}

const SearchByFilter: React.FC<PropType> = ({filters, onSearch}) => {

  const [searchBy, setSearchBy] = useState<SearchByOptionType|null>(null);
  const [searchTxt, setSearchTxt] = useState<string>('');
  const [showSearchOptions, setShowSearchOptions] = useState(false);

  const onSearchTypeChange = (searchByOption: SearchByOptionType) => {
    setSearchBy(searchByOption);
    setSearchTxt('');
  }

  const handleSearch = () => {
    if(searchBy !== null && searchTxt.trim() !== ''){
      onSearch({...searchBy, value: searchTxt.trim()});
      setSearchBy(null);
      setSearchTxt('');
    }
  }

  const onSearchTxt = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const {nativeEvent: {key}} = e;
    if(key === 'Enter'){
      handleSearch();
    }
  }

  return (
    <Box className={styles['search-by-filter']}>
      <ClickTooltip
        className={styles['popover-style']}
        show={showSearchOptions}
        showPopper={setShowSearchOptions}
        placement="bottom-start"
        title={
          <>
            {filters.map((filter) => {
              return (
                <div className={styles['filter-label']} 
                  onClick={(e) => {
                    e.stopPropagation();
                    onSearchTypeChange(filter);
                    setShowSearchOptions(false);
                  }}
                >{filter.label}</div>
              )
            })}
          </>
        }
      >
        <div className={styles['search-by-wrapper']}>
          <Box className={styles['search-type']}
            onClick = {() => {
              filters.length > 0 && setShowSearchOptions((prevVal) => !prevVal);
            }}
          >
            <p>{searchBy === null ? 'Search By' : searchBy.label}</p>
            {showSearchOptions ? <ExpandLessOutlinedIcon fontSize="small" /> : <ExpandMoreOutlinedIcon fontSize="small" />}
          </Box>
          <Box className={styles['search-text']} onClick={() => {
            searchBy && showSearchOptions && setShowSearchOptions(false);
            !searchBy && setShowSearchOptions((prevVal) => !prevVal);
          }}>
            {searchBy ? (
              <input 
                type='text' 
                value={searchTxt} 
                onChange={(e) => setSearchTxt(e.target.value)} 
                placeholder='Select search by'
                onKeyDown={onSearchTxt}
              />
            ): (
              <p>Select search by</p>
            )}
            <SearchIcon fontSize="small" className={styles['search-icon']} onClick={() => {
              searchBy && !showSearchOptions && handleSearch();
            }}/>
          </Box>
        </div>
      </ClickTooltip>
    </Box>
  );

}

export default SearchByFilter;

export type SearchSelectionType = 'search' | 'select' | 'multi-select' | 'checkbox';


export type AppliedFilterType = {
  id: string;
  value: string | boolean | string[];
  label: string;
  type: SearchSelectionType;
  options?: {label: string, value: string}[];
}


type SearchSelections = {
    appliedFilters: AppliedFilterType[];
    allClear: () => void;
    onClear: (id: string) => void;
}

const getFilterLabel = (filter: AppliedFilterType):string => {
  let valueLabel:string = '';
  switch(filter.type){
    case 'search': {
      valueLabel = filter.value as string; 
      break;
    }
    case 'checkbox': {
      valueLabel = filter.label;
      break;
    }
    case 'select': {
      valueLabel = filter?.options?.filter(op => op.value === filter.value)[0].label || '';
      break;
    }
    case 'multi-select': {
      valueLabel = filter?.options?.filter(op => (filter.value as string[]).includes(op.value)).map(op => op.label).join(', ') || '';
      break;
    }
  }
  return valueLabel;
}


export const SearchSelections:React.FC<SearchSelections> = ({appliedFilters, allClear, onClear}) => {
  return (
    <Box className={styles['search-selection-wrapper']}>
      <Box className={styles['search-selections']}>
        {appliedFilters.map((filter) => {
          const valueLabel = getFilterLabel(filter);
          return <Box className={styles['selection']}>
            {filter.type === 'search' && (
              <SearchIcon fontSize="small" className={styles['search-icon']} /> 
            )}
            {filter.type !== 'checkbox' && (
              <p>{filter.label}:</p>
            )}
            <p className={styles['value']}>{valueLabel}</p>
            <ClearIcon fontSize="small" className={styles['clear-icon']} onClick={() => onClear(filter.id)} />
          </Box>
        })}
      </Box>
      <p className={styles['all-clear']} onClick={() => {allClear()}}>Clear</p>
    </Box>
  );
}