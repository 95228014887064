import {ColumnPropType} from "../../components/organisms/TableManager/TableManager"
import styles from "./MRPLabel.module.scss";
import { MRPLabelDataHash } from "slices/mrpLabelSlice";
import { DEFAULT_BLACK_COLOR } from "../../constants";
import ButtonX from "components/atoms/ButtonX/ButtonX";

type MRPLabelColumnsPropType = {
  downloadReport: (id: string | number) => void;
  downloadMrpLabelsReport: (id: string | number) => void;
}

export const MRPLabelColumns = (props: MRPLabelColumnsPropType): ColumnPropType<MRPLabelDataHash>[]  =>  [
  {
    label: 'File Name',
    accessor: 'filename',
    minWidth: 200,
  },
  {
    label: 'Processed Status',
    accessor: 'status',
    minWidth: 200,
    cell: ({row, value}) => {
      const pillStyleClass = pillClass(value);
      const {success_count, total_count, filename} = row;
      const containsMultiple = filename.toLowerCase().includes('multiple');
      const countText = (value.toLowerCase() === 'success' && containsMultiple && total_count && total_count !== 0)
      ? ` (${success_count}/${total_count})`
      : '';
      return <p className={pillStyleClass}>{value}{countText && ` ${countText}`}</p>
    }
  },
  {
    label: 'Created At',
    accessor: 'created_at',
    minWidth: 200,
  },
  {
    label: 'Attachment',
    accessor: 'id',
    minWidth: 100,
    cell: ({ row }) => {
      const { id, processed_file_present, status, filename } = row;
      const isDisabled = status.toLowerCase() === 'processing' || !processed_file_present;
      const containsSingle = filename.toLowerCase().includes('single');
      return containsSingle ? (
        <span>-</span>  
      ) : (
        <ButtonX
          variant="outlined"
          size="small"
          color={DEFAULT_BLACK_COLOR}
          className={styles.reportDownload}
          style={{minWidth:"auto"}}
          onClick={() => props.downloadReport(id)}
          disabled={isDisabled}
        >
          File
        </ButtonX>
      );
    },
  },
  {
    label: 'MRP Label',
    accessor: 'id',
    minWidth: 100,
    cell: ({row}) => {
      const {id, mrp_labels_file_present, status} = row;
      const isDisabled = status.toLowerCase() == 'failed' || !mrp_labels_file_present;
      return (
        <ButtonX
          variant="outlined"
          size="small"
          color={DEFAULT_BLACK_COLOR} 
          className={styles.reportDownload}
          onClick={() => props.downloadMrpLabelsReport(id)}
          disabled={isDisabled}
        >
          Download
        </ButtonX>
      );
    },
  },
]

export const pillClass = (status: string) => {
  switch(status.toLowerCase()){
    case 'failed':
      return styles['red-pill'];
    case 'success':
      return styles['green-pill'];
    default:
      return styles['gray-pill'];
  }
}