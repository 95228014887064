import { Box, SwipeableDrawer, Typography } from "@mui/material";
import styles from "./MultiStepProgressDrawer.module.scss";
import { csx } from "helpers/utils";

type StepType = {
  label: string;
  value: string;
}

type MultiStepDrawerPropType = {
  steps: StepType[];
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const MultiStepProgressDrawer:React.FC<MultiStepDrawerPropType> = ({steps, onOpen, onClose, open}) => {

  const handleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }
    open ? onOpen() : onClose();
  };

  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={open}
      onClose={handleDrawer(false)}
      onOpen={handleDrawer(true)}
    >
      <div className={styles.swipeableMobileDrawer}>
        <Box className={styles.content}>
          {steps.map((step, idx) => {
            return (
              <Box className={styles.progressItem}>
                <Box className={csx(styles.line, step.value && styles.hasData)}>
                  <span className={styles.stepDot} />
                  {idx > 0 && <span className={styles.stepCol} />}
                </Box>
                <Box className={styles.progressTxt}>
                  <Typography className={styles.label}>
                    {step.label}
                  </Typography>
                  <Typography className={styles.value}>
                    {step.value || (idx === 0 ? 'NA' : '')}
                  </Typography>
                </Box>
              </Box>
            )
          })}
        </Box>
      </div>
    </SwipeableDrawer>
  )
}

export default MultiStepProgressDrawer;