import styles from "./Dashboard.module.scss";
import { useAppSelector, useAppDispatch } from "../../hooks/redux-hooks";
import { Link} from "react-router-dom";
import { Typography } from "@mui/material";
import LightTooltip from "../../components/atoms/LightTooltip/LightTooltip";
import {resetFilter, setEndDateFilter, setExchangesReportSKUFilter, setReturnsReportReturnedAtEndDateFilter, setReturnsReportReturnedAtStartDateFilter, setReturnsReportSKUFilter, setStartDateFilter , setExchangesReportExchangedAtEndDateFilter, setExchangesReportExchangedAtStartDateFilter} from "../../slices/filtersSlice";
import { csx, downloadFile, getPercentVal } from "../../helpers/utils";
import { lakhFormat } from "helpers/formatUtils";
import { productReturnSummaryDownloadApi } from "../../slices/dashboardSlice";
import { RootState } from "../../store";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { resetAuth } from "../../slices/authSlice";
import { useNavigate} from "react-router-dom";
import ChartHeader from "components/atoms/ChartHeader/ChartHeader";
import ProductThumbnail from "components/atoms/ProductThumbnail/ProductThumbnail";

const ProductReturnSummaryChart = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const startDateFilter = useAppSelector((state: RootState) => state.filters.startDateFilter);
  const endDateFilter = useAppSelector((state: RootState) => state.filters.endDateFilter);

  const downloadProductSummaryReport = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token;
      const headers = token ? { Authorization: `${token}` } : undefined;
      const downloadApi = () => dispatch(productReturnSummaryDownloadApi({startDate: startDateFilter, endDate: endDateFilter, headers: headers}));
      const success = await downloadFile({downloadApi, fileName: 'report.csv' , fileType: 'text/csv'});
      if(success){
        triggerMixpanelEvent(
          MIXPANEL_EVENT_TYPES.DOWNLOAD,
          {
            report_name: `Top returning SKU's`,
            download_type: 'Report'
          }
        );
      }
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  }

  const dashboardData = useAppSelector((state) => state.dashboard);
  const product_return_summary = dashboardData.productReturnSummary?.summary || [];

  const handleChartClick = (chart_type: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.CHART_CLICK,
      {chart_type}
    );
  }

  const chartName = 'product-return-summary-chart';

  return (
    <div className={csx(styles['chart-report-wrapper'], styles[chartName])} onClick={() => handleChartClick('product_return_summary')}>
      {dashboardData.productReturnSummaryStatus === 'loading' && <p className={styles['load-err-txt']}>Loading...</p>}
      {dashboardData.productReturnSummaryStatus === 'failed' && <p className={styles['load-err-txt']}>Error: {dashboardData.productReturnSummaryError}</p>}
      {dashboardData.productReturnSummaryStatus === 'idle' && dashboardData.productReturnSummary && (
        <>
          <ChartHeader
            headerTxt={'Top Returning SKUs'}
            tooltipTxt={'Products with the highest return frequency during the specified period'}
            link={'/'}
            isDownload
            onClick={() => {
              downloadProductSummaryReport();
            }}
          />
          <div className={styles["product-item-list"]}>
            {product_return_summary.slice(0, 5).map((product) => (
              <>
                <div className={styles["order-product-details"]} onClick={() => {
                  dispatch(resetFilter());
                  dispatch(setStartDateFilter(startDateFilter));
                  dispatch(setEndDateFilter(endDateFilter));
                  dispatch(setReturnsReportReturnedAtStartDateFilter(startDateFilter));
                  dispatch(setReturnsReportReturnedAtEndDateFilter(endDateFilter));
                  dispatch(setExchangesReportExchangedAtStartDateFilter(startDateFilter));
                  dispatch(setExchangesReportExchangedAtEndDateFilter(endDateFilter));
                  dispatch(setReturnsReportSKUFilter(product.sku));
                  dispatch(setExchangesReportSKUFilter(product.sku));
                  navigate('/return-exchange-report/returns');
                }}>
                  <ProductThumbnail 
                    sku={product.sku}
                    title={product.title}
                    src={product.img_url}
                  />
                </div>
                <p className={styles["product-price"]}>{lakhFormat(product.price)}</p>
              </>
            ))}
          </div>
        </>
      )}
    </div>
  );

}

export default ProductReturnSummaryChart;