import {Typography, useMediaQuery, useTheme } from "@mui/material";
import LightTooltip from "../LightTooltip/LightTooltip";
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import styles from "./ChartHeader.module.scss";
import { Link} from "react-router-dom";
import React from "react";
import ClickTooltip from "../ClickTooltip/ClickTooltip";

type ChartHeaderPropType = {
  headerTxt: string;
  tooltipTxt: string;
  link: string;
  onClick? : () => void;
  isDownload? : boolean;
}

const ChartHeader: React.FC<ChartHeaderPropType> = ({headerTxt, tooltipTxt, link, onClick = () => {}, isDownload = false}) => {

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showClickedTooltip, setShowClickedTooltip] = React.useState(false);
  
  return (
    <div className={styles['chart-header']}>
      <div className={styles['chart-header-text-wrapper']}>
        <div className={styles['chart-header-text']}>{headerTxt} </div>
        {isMobile ? (
          <ClickTooltip
            title={<Typography style={{fontSize: 11}}>{tooltipTxt}</Typography>}
            show={showClickedTooltip}
            showPopper={setShowClickedTooltip}
          >   
            <div className={styles['report-info-icon']} onClick={() => setShowClickedTooltip(true) }>?</div>
          </ClickTooltip>
        ): (
          <LightTooltip
            placement="bottom-start"
            title={
              <Typography style={{fontSize: 11}}>{tooltipTxt}</Typography>
            }
          >
            <div className={styles['report-info-icon']}>?</div>
          </LightTooltip>
        )}
      </div>
      <Link className={styles["chart-navigation"]} to={link}
        onClick={() => onClick()}
      >
        {isDownload 
          ? 'Download' 
          : (
            <>
              {`View`} <ChevronRightOutlinedIcon fontSize="small"/>
            </>
          )
        }
        </Link>
    </div>
  );
}

export default ChartHeader;