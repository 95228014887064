import { csx } from "helpers/utils";
import styles from "./ChartData.module.scss";

type DataRowPropType = {
  separator?: boolean;
  children?: JSX.Element[];
  className?: string;
}

const DataRow: React.FC<DataRowPropType> = ({children: cols = [], separator = false, className = ''}) => {
  return <div className={csx(styles['data-row'], className)}>
    {cols.map((col, idx) => {
      return (
        <>
          {idx > 0 && separator && <div className={styles['vertical-line']} />}
          {col}
        </>
      )
    })}
  </div>
}

type DataColPropType = {
  label : React.ReactNode;
  value : React.ReactNode;
  colClass? : string;
  labelClass? : string;
  valueClass? : string;
}

const DataCol: React.FC<DataColPropType> = ({label, value, colClass = '', labelClass = '' , valueClass = ''}) => {
  return (
    <div className={csx(styles['data-column'], colClass)}>
      <div className={csx(styles['data-value'], valueClass)}>
        {value}
      </div>
      <div className={csx(styles['data-label'], labelClass)}>
        {label}
      </div> 
    </div>
  );
}

export {DataCol, DataRow};