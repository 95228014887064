import {ColumnPropType} from "../../components/organisms/TableManager/TableManager"
import styles from "./RequestTracking.module.scss";
import { RequestDataHash } from "slices/requestTrackingSlice";
import { DEFAULT_BLACK_COLOR } from "../../constants";
import ButtonX from "components/atoms/ButtonX/ButtonX";

type TrackRequestColumnsPropType = {
  downloadReport: (id: string | number) => void;
}

export const requestTrackingColumns = (props: TrackRequestColumnsPropType): ColumnPropType<RequestDataHash>[]  =>  [
  {
    label: 'ID',
    accessor: 'id',
    minWidth: 100
  },
  {
    label: 'Request Type',
    accessor: 'request_type',
    maxWidth: 250,
  },
  {
    label: 'Status',
    accessor: 'status',
    minWidth: 125,
    cell: ({value}) => {
      const pillStyleClass = pillClass(value);
      return <p className={pillStyleClass}>{value}</p>
    }
  },
  {
    label: 'Count',
    accessor: 'unique_key',
    minWidth: 125,
    cell: ({row}) => {
      const {success_count, total_count} = row;
      if(total_count === null){
        return 'NA'
      }
      return  `${success_count}/${total_count}`;
    }
  },
  {
    label: 'Updated At',
    accessor: 'updated_at',
    minWidth: 150,
  },
  {
    label: 'Actions',
    accessor: 'id',
    minWidth: 75,
    cell: ({row}) => {
      const {id, processed_file_present} = row;
      return (
        <ButtonX
          variant="outlined"
          size="small"
          color={DEFAULT_BLACK_COLOR} 
          className={styles.reportDownload}
          onClick={() => props.downloadReport(id)}
          disabled={!processed_file_present}
        >
          Download
        </ButtonX>
      );
    },
  },
]

export const pillClass = (status: string) => {
  switch(status.toLowerCase()){
    case 'failed':
      return styles['red-pill'];
    case 'success':
      return styles['green-pill'];
    default:
      return styles['orange-pill'];
  }
}