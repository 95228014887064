import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box, Button, Fab, useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './RTOReport.module.scss';
import { setSort, rtoTrackingApi, ShippingDataHash, rtoTrackingDownloadReportApi } from "../../slices/rtoReportSlice";
import FbvIcon from '../../images/fbv-icon.png';
import Loader from "components/atoms/Loader/Loader";
import { 
  setRtoReportDeliveredAtStartDateFilter,
  setRtoReportDeliveredAtEndDateFilter,
  setRtoReportTrackingNumberFilter,
  setRtoReportFbvEnabledFilter,
  setRtoReportOrderedAtStartDateFilter,
  setRtoReportOrderedAtEndDateFilter,
  setRtoReportFbvWarehouseFilter,
  setOrderReportOrderNoFilter,
  resetFilter,
} from "../../slices/filtersSlice";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { resetAuth } from "../../slices/authSlice";
import TableManager from "../../components/organisms/TableManager/TableManager";
import { fixedColumn, rtoReportColumns } from "./RTOReport.constant";
import { downloadFile } from "helpers/utils";
import { DateRangeType } from "../../constants";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import NamedToggle from "components/atoms/NamedToggle/NamedToggle";
import DateRangeSelector, { DateRangeLabel } from "components/molecules/DateRangeSelector/DateRangeSelector";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "components/molecules/SearchByFilter/SearchByFilter";
import CheckboxFilter from "components/atoms/CheckboxFilter/CheckboxFilter";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ColorButton from "components/atoms/ColorButton/ColorButton";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SwipeableFilters from "components/organisms/SwipeableFilter/SwipeableFilter";
import MultiStepProgressDrawer from "components/molecules/MultiStepProgressDrawer/MultiStepProgressDrawer";
import MultiSelectFilter from 'components/molecules/MultiSelectFilter/MultiSelectFilter';

const PAGE_TITLE = 'RTO Report'

const RtoReport = () => {

  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);

  const dispatch = useAppDispatch();

  const rtoReportData = useAppSelector((state) => state.rtoReport);
  const [filterOpen, setFilterOpen] = useState(false);
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);

  const handleSort = (column: keyof ShippingDataHash) => {
    const direction = column === rtoReportData.sortedColumn && rtoReportData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };

  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT, 
      {
        page_link: window.location.href,
        page_title: PAGE_TITLE
      }
    );
  }, []);

  const rtoReportOrderedAtStartDateFilter = useAppSelector((state: RootState) => state.filters.rtoReportOrderedAtStartDateFilter);
  const rtoReportOrderedAtEndDateFilter = useAppSelector((state: RootState) => state.filters.rtoReportOrderedAtEndDateFilter);
  const rtoReportDeliveredAtStartDateFilter = useAppSelector((state: RootState) => state.filters.rtoReportDeliveredAtStartDateFilter);
  const rtoReportDeliveredAtEndDateFilter = useAppSelector((state: RootState) => state.filters.rtoReportDeliveredAtEndDateFilter);
  const rtoReportTrackingNumberFilter = useAppSelector((state: RootState) => state.filters.rtoReportTrackingNumberFilter);
  const rtoReportFbvEnabledFilter = useAppSelector((state: RootState) => state.filters.rtoReportFbvEnabledFilter);
  const orderNoFilter = useAppSelector((state: RootState) => state.filters.orderReportOrderNoFilter);
  const rtoReportFbvWarehouseFilter = useAppSelector((state: RootState) => state.filters.rtoReportFbvWarehouseFilter)
  
  const warehouseOptions = useAppSelector((state) => (state.filters.fbvWarehouseFilter || []).map((c) => ({ label: c.name, value: c.name })));
  
  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      try {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        await dispatch(rtoTrackingApi({
          startDateFilter : rtoReportOrderedAtStartDateFilter,
          endDateFilter: rtoReportOrderedAtEndDateFilter,
          orderNo: orderNoFilter,
          rtoReportDeliveredAtStartDateFilter,
          rtoReportDeliveredAtEndDateFilter,
          rtoReportTrackingNumberFilter,
          rtoReportFbvEnabledFilter,
          rtoReportFbvWarehouseFilter,
          headers,
        })).unwrap();
        setPage(0);
        setLoaderActive(false);
        setFilterApplied(false);
      } catch (e) {
        console.error(e);
      }
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const trackFilterEvent = async () => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_INTERACTION,
      {
        report_name: PAGE_TITLE,
        filter: [
          (rtoReportOrderedAtStartDateFilter ? 'Ordered At Date Range' : 'RTO Deliverd Date Range'),
          ...appliedSearchFilters.map(f => f.label)
        ]
      }
    )
  }

  useEffect(() => {
    if(filterApplied){
      trackFilterEvent();
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const clearFilters = () => {
    dispatch(resetFilter());
    setFilterApplied(true);
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };


  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById('download-button');
    if (button) {
      if(!fab) button.innerHTML = 'Downloading...';
      const userInfo = localStorage.getItem('userInfo');
      if(userInfo) {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        let downloadApi: () => Promise<any>;
        downloadApi = () => dispatch(rtoTrackingDownloadReportApi({
          startDateFilter: rtoReportOrderedAtStartDateFilter,
          endDateFilter: rtoReportOrderedAtEndDateFilter,
          orderNo: orderNoFilter,
          rtoReportDeliveredAtStartDateFilter,
          rtoReportDeliveredAtEndDateFilter,
          rtoReportTrackingNumberFilter,
          rtoReportFbvEnabledFilter,
          rtoReportFbvWarehouseFilter,
          headers,
        }));
        const success = await downloadFile({downloadApi, fileName: 'report.csv', fileType: 'text/csv'})
        if(success){
          triggerMixpanelEvent(
            MIXPANEL_EVENT_TYPES.DOWNLOAD,
            {
              report_name: PAGE_TITLE,
              download_type: 'Report'
            }
          );
        }
      }else{
        dispatch(resetAuth());
        dispatch(resetFilter());
      }
      if(!fab) button.innerHTML = 'Download';
    }
  };

  const [showOrderDate, setShowOrderDate] = useState(true);
  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);

  useEffect(() => {
    if(!!rtoReportOrderedAtStartDateFilter){
      setShowOrderDate(true);
    }else{
      setShowOrderDate(false);      
    }
  }, []);

  const updateDisplayDate = () => {
    if(showOrderDate){
      setShowOrderDate(false);
      dispatch(setRtoReportOrderedAtStartDateFilter(undefined));
      dispatch(setRtoReportOrderedAtEndDateFilter(undefined));
      dispatch(setRtoReportDeliveredAtStartDateFilter(rtoReportOrderedAtStartDateFilter));
      dispatch(setRtoReportDeliveredAtEndDateFilter(rtoReportOrderedAtEndDateFilter));
    }else{
      setShowOrderDate(true);
      dispatch(setRtoReportOrderedAtStartDateFilter(rtoReportDeliveredAtStartDateFilter));
      dispatch(setRtoReportOrderedAtEndDateFilter(rtoReportDeliveredAtEndDateFilter));
      dispatch(setRtoReportDeliveredAtStartDateFilter(undefined));
      dispatch(setRtoReportDeliveredAtEndDateFilter(undefined));
    }
    setFilterApplied(true);
  }

  const searchByOptions = [
    {id: 'awbNumber', label: 'AWB No'},
    {id: 'orderNumber', label: 'Order Number'},
  ]

  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string[]}) => {
    switch(id){
      case 'awbNumber': {
        dispatch(setRtoReportTrackingNumberFilter(value as string)); break;
      }
      case 'fbvEnabled': {
        dispatch(setRtoReportFbvEnabledFilter(value as boolean)); break;
      }
      case 'orderNumber': {
        dispatch(setOrderReportOrderNoFilter(value as string)); break;
      }
      case 'fbvWarehouse' :{
        dispatch(setRtoReportFbvWarehouseFilter(value as string[])); break;
      }
    }
    setFilterApplied(true);
  }

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSwipeableDrawerFilters = (idValueMap: Record<string, (string | boolean| string[])>) => {
    for(const [key, value] of Object.entries(idValueMap)){
      switch(key){
        case 'fbvEnabled' : {
          dispatch(setRtoReportFbvEnabledFilter(value as boolean)); break;
        }
        case 'fbvWarehouse' :{
          dispatch(setRtoReportFbvWarehouseFilter(value as string[])); break;
        }
      }
    }
    setFilterApplied(true);
  }

  const onSearchByClear = (id: string) => {
    if(id === 'fbvEnabled')
      handleFilterValueChange({id, value: false});
    else if(id === 'fbvWarehouse')
      handleFilterValueChange({id, value: []});
    else
      handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setRtoReportTrackingNumberFilter(''));
    dispatch(setRtoReportFbvEnabledFilter(false));
    dispatch(setOrderReportOrderNoFilter(''));
    dispatch(setRtoReportFbvWarehouseFilter([]));
    setFilterApplied(true);
  }

  const appliedSearchFilters:AppliedFilterType[] = [
    ...(!!rtoReportTrackingNumberFilter ? [{id: 'awbNumber', label: 'AWB No', value: rtoReportTrackingNumberFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!orderNoFilter ? [{id: 'orderNumber', label: 'Order Number', value: orderNoFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!rtoReportFbvEnabledFilter ? [{id: 'fbvEnabled', label: 'FBV', value: rtoReportFbvEnabledFilter, type: 'checkbox'}] : []) as AppliedFilterType[],
    ...(!!rtoReportFbvWarehouseFilter.length ? [{id: 'fbvWarehouse', label: 'FBV Warehouse', value: rtoReportFbvWarehouseFilter, type: 'multi-select', options: warehouseOptions}] : []) as AppliedFilterType[],
  ];

  const [showProgressDrawer, setShowProgressDrawer] = React.useState(false);
  const [trackingProgress, setTrackingProgress] = React.useState<{label: string, value: string}[]>([]);

  const showTrackingProgress = (steps: {label: string, value: string}[]) => {
    setTrackingProgress(steps);
    setShowProgressDrawer(true);
  }

  const handleStartEndDateSelect = (ranges: DateRangeType) => {
    if(showOrderDate){
      dispatch(setRtoReportOrderedAtStartDateFilter(ranges.startDate as Date));
      dispatch(setRtoReportOrderedAtEndDateFilter(ranges.endDate as Date));
    }else{
      dispatch(setRtoReportDeliveredAtStartDateFilter(ranges.startDate as Date));
      dispatch(setRtoReportDeliveredAtEndDateFilter(ranges.endDate as Date));
    }
    setFilterApplied(true);
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="RTO Report">
        {!isMobile && (
          <NamedToggle
            trueLabel={'RTO delivered'} 
            falseLabel={'Order date'}
            onChange={updateDisplayDate}
            value={!showOrderDate}
          />
        )}
        <DateRangeLabel
          startDate={showOrderDate ? rtoReportOrderedAtStartDateFilter : rtoReportDeliveredAtStartDateFilter}
          endDate={showOrderDate ? rtoReportOrderedAtEndDateFilter : rtoReportDeliveredAtEndDateFilter}
          onClick={() => {setShowDateRangeFilter(true)}}
          of={isMobile ? (showOrderDate ? 'Order Date' : 'RTO Delivered'): undefined}
        />
      </MainHeader>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />

          {!isMobile? (
            <>
              {fbv && <MultiSelectFilter
                  label={'FBV Warehouse'}
                  values={rtoReportFbvWarehouseFilter}
                  options={warehouseOptions}
                  allowAll={true}
                  onSubmit={(val) => {
                    dispatch(setRtoReportFbvWarehouseFilter(val));
                    setFilterApplied(true);
                  }}
                />
              }
            </>
          ): (
            <Fab variant="extended" size="small" className={styles.filterFAB} onClick={() => setFilterOpen(true)}>
              <FilterAltOutlinedIcon fontSize="small" />
              Filter
            </Fab>
          )}
        </Box>
        {!isMobile ? (
          <Button
            id="download-button"
            className={styles.downloadBtn}
            onClick={() => downloadReport()}
          >
            Download
          </Button>
        ):(
          <Fab className={styles.downloadFAB} onClick={() => downloadReport(true)} size='medium' id="download-button">
            <FileDownloadOutlinedIcon fontSize='small' />
          </Fab>
        )}
      </Box>
        <SwipeableFilters
          open={filterOpen}
          onOpen={() => setFilterOpen(true)}
          onClose={() => setFilterOpen(false)}
          onAction={handleSwipeableDrawerFilters}
          multiSelectFilters={[
            ...(fbv ? [
              {
                id: 'fbvWarehouse',
                label: 'FBV Warehouse',
                type: 'multiSelect' as 'multiSelect',
                value: rtoReportFbvWarehouseFilter,
                options: warehouseOptions,
              }
            ] : [])
          ]}
      />
      {appliedSearchFilters.length ? (
        <SearchSelections
          appliedFilters={appliedSearchFilters} 
          allClear={onSearchByAllClear} 
          onClear={onSearchByClear}
        />
      ): null}

      <Loader show={loaderActive} />

      {!loaderActive && (
        <TableManager<ShippingDataHash>
          data={rtoReportData?.rtoTrackingData?.rto_data || []}
          columns={rtoReportColumns({showTrackingProgress, isMobile})}
          sortedColumn={rtoReportData.sortedColumn}
          handleSort={handleSort}
          sortDirection={rtoReportData.sortDirection}
          showPagination
          totalCount={rtoReportData?.rtoTrackingData?.rto_data?.length || 0}
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          fixedColumn={isMobile ? fixedColumn: undefined}
        />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={showOrderDate ? rtoReportOrderedAtStartDateFilter: rtoReportDeliveredAtStartDateFilter}
        endDate={showOrderDate ? rtoReportOrderedAtEndDateFilter: rtoReportDeliveredAtEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={
          isMobile ? 
          <NamedToggle
            trueLabel={'RTO delivered'} 
            falseLabel={'Order date'}
            onChange={updateDisplayDate}
            value={!showOrderDate}
          /> : 'Select Date Range'
        }
        onOpen={() => {setShowDateRangeFilter(true)}}
      />

      <MultiStepProgressDrawer
        steps={trackingProgress}
        open={showProgressDrawer}
        onOpen={() => setShowProgressDrawer(true)}
        onClose={() => setShowProgressDrawer(false)}
      />
    </Box>
  );
};
  
export default RtoReport;
