import { ShippingData } from "../../slices/orderTrackingSlice";
import {ColumnPropType, FixedColumnPropType} from "../../components/organisms/TableManager/TableManager";
import styles from "./OrderTracking.module.scss";
import ProductThumbnail, { ProductThumbnailLite } from "../../components/atoms/ProductThumbnail/ProductThumbnail";
import MultiStepProgressPopover from "../../components/molecules/MultiStepPopover/MultiStepProgressPopover";
import { Typography } from "@mui/material";
import { lakhFormat } from "helpers/formatUtils";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type OrderTrackingColumnsPropType = {
  statusFilter: string;
  isMobile: boolean;
  showTrackingProgress: (steps: {label: string, value: string}[]) => void;
}

export const fixedColumn:FixedColumnPropType<ShippingData> = {
  label: 'Product / SKU',
  accessor: 'product_variant_sku_code',
  width: 120,
  maxWidth: 120,
  thresold: (290 - 120), // calculated based on first colum of table
  cell: ({row}) => <ProductThumbnailLite 
    sku={row.product_variant_sku_code || ''}
    src={row.product_image_src}
    fbv_enabled={row.fbv_enabled}
    fbv_warehouse={row.fbv_warehouse}
  />
}

export const orderTrackingColumns = (props: OrderTrackingColumnsPropType):ColumnPropType<ShippingData>[] => [
  {
    label: 'Product / SKU',
    accessor: 'product_variant_sku_code',
    width: 290,
    minWidth: 290,
    cell: ({row}) => <ProductThumbnail 
      sku={row.product_variant_sku_code || ''}
      title={row.product_title}
      src={row.product_image_src}
      fbv_enabled={row.fbv_enabled}
      fbv_warehouse={row.fbv_warehouse}
    />
  },
  {
    label: 'Shipment',
    accessor: 'delayed_dispatch',
    minWidth: 120,
    cell: ({value}) => {
      return <>
        {props.statusFilter !== 'Cancelled' && !value && (
          <span className={styles.ontimePill}>
            OnTime
          </span>
        )}
        {props.statusFilter !== 'Cancelled' && value && (
          <span className={styles.delayedPill}>
            Delayed
          </span>
        )}
      </>
    }
  },
  {
    label: 'Order Date',
    accessor: 'order_created_at',
    minWidth: 150,
  },
  {
    label: 'Order No',
    accessor: 'order_number',
    minWidth: 125,
    sortable: true,
  },
  {
    label: 'Qty',
    accessor: 'shipping_order_item_quantity',
    minWidth: 50,
    sortable: true,
  },
  {
    label: 'Value',
    accessor: 'product_variant_price',
    minWidth: 125,
    sortable: true,
    cell: ({value}) => {
      return <>
        {lakhFormat(value)}
      </>
    }
  },
  {
    label: 'Payment',
    accessor: 'order_payment_mode',
    minWidth: 125,
  },
  {
    label: 'Logistic Partner',
    accessor: 'unique_key',
    minWidth: 125,
    cell: ({row}) => {
      return <>
        {row.shipping_package_data.shipping_provider}
      </>
    },
  },
  {
    label: 'Order Status',
    accessor: 'unique_key',
    minWidth: 200,
    cell: ({row}) => {
      const tracking_number = row.return_attr ? row.return_attr.pickup_tracking_number : row.shipping_package_data.tracking_number;
      const status = row.return_attr?.delivered_at ? 'Returned' : row.shipping_package_data.courier_status;
      return (
        <div className={styles.orderAWBDetails}>
          <div className={styles.awbStatus}>
            <div>{status}</div>
            <div className={styles.awbNumber}>{'AWB: ' + tracking_number}</div>
          </div>
          {props.isMobile ? (
           <InfoOutlinedIcon fontSize="small" className={styles.orderStatus} onClick={() => {
            props.showTrackingProgress([
              {label: 'Order Placed:', value: row.order_created_at || ''},
              {label: 'Order Dispatched:', value: row.shipping_package_data.dispatched_at || ''},
              {label: 'Order Delivered:', value: row.shipping_package_data.delivered_at || ''},
              ...(row.return_attr?.delivered_at ? [
                {label: 'Returned:', value: row.return_attr.delivered_at || ''}
              ] : [])
            ]);
          }} />
          ): (
            <MultiStepProgressPopover
              steps={[
                {label: 'Order Placed:', value: row.order_created_at || ''},
                {label: 'Order Dispatched:', value: row.shipping_package_data.dispatched_at || ''},
                {label: 'Order Delivered:', value: row.shipping_package_data.delivered_at || ''},
                ...(row.return_attr?.delivered_at ? [
                  {label: 'Returned:', value: row.return_attr.delivered_at || ''}
                ] : [])
              ]}
            >
              <InfoOutlinedIcon fontSize="small" className={styles.orderStatus} />
            </MultiStepProgressPopover>
          )}
        </div>
      );
    }
  },
]