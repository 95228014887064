import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {Box,  Button,  Fab, Tab, Tabs, useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './ReturnExchangeReport.module.scss';
import { setSort, exchangesTrackingApi, ExchangeDataHash, exchangeTrackingDownloadReportApi } from "../../slices/exchangesTrackingSlice";
import FbvIcon from '../../images/fbv-icon.png';
import Loader from "components/atoms/Loader/Loader";
import { 
  setExchangesReportReceivedAtStartDateFilter,
  setExchangesReportReceivedAtEndDateFilter,
  setExchangesReportOrderNumberFilter,
  setExchangesReportSKUFilter,
  setExchangesReportReasonsFilter,
  setExchangesReportTrackingNumberFilter,
  setExchangesReportFbvEnabledFilter,
  setExchangesReportExchangedAtStartDateFilter,
  setExchangesReportExchangedAtEndDateFilter,
  setExchangesReportFbvWarehouseFilter,
  resetFilter,
} from "../../slices/filtersSlice";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { resetAuth } from "../../slices/authSlice";
import { exchangessReportColumns, fixedColumn } from "./ExchangesReport.constant";
import TableManager from "../../components/organisms/TableManager/TableManager";
import { downloadFile } from "helpers/utils";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import NamedToggle from "components/atoms/NamedToggle/NamedToggle";
import DateRangeSelector, { DateRangeLabel } from "components/molecules/DateRangeSelector/DateRangeSelector";
import { ROUTES, DateRangeType, ROUTE_REPORT_MAP, DEFAULT_BLACK_COLOR} from "../../constants";
import { useNavigate } from "react-router-dom";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "components/molecules/SearchByFilter/SearchByFilter";
import MultiSelectFilter from "components/molecules/MultiSelectFilter/MultiSelectFilter";
import CheckboxFilter from "components/atoms/CheckboxFilter/CheckboxFilter";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ColorButton from "components/atoms/ColorButton/ColorButton";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SwipeableFilters from "components/organisms/SwipeableFilter/SwipeableFilter";
import MultiStepProgressDrawer from "components/molecules/MultiStepProgressDrawer/MultiStepProgressDrawer";

const reasons = [
  'Changed my mind',
  'Received damaged products',
  'Received wrong or incorrect products',
  'Product not same as image on website',
  'Quality issues with product',
];

const PAGE_TITLE = 'Exchanges Report'

const ExchangesReport = () => {

  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const exchangesReportData = useAppSelector((state) => state.exchangesReport);
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);

  const handleSort = (column: keyof ExchangeDataHash) => {
    const direction = column === exchangesReportData.sortedColumn && exchangesReportData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };

  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT, 
      {
        page_link: window.location.href, 
        page_title: PAGE_TITLE
      }
    );
  }, []);

  const exchangesReportExchangedAtStartDateFilter = useAppSelector((state: RootState) => state.filters.exchangesReportExchangedAtStartDateFilter);
  const exchangesReportExchangedAtEndDateFilter = useAppSelector((state: RootState) => state.filters.exchangesReportExchangedAtEndDateFilter);
  const exchangesReportReceivedAtStartDateFilter = useAppSelector((state: RootState) => state.filters.exchangesReportReceivedAtStartDateFilter);
  const exchangesReportReceivedAtEndDateFilter = useAppSelector((state: RootState) => state.filters.exchangesReportReceivedAtEndDateFilter);
  const exchangesReportManufactureErrorFilter = useAppSelector((state: RootState) => state.filters.exchangesReportManufactureErrorFilter);
  const exchangesReportOrderNumberFilter = useAppSelector((state: RootState) => state.filters.exchangesReportOrderNumberFilter);
  const exchangesReportSKUFilter = useAppSelector((state: RootState) => state.filters.exchangesReportSKUFilter);
  const exchangesReportReasonsFilter = useAppSelector((state: RootState) => state.filters.exchangesReportReasonsFilter);
  const exchangesReportTrackingNumberFilter = useAppSelector((state: RootState) => state.filters.exchangesReportTrackingNumberFilter);
  const exchangesReportFbvEnabledFilter = useAppSelector((state: RootState) => state.filters.exchangesReportFbvEnabledFilter);  
  const exchangesReportFbvWarehouseFilter = useAppSelector((state: RootState) => state.filters.exchangesReportFbvWarehouseFilter)
  
  const warehouseOptions = useAppSelector((state) => (state.filters.fbvWarehouseFilter || []).map((c) => ({ label: c.name, value: c.name })));
  
  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(exchangesTrackingApi({
        startDateFilter: exchangesReportExchangedAtStartDateFilter,
        endDateFilter: exchangesReportExchangedAtEndDateFilter,
        exchangesReportReceivedAtStartDateFilter,
        exchangesReportReceivedAtEndDateFilter,
        exchangesReportManufactureErrorFilter,
        exchangesReportOrderNumberFilter,
        exchangesReportSKUFilter,
        exchangesReportReasonsFilter,
        exchangesReportTrackingNumberFilter,
        exchangesReportFbvEnabledFilter,
        exchangesReportFbvWarehouseFilter,
        headers,
      })).unwrap();
      setPage(0);
      setFilterApplied(false);
      setLoaderActive(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById('download-button');
    if (button) {
      if(!fab) button.innerHTML = 'Downloading...';
      const userInfo = localStorage.getItem('userInfo');
      if(userInfo) {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        let downloadApi: () => Promise<any>  = () => dispatch(exchangeTrackingDownloadReportApi({
          startDateFilter: exchangesReportExchangedAtStartDateFilter,
          endDateFilter: exchangesReportExchangedAtEndDateFilter,
          exchangesReportReceivedAtStartDateFilter,
          exchangesReportReceivedAtEndDateFilter,
          exchangesReportOrderNumberFilter,
          exchangesReportSKUFilter,
          exchangesReportReasonsFilter,
          exchangesReportTrackingNumberFilter,
          exchangesReportFbvEnabledFilter,
          exchangesReportFbvWarehouseFilter,
          headers,
        }));
        const success = await downloadFile({downloadApi, fileName: 'report.csv', fileType: 'text/csv'})
        if(success){
          triggerMixpanelEvent(
            MIXPANEL_EVENT_TYPES.DOWNLOAD,
            {
              report_name: PAGE_TITLE,
              download_type: 'Report'
            }
          );
        }
      }else{
        dispatch(resetAuth());
        dispatch(resetFilter());
      }
      if(!fab) button.innerHTML = 'Download';
    }
  };

  const trackFilterEvent = async () => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_INTERACTION,
      {
        report_name: PAGE_TITLE,
        filter: [
          (exchangesReportExchangedAtStartDateFilter ? 'Exchange Approved Date Range' : 'Exchange Deliverd Date Range'),
          ...appliedSearchFilters.map(f => f.label)
        ]
      }
    )
  }

  useEffect(() => {
    if(filterApplied){
      trackFilterEvent();
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const clearFilters = () => {
    dispatch(resetFilter());
    setFilterApplied(true);
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

  const [showExchangeDate, setShowExchangeDate] = useState(true);
  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);

  useEffect(() => {
    if(!!exchangesReportExchangedAtStartDateFilter){
      setShowExchangeDate(true);
    }else{
      setShowExchangeDate(false);      
    }
  }, []);

  const updateDisplayDate = () => {
    if(showExchangeDate){
      setShowExchangeDate(false);
      dispatch(setExchangesReportExchangedAtStartDateFilter(undefined));
      dispatch(setExchangesReportExchangedAtEndDateFilter(undefined));
      dispatch(setExchangesReportReceivedAtStartDateFilter(exchangesReportExchangedAtStartDateFilter));
      dispatch(setExchangesReportReceivedAtEndDateFilter(exchangesReportExchangedAtEndDateFilter));
    }else{
      setShowExchangeDate(true);
      dispatch(setExchangesReportExchangedAtStartDateFilter(exchangesReportReceivedAtStartDateFilter));
      dispatch(setExchangesReportExchangedAtEndDateFilter(exchangesReportReceivedAtEndDateFilter));
      dispatch(setExchangesReportReceivedAtStartDateFilter(undefined));
      dispatch(setExchangesReportReceivedAtEndDateFilter(undefined));
    }
    setFilterApplied(true);
  }

  const searchByOptions = [
    {id: 'sku', label: 'SKU'},
    {id: 'orderNumber', label: 'Order Number'},
    {id: 'awbNumber', label: 'AWB No'},
  ]

  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string[]}) => {
    switch(id){
      case 'sku': {
        dispatch(setExchangesReportSKUFilter(value as string)); break;
      }
      case 'orderNumber': {
        dispatch(setExchangesReportOrderNumberFilter(value as string)); break;
      }
      case 'awbNumber': {
        dispatch(setExchangesReportTrackingNumberFilter(value as string)); break;
      }
      case 'reasons': {
        dispatch(setExchangesReportReasonsFilter(value as string [])); break;
      }
      case 'fbvEnabled': {
        dispatch(setExchangesReportFbvEnabledFilter(value as boolean)); break;
      }
      case 'fbvWarehouse' :{
        dispatch(setExchangesReportFbvWarehouseFilter(value as string[])); break;
      }
    }
    setFilterApplied(true);
  }

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSwipeableDrawerFilters = (idValueMap: Record<string, (string | boolean| string[])>) => {
    for(const [key, value] of Object.entries(idValueMap)){
      switch(key){
        case 'fbvEnabled' : {
          dispatch(setExchangesReportFbvEnabledFilter(value as boolean)); break;
        }
        case 'reasons' : {
          dispatch(setExchangesReportReasonsFilter(value as string[])); break;
        }
        case 'fbvWarehouse' :{
          dispatch(setExchangesReportFbvWarehouseFilter(value as string[])); break;
        }
      }
    }
    setFilterApplied(true);
  }

  const onSearchByClear = (id: string) => {
    if(id === 'reasons')
      handleFilterValueChange({id, value: []});
    else if(id === 'fbvEnabled')
      handleFilterValueChange({id, value: false});
    else if(id === 'fbvWarehouse')
      handleFilterValueChange({id, value: []});
    else
      handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setExchangesReportSKUFilter(''));
    dispatch(setExchangesReportOrderNumberFilter(''));
    dispatch(setExchangesReportTrackingNumberFilter(''));
    dispatch(setExchangesReportReasonsFilter([]));
    dispatch(setExchangesReportFbvEnabledFilter(false));
    dispatch(setExchangesReportFbvWarehouseFilter([]));
    setFilterApplied(true);
  }

  const reasonOptions = reasons.map((r => ({label: r, value: r})));

  const appliedSearchFilters: AppliedFilterType[] = [
    ...(!!exchangesReportSKUFilter ? [{id: 'sku', label: 'SKU', value: exchangesReportSKUFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!exchangesReportOrderNumberFilter ? [{id: 'orderNumber', label: 'Order Number', value: exchangesReportOrderNumberFilter, type: 'search'}] : [])  as AppliedFilterType[],
    ...(!!exchangesReportTrackingNumberFilter ? [{id: 'awbNumber', label: 'AWB No', value: exchangesReportTrackingNumberFilter, type: 'search'}] : [])  as AppliedFilterType[],
    ...(!!exchangesReportReasonsFilter.length ? [{id: 'reasons', label: 'Reasons', value: exchangesReportReasonsFilter, type: 'multi-select', options: reasonOptions}] : []) as AppliedFilterType[],
    ...(!!exchangesReportFbvEnabledFilter ? [{id: 'fbvEnabled', label: 'FBV', value: exchangesReportFbvEnabledFilter, type: 'checkbox'}] : []) as AppliedFilterType[],
    ...(!!exchangesReportFbvWarehouseFilter.length ? [{id: 'fbvWarehouse', label: 'FBV Warehouse', value: exchangesReportFbvWarehouseFilter, type: 'multi-select', options: warehouseOptions}] : []) as AppliedFilterType[],
  ];

  const [showProgressDrawer, setShowProgressDrawer] = React.useState(false);
  const [trackingProgress, setTrackingProgress] = React.useState<{label: string, value: string}[]>([]);

  const showTrackingProgress = (steps: {label: string, value: string}[]) => {
    setTrackingProgress(steps);
    setShowProgressDrawer(true);
  }

  const handleStartEndDateSelect = (ranges: DateRangeType) => {
    if(showExchangeDate){
      dispatch(setExchangesReportExchangedAtStartDateFilter(ranges.startDate as Date));
      dispatch(setExchangesReportExchangedAtEndDateFilter(ranges.endDate as Date));
    }else{
      dispatch(setExchangesReportReceivedAtStartDateFilter(ranges.startDate as Date));
      dispatch(setExchangesReportReceivedAtEndDateFilter(ranges.endDate as Date));
    }
    setFilterApplied(true);
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="Returns & Exchange Report">
        {!isMobile && (
          <NamedToggle 
            trueLabel={'Exchange delivered'} 
            falseLabel={'Exchange date'}
            onChange={updateDisplayDate}
            value={!showExchangeDate}
          />
        )}
        <DateRangeLabel
          startDate={showExchangeDate ? exchangesReportExchangedAtStartDateFilter : exchangesReportReceivedAtStartDateFilter}
          endDate={showExchangeDate ? exchangesReportExchangedAtEndDateFilter : exchangesReportReceivedAtEndDateFilter}
          onClick={() => {setShowDateRangeFilter(true)}}
          of={isMobile ? (showExchangeDate ? 'Exchange Date' : 'Exchange Delivered'): undefined}
        />
      </MainHeader>

      <Box className={styles.pageTabsWrapper}>
        <Tabs
          value={window.location.pathname}
          onChange={() => {
            triggerMixpanelEvent(
              MIXPANEL_EVENT_TYPES.REPORT_VIEWED, 
              {
                report_name: ROUTE_REPORT_MAP[ROUTES.RETURNS],
                source: 'Navigation'
              }
            );
            navigate(ROUTES.RETURNS);
          }}
          TabIndicatorProps={{
            sx: {
              backgroundColor: '#4F76FE',
            }
          }}
        >
          <Tab label="Returns" value={ROUTES.RETURNS} style={{ textTransform: 'none', fontWeight:400, color: window.location.pathname === ROUTES.RETURNS ? '#3361FF' : DEFAULT_BLACK_COLOR}}/>
          <Tab label="Exchanges" value={ROUTES.EXCHANGES} style={{ textTransform: 'none', fontWeight: 400, color: window.location.pathname === ROUTES.EXCHANGES ? '#3361FF' : DEFAULT_BLACK_COLOR}}/>
        </Tabs>
      </Box>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />

          {!isMobile? (
            <>
              <MultiSelectFilter 
                label={'Reasons'}
                values={exchangesReportReasonsFilter}
                options={reasonOptions}
                onSubmit={(values) => {
                  dispatch(setExchangesReportReasonsFilter(values));
                  setFilterApplied(true);
                }}
              />
              {fbv && <MultiSelectFilter
                  label={'FBV Warehouse'}
                  values={exchangesReportFbvWarehouseFilter}
                  options={warehouseOptions}
                  allowAll={true}
                  onSubmit={(val) => {
                    dispatch(setExchangesReportFbvWarehouseFilter(val));
                    setFilterApplied(true);
                  }}
                />
              }
            </>
          ): (
            <Fab variant="extended" size="small" className={styles.filterFAB} onClick={() => setFilterOpen(true)}>
              <FilterAltOutlinedIcon fontSize="small" />
              Filter
            </Fab>
          )}
        </Box>
        {!isMobile ? (
          <Button
            id="download-button"
            className={styles.downloadBtn}
            onClick={() => downloadReport()}
          >
            Download
          </Button>
        ):(
          <Fab className={styles.downloadFAB} onClick={() => downloadReport(true)} size='medium' id="download-button">
            <FileDownloadOutlinedIcon fontSize='small' />
          </Fab>
        )}
      </Box>
        <SwipeableFilters
          open={filterOpen}
          onOpen={() => setFilterOpen(true)}
          onClose={() => setFilterOpen(false)}
          onAction={handleSwipeableDrawerFilters}
          multiSelectFilters={[
            ...(fbv ? [
              {
                id: 'fbvWarehouse',
                label: 'FBV Warehouse',
                type: 'multiSelect' as 'multiSelect',
                value: exchangesReportFbvWarehouseFilter,
                options: warehouseOptions,
              }
            ] : []),
            {
              id: 'reasons',
              label: 'Reasons',
              type: 'multiSelect',
              value: exchangesReportReasonsFilter,
              options: reasonOptions,
            },
          ]}
      />
      {appliedSearchFilters.length ? (
        <SearchSelections
          appliedFilters={appliedSearchFilters} 
          allClear={onSearchByAllClear} 
          onClear={onSearchByClear}
        />
      ): null}

      <Loader show={loaderActive} />

      {!loaderActive && (
        <TableManager<ExchangeDataHash>
          data={exchangesReportData?.exchangesTrackingData?.exchanges_data || []}
          columns={exchangessReportColumns({showTrackingProgress, isMobile})}
          sortedColumn={exchangesReportData.sortedColumn}
          handleSort={handleSort}
          sortDirection={exchangesReportData.sortDirection}
          showPagination
          totalCount={exchangesReportData?.exchangesTrackingData?.exchanges_data?.length || 0}
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          fixedColumn={isMobile ? fixedColumn: undefined}
        />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={showExchangeDate ? exchangesReportExchangedAtStartDateFilter: exchangesReportReceivedAtStartDateFilter}
        endDate={showExchangeDate ? exchangesReportExchangedAtEndDateFilter: exchangesReportReceivedAtEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={
          isMobile ? 
          <NamedToggle
            trueLabel={'Exchange delivered'} 
            falseLabel={'Exchange date'}
            onChange={updateDisplayDate}
            value={!showExchangeDate}
          /> : 'Select Date Range'
        }
        onOpen={() => {setShowDateRangeFilter(true)}}
      />

      <MultiStepProgressDrawer
        steps={trackingProgress}
        open={showProgressDrawer}
        onOpen={() => setShowProgressDrawer(true)}
        onClose={() => setShowProgressDrawer(false)}
      />
    </Box>
  );
};
  
export default ExchangesReport;
