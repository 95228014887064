import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

export type SalesReportDataHash = {
  product_variant_id: number;
  product_title: string;
  product_variant_title: string;
  product_image: string;
  product_variant_sku_code: string;
  l1_category: string;
  product_inventory: number;
  sold_quantity: number;
  sold_amount: number;
  product_holding_days: number;
  unique_key: string;
  fbv_enabled: boolean;
  fbv_warehouse: string;
}

type SalesReportData = {
  sales_report_data: SalesReportDataHash[];
  l1_categories: string[]
}

type SalesReportApiState = {
  salesReportData?: SalesReportData | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof SalesReportDataHash | null;
  salesReportStatus: "idle" | "loading" | "failed";
  salesReportError: string | null;
};

const initialState: SalesReportApiState = {
  salesReportData: null,
  salesReportStatus: "loading",
  salesReportError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};

export const salesReportApi = createAsyncThunk(
  "salesReportApi",
  async ({startDate, endDate, category, productId, productTitle, fbvEnabled, fbvWarehouse, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      let url = `/forge/sales/sales_report?from_date=${startDate}&to_date=${endDate}&category=${encodeURIComponent(category)}&product_id=${productId}&product_title=${encodeURIComponent(productTitle)}&fbv_enabled=${!!fbvWarehouse.length}`;
      fbvWarehouse.forEach((warehouseName: string) => {
        url += `&fbv_warehouse[]=${warehouseName}`;
      });
      const response = await axiosInstance.get(url, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const salesReportDownloadReportApi = createAsyncThunk(
  "salesReportDownloadReportApi",
  async ({startDate, endDate, category, productId, productTitle, fbvEnabled, fbvWarehouse, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      let url = `/forge/sales/sales_report.csv?from_date=${startDate}&to_date=${endDate}&category=${encodeURIComponent(category)}&product_id=${productId}&product_title=${encodeURIComponent(productTitle)}&fbv_enabled=${!!fbvWarehouse.length}`;
      fbvWarehouse.forEach((warehouseName: string) => {
        url += `&fbv_warehouse[]=${warehouseName}`;
      });
      const response = await axiosInstance.get(url, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

const salesReportSlice = createSlice({
  name: 'salesReport',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof SalesReportDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.salesReportData && column){
        const sorted = sortRecords(state.salesReportData.sales_report_data, column, direction);
        state.salesReportData.sales_report_data = sorted as SalesReportDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(salesReportApi.pending, (state) => {
        state.salesReportStatus = "loading";
        state.salesReportError = null;
      })
      .addCase(
        salesReportApi.fulfilled,
        (state, action: PayloadAction<SalesReportData>) => {
          state.salesReportStatus = "idle";
          state.salesReportData = action.payload;
        }
      )
      .addCase(salesReportApi.rejected, (state, action) => {
        state.salesReportStatus = "failed";
        if (action.payload) {
          state.salesReportError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.salesReportError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = salesReportSlice.actions;
export default salesReportSlice.reducer;
