import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

export type RequestDataHash = {
  id: string | number;
  unique_key: string | number;
  request_type: string;
  status: string;
  created_at: string;
  updated_at: string;
  total_count: number | null;
  success_count: number | null;
  error_count: number | null;
  processed_file_present: boolean;
}

type RequestsData = RequestDataHash[];

type RequestsTrackingApiState = {
  requestsData?: RequestsData | null;
  requestsTrackingStatus: "idle" | "loading" | "failed";
  requestsTrackingError: string | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof RequestDataHash | null;
};

const initialState: RequestsTrackingApiState = {
  requestsData: null,
  requestsTrackingStatus: "loading",
  requestsTrackingError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};

export const requestsTrackingApi = createAsyncThunk(
  "requestsTrackingApi",
  async ({headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get("/forge/requests/", config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const downloadRequestProcessedReport = createAsyncThunk(
  "downloadRequestProcessedReport",
  async ({requestId, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get(`/forge/requests/${requestId}/download_processed_file`, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);


const requestsTrackingSlice = createSlice({
  name: 'requestsTracking',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof RequestDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      // Sorting logic
      if(state.requestsData && column){
        const sorted = sortRecords(state.requestsData, column, direction);
        state.requestsData = sorted as RequestDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestsTrackingApi.pending, (state) => {
        state.requestsTrackingStatus = "loading";
        state.requestsTrackingError = null;
      })
      .addCase(
        requestsTrackingApi.fulfilled,
        (state, action: PayloadAction<RequestsData>) => {
          state.requestsTrackingStatus = "idle";
          state.requestsData = action.payload;
        }
      )
      .addCase(requestsTrackingApi.rejected, (state, action) => {
        state.requestsTrackingStatus = "failed";
        if (action.payload) {
          state.requestsTrackingError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.requestsTrackingError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = requestsTrackingSlice.actions;
export default requestsTrackingSlice.reducer;
