import { Box, useMediaQuery, useTheme, } from "@mui/material";
import styles from "./Navigation.module.scss";
import SideBar from "../../molecules/Sidebar/SideBar";
import MobileNavigator from "../../molecules/MobileNavigator/MobileNavigator";
import { ROUTES } from "../../../constants";
import { useLocation } from "react-router-dom";
import { csx } from "helpers/utils";
import PolicyModal from "components/atoms/PolicyModal/PolicyModal";
import { useEffect } from 'react';
import { warehouseListApi } from "../../../slices/filtersSlice";
import { useAppDispatch } from "../../../hooks/redux-hooks";


type NavigationPropType = {
  children: JSX.Element;
}


const Navigation: React.FC<NavigationPropType> = ({children: outlet}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const showFullHeight = location.pathname.startsWith(ROUTES.ISSUE_TRACKING) || location.pathname.startsWith(ROUTES.REQUEST_TRACKING) || location.pathname.startsWith(ROUTES.MRP_LABEL);
  
  useEffect(()=>{
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      dispatch(warehouseListApi({headers}))
    }
  }, [])

  return (
    <Box className={csx(styles.navWrapper, showFullHeight ? styles.fullHeight : '')}>
      {isMobile ? (
        <Box className={styles.bottomNavSection}>
          <MobileNavigator />
        </Box>
      ) : (
        <Box className={styles.sideBarSection}>
          <SideBar />
        </Box>
      )}
      <Box className={styles.layoutSection}>
        {outlet}
      </Box>
      <PolicyModal />
    </Box>
  )
}

export default Navigation;