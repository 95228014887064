import { createTheme } from "@mui/material";
import { DEFAULT_BLACK_COLOR } from "../constants";

const appTheme = createTheme({
  palette: {
    text: {
      primary: DEFAULT_BLACK_COLOR,
      secondary: '#7A7A7A',
    }
  },
  typography: {
    fontFamily : "'Poppins', sans-serif",
  },
});

export default appTheme;