import { Box, SwipeableDrawer, useMediaQuery, useTheme } from "@mui/material";
import styles from "./MonthSelector.module.scss";
import { useState } from "react";
import ClickTooltip from "components/atoms/ClickTooltip/ClickTooltip";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ColorButton from "components/atoms/ColorButton/ColorButton";
import { csx } from "helpers/utils";

export type SelectedMonth = { year: number, month: number };

type PropType = MonthCalanderPropType & {
  children: JSX.Element;
  showCalendar: boolean;
  setShowCalendar: (show: boolean) => void;
}

const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December",
];

const MonthSelector: React.FC<PropType> = (props) => {
  const { children, showCalendar, setShowCalendar, ...calendarProps } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    open ? setShowCalendar(true) : setShowCalendar(false);
  };

  if (isMobile) {
    return (
      <>
        {children}
        <SwipeableDrawer
          anchor={"bottom"}
          open={showCalendar}
          onClose={handleDrawer(false)}
          onOpen={handleDrawer(true)}
        >
          <Box>
            <MonthCalander {...calendarProps} />
          </Box>
        </SwipeableDrawer>
      </>
    );
  }

  return (
    <Box>
      <ClickTooltip
        className={styles['popover-style']}
        show={showCalendar}
        showPopper={setShowCalendar}
        placement="bottom-start"
        title={<MonthCalander {...calendarProps} />}
      >
        {children}
      </ClickTooltip>
    </Box>
  );
}

export default MonthSelector;

type MonthCalanderPropType = {
  disabledMonths: (month: number, year: number) => boolean;
  onClick: (month: number, year: number) => void;
  selectedMonths: SelectedMonth[]
  onSubmit: () => void;
  onClear: () => void;
};

const MonthCalander: React.FC<MonthCalanderPropType> = (props) => {
  const { disabledMonths, onClick, selectedMonths, onClear, onSubmit } = props;
  const [currYear, setCurrYear] = useState(new Date().getFullYear());

  return (
    <Box className={styles.calendarWrapper}>
      <Box className={styles.yearSelection}>
        <KeyboardArrowLeftIcon onClick={() => { setCurrYear((prev) => prev - 1) }} />
        <p className={styles.currYear}>{currYear}</p>
        <KeyboardArrowRightIcon onClick={() => { setCurrYear((prev) => prev + 1) }} />
      </Box>
      <Box className={styles.monthSelection}>
        {months.map((month, idx) => {
          const isSelected = selectedMonths.find((sm) => sm.month === (idx + 1) && sm.year === currYear)
          const isDisabled = disabledMonths(idx + 1, currYear);
          return (
            <ColorButton
              key={`${month}-${currYear}`}
              bgColor="#FFF"
              variant="outlined"
              onClick={() => onClick(idx + 1, currYear)}
              className={csx(styles.monthBtn, isSelected ? styles.selected : undefined)}
              disabled={isDisabled}>
              {month}
            </ColorButton>
          )
        })}
      </Box>
      <Box className={styles['picker-footer']}>
        <ColorButton bgColor="#FFF" className={styles['clear-button']} variant="outlined" onClick={onClear}>
          Clear All
        </ColorButton>
        <ColorButton bgColor="#3361FF" bgHoverColor="#2E58E8" className={styles['submit-button']} onClick={onSubmit} disabled={!selectedMonths.length}>
          Submit
        </ColorButton>
      </Box>
    </Box>
  )
}