import {ColumnPropType, FixedColumnPropType} from "../../components/organisms/TableManager/TableManager";
import ProductThumbnail, { ProductThumbnailLite } from "../../components/atoms/ProductThumbnail/ProductThumbnail";
import { formatCurrency } from "helpers/formatUtils";
import { FbvStorageChargesDataHash } from "slices/fbvStockChargesReportSlice";
import styles from "./FbvStockChargesReport.module.scss";
import { Box, ClickAwayListener, selectClasses } from "@mui/material";
import { useState } from "react";
import LightTooltip from "components/atoms/LightTooltip/LightTooltip";
import MonthlyDataTable from "components/molecules/MonthlyDataTable/MontlyDataTable";

type ClickNetQuantityTooltipPropType = {
  dateValueHash: Record<string, (number)>;
  month: number;
  value: number;
}

const ClickNetQuantityTooltip:React.FC<ClickNetQuantityTooltipPropType> = (props) => {
  const {dateValueHash, month, value,} = props;

  const [showDetailsTooltip, setShowDetailsTooltip] = useState<boolean>(false);

  return (
    <div className={styles.popupWrapper}>
      <ClickAwayListener onClickAway={() => setShowDetailsTooltip(false)}>
        <div>
          <LightTooltip
            PopperProps={{
              disablePortal: true,
            }}
            placement="bottom"
            arrow
            onClose={() => {setShowDetailsTooltip(false)}}
            open={showDetailsTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <MonthlyDataTable
                month={month}
                dateValueHash={dateValueHash}
              />
            }
          >
            <p className={styles.value}
              onClick={() => {setShowDetailsTooltip(true)}}>
              {value}
            </p>
          </LightTooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
}

export const fixedColumn:FixedColumnPropType<FbvStorageChargesDataHash> = {
  label: 'Product / SKU',
  accessor: 'sku',
  width: 120,
  maxWidth: 120,
  thresold: (290 - 120), // calculated based on first colum of table
  cell: ({row}) => <ProductThumbnailLite
    sku={row.sku || ''}
    src={row.img}
    fbv_enabled={true}
    fbv_warehouse={row.fbv_warehouse}
  />
}

export const fbvStockChargesReportColumns = (selectedMonth :number):ColumnPropType<FbvStorageChargesDataHash>[] => [
  {
    label: 'Product / SKU',
    accessor: 'sku',
    width: 290,
    minWidth: 290,
    cell: ({row}) => <ProductThumbnail 
      sku={row.sku || ''}
      title={row.name}
      src={row.img}
      fbv_enabled={true}
      fbv_warehouse={row.fbv_warehouse}
    />
  },
  {
    label: 'Chargeable Count(Qty)',
    accessor: 'net_quantity',
    minWidth: 180,
    align: 'center',
    cell: ({row, value}) => {
      return (
        <ClickNetQuantityTooltip 
          value={value}
          dateValueHash={row.date_quantity_map}
          month={selectedMonth}
        />
      );
    }
  },
  {
    label: 'Net Sales',
    accessor: 'net_sale_quantity',
    minWidth: 150,
    align: 'center',
    sortable: true,
  },
  {
    label: 'Stock on Hand',
    accessor: 'soh',
    minWidth: 180,
    align: 'center',
    sortable: true,
  },
  {
    label: 'Pick Pack Charges',
    accessor: 'pickpack_charges',
    minWidth: 150,
    align: 'center',
    sortable: true,
    cell: ({value}) => formatCurrency(value)
  },
  {
    label: <p>Volumetric Size (ft<sup>3</sup>) </p>,
    accessor: 'volumetric_size',
    minWidth: 180,
    align: 'center',
    sortable: true,
    cell: ({value}) => value.toFixed(5)
  },
  {
    label: 'Storage Charges',
    accessor: 'net_storage_charges',
    minWidth: 150,
    align: 'center',
    sortable: true,
    cell: ({value}) => formatCurrency(value)
  },
  {
    label: 'Total',
    accessor: 'total',
    minWidth: 150,
    align: 'center',
    sortable: true,
    cell: ({value}) => formatCurrency(value),
  },
]