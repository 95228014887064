import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

type ShippingPackageData = {
  shipping_provider: string;
  tracking_number: string;
  courier_status: string;
  created_at: string;
  dispatched_at: string;
  delivered_at: string;
}

type ReturnAttrHash = {
  created_at: string;
  pickup_tracking_number: string;
  delivered_at: string | null;
}

export type ShippingData = {
  unique_key: number;
  product_title: string;
  product_variant_sku_code: string;
  product_image_src: string;
  order_created_at: string;
  order_number: string;
  shipping_order_item_quantity: number;
  product_variant_price: number;
  order_payment_mode: string;
  delayed_dispatch: boolean;
  shipping_package_data: ShippingPackageData;
  fbv_enabled: boolean;
  fbv_warehouse: string;
  return_attr: null | ReturnAttrHash;
}

type OrderTrackingData = {
  shipping_data: ShippingData[];
}

type OrderTrackingApiState = {
  orderTrackingData?: OrderTrackingData | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof ShippingData | null;
  orderTrackingStatus: "idle" | "loading" | "failed";
  orderTrackingError: string | null;
};

const initialState: OrderTrackingApiState = {
  orderTrackingData: null,
  orderTrackingStatus: "loading",
  orderTrackingError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};

export const orderTrackingApi = createAsyncThunk(
  "orderTrackingApi",
  async ({startDate, endDate, status, paymentType, productId, productName, orderNo, awbNo, delayedShipment, delayedDelivery, fbvEnabled, fbvWarehouse, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      let url = `/forge/orders/order_tracking_v2?q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}&order_status=${status}&q[order_payment_type_eq]=${paymentType}&q[product_variant_sku_eq]=${productId}&q[product_variant_product_title_cont]=${productName}&q[order_order_number_eq]=${orderNo}&q[shipping_packages_tracking_number_eq]=${awbNo}&delayed_shipment=${delayedShipment}&delayed_deliver=${delayedDelivery}&fbv_enabled=${!!fbvWarehouse.length}`;
      fbvWarehouse.forEach((warehouseName: string) => {
        url += `&q[vendor_location_warehouse_name_in][]=${warehouseName}`;
      });
      const response = await axiosInstance.get(url, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const orderTrackingDownloadReportApi = createAsyncThunk(
  "orderTrackingDownloadReportApi",
  async ({startDate, endDate, status, paymentType, productId, productName, orderNo, awbNo, delayedShipment, delayedDelivery, fbvEnabled, fbvWarehouse, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      let url = `/forge/orders/order_tracking_v2.csv?q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}&order_status=${status}&q[order_payment_type_eq]=${paymentType}&q[product_variant_sku_eq]=${productId}&q[product_variant_product_title_cont]=${productName}&q[order_order_number_eq]=${orderNo}&q[shipping_packages_tracking_number_eq]=${awbNo}&delayed_shipment=${delayedShipment}&delayed_deliver=${delayedDelivery}&fbv_enabled=${!!fbvWarehouse.length}`;
      fbvWarehouse.forEach((warehouseName: string) => {
        url += `&q[vendor_location_warehouse_name_in][]=${warehouseName}`;
      });
      const response = await axiosInstance.get(url, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

const orderTrackingSlice = createSlice({
  name: 'orderTracking',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof ShippingData | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.orderTrackingData && column){
        const sorted = sortRecords(state.orderTrackingData?.shipping_data || [], column, direction);
        state.orderTrackingData.shipping_data = sorted as ShippingData[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(orderTrackingApi.pending, (state) => {
        state.orderTrackingStatus = "loading";
        state.orderTrackingError = null;
      })
      .addCase(
        orderTrackingApi.fulfilled,
        (state, action: PayloadAction<OrderTrackingData>) => {
          state.orderTrackingStatus = "idle";
          state.orderTrackingData = action.payload;
        }
      )
      .addCase(orderTrackingApi.rejected, (state, action) => {
        state.orderTrackingStatus = "failed";
        if (action.payload) {
          state.orderTrackingError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.orderTrackingError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = orderTrackingSlice.actions;
export default orderTrackingSlice.reducer;
