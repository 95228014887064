import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

export type IssueDataHash = {
  id: number | string;
  unique_key: string | string;
  summary: string;
  created_at: string;
  resolved_at: string | null;
  status: string;
  reason: string;
  description: string;
  vendor_remark: string | null;
  image_urls: string[];
  sla_breached: boolean;
  vendor_satisfied: boolean | null;
}

type TrackIssueData = IssueDataHash[]

type TrackIssueDataApiState = {
  trackIssueData?: TrackIssueData | null;
  trackIssueDataStatus: "idle" | "loading" | "failed";
  trackIssueDataError: string | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof IssueDataHash | null;
};

const initialState: TrackIssueDataApiState = {
  trackIssueData: null,
  trackIssueDataStatus: "loading",
  trackIssueDataError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};


const formatRequest = (requestData: any, download:boolean = false): string => {
  let url = `/forge/issues/${download ? '.csv' : ''}?`;
  url += `q[created_at_gteq]=${requestData.issueTrackingIssueRaisedStartDateFilter}&`;
  url += `q[created_at_lteq]=${requestData.issueTrackingIssueRaisedEndDateFilter}&`;
  url += `q[resolved_at_gteq]=${requestData.issueTrackingIssueResolvedStartDateFilter}&`;
  url += `q[resolved_at_lteq]=${requestData.issueTrackingIssueResolvedEndDateFilter}&`;
  url += `q[summary_cont]=${requestData.issueTrackingTitleFilter}&`;
  url += `q[id_eq]=${requestData.issueTrackingTicketIdFilter}&`;
  url += `q[status_eq]=${requestData.issueTrackingStatusFilter}&`;
  for(let i = 0 ; i < requestData.issueTrackingStatusFilter.length ; i++){
    const status = requestData.issueTrackingStatusFilter[i];
    url += `q[status_in][]=${status}&`;
  }
  url += `delayed_issue=${requestData.issueTrackingDelayedFilter}`;
  return url;
}

export const trackIssueApi = createAsyncThunk(
  "trackIssueApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData);
    try {
      
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);


export const updateVendorSatisfactionApi = createAsyncThunk(
  "updateVendorSatisfactionApi",
  async ({headers, id, vendorSatisfied} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const payload = {vendor_satisfied: vendorSatisfied};
      const response = await axiosInstance.post(`/forge/issues/${id}/update_vendor_satisfaction_status`, payload, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

const trackIssueSlice = createSlice({
  name: 'trackIssue',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof IssueDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      // Sorting logic
      if(state.trackIssueData && column){
        const sorted = sortRecords(state.trackIssueData, column, direction);
        state.trackIssueData = sorted as IssueDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(trackIssueApi.pending, (state) => {
        state.trackIssueDataStatus = "loading";
        state.trackIssueDataError = null;
      })
      .addCase(
        trackIssueApi.fulfilled,
        (state, action: PayloadAction<TrackIssueData>) => {
          state.trackIssueDataStatus = "idle";
          state.trackIssueData = action.payload;
        }
      )
      .addCase(trackIssueApi.rejected, (state, action) => {
        state.trackIssueDataStatus = "failed";
        if (action.payload) {
          state.trackIssueDataError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.trackIssueDataError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = trackIssueSlice.actions;
export default trackIssueSlice.reducer;