import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";

type GetHelpFormPayload = {
  reason: string;
  summary: string;
  description: string;
  images: File[];
}

type CreateIssuePostState = {
  loading: boolean;
  error: string | null;
  data: any;
}

type ErrorResponse = {
  errors: string;
};

const initialState: CreateIssuePostState = {
  loading: false,
  error: null,
  data: null,
};

export const createIssueApi = createAsyncThunk(
  "createIssueApi",
  async (data: GetHelpFormPayload, { rejectWithValue }) => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      const token = JSON.parse(userInfo).token
      const email = JSON.parse(userInfo).email

      const formData = new FormData();
      formData.append('issue[reason]', data.reason);
      formData.append('issue[summary]', data.summary);
      formData.append('issue[description]', data.description);
      formData.append('issue[email]', email);
      data.images.forEach((image, index) => {
        formData.append(`images[${index}]`, image);
      });

      const headers = token ? { Authorization: `${token}` } : undefined;

      const config: AxiosRequestConfig = {
        headers: headers
      };

      try {
        const response = await axiosInstance.post('/forge/issues', formData, config);
        const resData = response.data;

        return resData;
      } catch (error) {
        if (error instanceof AxiosError && error.response) {
          const errorResponse = error.response.data;
  
          return rejectWithValue(errorResponse);
        }
      }
    } else {
      return rejectWithValue({errors: "Unauthorized! Login again"});
    }
  }
);


const createIssuePostSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createIssueApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createIssueApi.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(createIssueApi.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default createIssuePostSlice.reducer;
