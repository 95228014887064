import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { initMixpanelIdentifier, triggerMixpanelEvent, MIXPANEL_EVENT_TYPES } from "../hooks/mixpanel_hook";

type User = {
  email: string;
  password: string;
  portal: string;
};

type UserBasicInfo = {
  id: string;
  name: string;
  email: string;
  logo_url: string;
  token: string;
  fbv: boolean;
  gst_number: string | null;
  policy_accepted: boolean;
  partner_fulfillment_policy_url: string;
  user_type: string;
};

type ErrorResponse = {
  errors: string;
};

type AuthApiState = {
  basicUserInfo?: UserBasicInfo | null;
  status: "idle" | "loading" | "failed";
  error: string | null;
  acceptPolicyApiStatus: 'idle' | 'loading' | 'failed';
};

const initialState: AuthApiState = {
  basicUserInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") as string)
    : null,
  status: "idle",
  error: null,
  acceptPolicyApiStatus: 'idle',
};

export const login = createAsyncThunk(
  "login",
  async (data: User, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/forge/login', data);
      const resData = response.data;
      localStorage.setItem("userInfo", JSON.stringify(resData));
      initMixpanelIdentifier(resData.email)
      triggerMixpanelEvent(
        MIXPANEL_EVENT_TYPES.LOGIN,
        {email: resData.email, vendor_name: resData.name}
      );
      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

export const acceptPolicyApi = createAsyncThunk(
  "acceptPolicyApi",
  async ({headers}: any, { rejectWithValue }) => {
    try {
      const config: AxiosRequestConfig = {
        headers: headers || {},
      };
      const response = await axiosInstance.put('/forge/accept_policy', {}, config);
      const resData = response.data;
      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state) => {
      localStorage.removeItem("userInfo");
      triggerMixpanelEvent(
        MIXPANEL_EVENT_TYPES.LOGOUT,
        {email: state.basicUserInfo?.email, vendor_name: state.basicUserInfo?.name}
      );
      state.basicUserInfo = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        login.fulfilled,
        (state, action: PayloadAction<UserBasicInfo>) => {
          state.status = "idle";
          state.basicUserInfo = action.payload;
        }
      )
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error =
            (action.payload as ErrorResponse).errors || "Login failed";
        } else {
          state.error = action.error.message || "Login failed";
        }
      })
      .addCase(acceptPolicyApi.pending, (state) => {
        state.acceptPolicyApiStatus = "loading";
        state.error = null;
      })
      .addCase(acceptPolicyApi.fulfilled,
        (state, action) => {
          state.acceptPolicyApiStatus = "idle";
          if(state.basicUserInfo){
            state.basicUserInfo.policy_accepted = true
            localStorage.setItem("userInfo", JSON.stringify(state.basicUserInfo));
          }
        }
      )
      .addCase(acceptPolicyApi.rejected, (state, action) => {
        state.acceptPolicyApiStatus = "failed";
        if (action.payload) {
          state.error =
            (action.payload as ErrorResponse).errors || "Error occurred";
        } else {
          state.error = action.error.message || "Error occurred";
        }
      })
  },
});

export const {resetAuth} = authSlice.actions;
export default authSlice.reducer;