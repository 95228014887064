import { Box } from "@mui/material";
import styles from "./NamedToggle.module.scss";
import { csx } from "helpers/utils";

type PropType = {
  value: boolean;
  falseLabel: string;
  trueLabel: string;
  onChange: (value: boolean) => void;
}

const NamedToggle: React.FC<PropType> = ({value, falseLabel, trueLabel, onChange}) => {

  const handleToggle = (val: boolean) => {
    if(val === value) return;
    onChange(val);
  }

  return (
    <Box className={styles['toggle']}>
      <Box className={csx(styles['false-label'], !value ? styles['selected']: '')} onClick={() => handleToggle(false)}>
        <p className={styles['label']}>{falseLabel}</p>
      </Box>
      <Box className={csx( styles['true-label'], value ? styles['selected']: '')} onClick={() => handleToggle(true)}>
        <p className={styles['label']}>{trueLabel}</p>
      </Box>
    </Box>
  );
}

export default NamedToggle;