import styles from "./Dashboard.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { checkCurrencyNA, csx } from "../../helpers/utils";
import { lakhFormat } from "helpers/formatUtils";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import ChartHeader from "components/atoms/ChartHeader/ChartHeader";
import { DataRow, DataCol } from "components/atoms/ChartData/ChartData";
import { RootState } from "store";
import { resetFilter, setEndDateFilter, setSalesReportOrderCreatedEndDateFilter, setSalesReportOrderCreatedStartDateFilter, setStartDateFilter } from "slices/filtersSlice";
import { ROUTE_REPORT_MAP } from "../../constants";


const FbvStorageChargesChart = () => {

  const dispatch = useAppDispatch();
  const dashboardData = useAppSelector((state) => state.dashboard);
  const startDateFilter = useAppSelector((state: RootState) => state.filters.startDateFilter);
  const endDateFilter = useAppSelector((state: RootState) => state.filters.endDateFilter);

  const totalCharges = (dashboardData.fbvStockChargesSummary?.total_pickpack_charges || 0) + (dashboardData.fbvStockChargesSummary?.total_storage_charges || 0)

  const handleChartClick = (chart_type: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.CHART_CLICK,
      {chart_type}
    );
  }

  const triggerReportViewedEvent = (path: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_VIEWED,
      {
        report_name: ROUTE_REPORT_MAP[path],
        source: 'Dashboard'
      }
    );
  }

  const chartName = "fbv-storage-charges-chart";

  return (
    <div className={csx(styles['chart-report-wrapper'], styles[chartName])} onClick={() => handleChartClick('sales_summary')}>
      {dashboardData.fbvStockChargesSummaryStatus === 'loading' && <p className={styles['load-err-txt']}>Loading...</p>}
      {dashboardData.fbvStockChargesSummaryStatus === 'failed' && <p className={styles['load-err-txt']}>Error: {dashboardData.fbvStockChargesSummaryError}</p>}
      {dashboardData.fbvStockChargesSummaryStatus === 'idle' && (
        <>
          <ChartHeader
            headerTxt={'Summary of FBV Storage Charges'}
            tooltipTxt={'Comprehensive insights of FBV storage charges for the applied date range'}
            link={'/fbv-storage-charges-report'}
            onClick={() => {
              dispatch(resetFilter());
              dispatch(setStartDateFilter(startDateFilter));
              dispatch(setEndDateFilter(endDateFilter));
              triggerReportViewedEvent('/fbv-storage-charges-report')
            }}
          />
          <DataRow
            className={styles['data-row']}
            children={[
              <DataCol
                label={'Number of SKUs live on FBV'} 
                value={dashboardData.fbvStockChargesSummary?.live_fbv_sku_count} 
              />,
              <DataCol
                label={'Net Sale Quantity (Orders placed - RTO and Return)'} 
                value={dashboardData.fbvStockChargesSummary?.net_sale_quantity}
              />,
            ]}
          />
          <DataRow
            className={styles['data-row']}
            children={[
              <DataCol
                label={'Total Pick Pack Charges'}
                value={checkCurrencyNA(dashboardData.fbvStockChargesSummary?.total_pickpack_charges, false)}
              />,
              <DataCol
                label={'Total Storage Charges'}
                value={checkCurrencyNA(dashboardData.fbvStockChargesSummary?.total_storage_charges, false)}
              />,
            ]}
          />
          <div className={styles['data-row-wrapper']}>
            <DataRow
              className={styles['data-row']}
              children={[
                <DataCol
                  label={'Total Expense'}
                  value={null}
                />,
                <DataCol
                  label={null}
                  value={lakhFormat(totalCharges, 2)}
                />,
              ]}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default FbvStorageChargesChart;