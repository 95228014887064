import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Button, { ButtonProps } from '@mui/material/Button';
import { csx } from "helpers/utils";
import styles from "./ButtonX.module.scss";

const DEFAULT_BUTTON_COLOR = '#3361FF';


type ButtonXProps = Omit<ButtonProps, 'color'> & {
  color?: string;
}

const customTheme = (color: string) => createTheme({
  palette: {
    primary: {
      main: color,
    }
  },
});

const ButtonX:React.FC<ButtonXProps> = (props) => {
  const {color = DEFAULT_BUTTON_COLOR, className, variant = 'contained', size = 'medium', ...rest} = props;
  return (
    <ThemeProvider theme={customTheme(color)}>
      <Button
        className={csx(className, styles.buttonX)}
        color="primary"
        variant={variant}
        size={size}
        {...rest}
      />
    </ThemeProvider>
  );
}

export default ButtonX;