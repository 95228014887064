import { SohReportDataHash } from "../../slices/sohReportSlice";
import {ColumnPropType, FixedColumnPropType} from "../../components/organisms/TableManager/TableManager";
import styles from "./SohReport.module.scss";
import ProductThumbnail, { ProductThumbnailLite } from "../../components/atoms/ProductThumbnail/ProductThumbnail";

export const fixedColumn:FixedColumnPropType<SohReportDataHash> = {
  label: 'Product / SKU',
  accessor: 'product_variant_sku_code',
  width: 120,
  maxWidth: 120,
  thresold: (300 - 120), // calculated based on first colum of table
  cell: ({row}) => <ProductThumbnailLite 
    sku={row.product_variant_sku_code || ''}
    src={row.product_image}
    fbv_enabled={row.fbv_enabled}
    fbv_warehouse={row.fbv_warehouse}
  />
}

export const sohReportColumns = ():ColumnPropType<SohReportDataHash>[] => [
  {
    label: 'Product / SKU',
    accessor: 'product_variant_sku_code',
    minWidth: 300,
    cell: ({row}) => <ProductThumbnail 
      sku={row.product_variant_sku_code || ''}
      title={row.product_title}
      src={row.product_image}
      fbv_enabled={row.fbv_enabled}
      fbv_warehouse={row.fbv_warehouse}
    />
  },
  {
    label: 'ROS / Day (Quantity)',
    accessor: 'product_sales_rate',
    minWidth: 200,
    sortable: true,
    align: 'center',
  },
  {
    label: 'Stock On Hand',
    accessor: 'product_soh',
    minWidth: 200,
    sortable: true,
    align: 'center',
  },
  {
    label: 'Holding Days',
    accessor: 'product_holding_days',
    minWidth: 200,
    sortable: true,
    align: 'center',
    cell: ({value}) => {
      return <div className={value <= 10 ? styles.warning : ''}>
        {value} Days
      </div>
    }
  },
  {
    label: 'SOH Threshold',
    accessor: 'product_soh_threshold',
    minWidth: 200,
    sortable: true,
    align: 'center',
    cell: ({row : {product_holding_days, product_soh_threshold}}) => {
      return <div className={product_holding_days <= 10 ? styles.warning : ''}>
        {product_soh_threshold}
      </div>
    }
  },
];