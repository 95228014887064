import { useState, useRef, useEffect } from "react";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import { Box, FormControl, TextField, MenuItem, Modal, Typography, IconButton, Button, useTheme, useMediaQuery, SwipeableDrawer, Select, InputLabel } from "@mui/material";
import { styled } from '@mui/system';
import { Delete } from '@mui/icons-material';
import { createIssueApi } from "../../../slices/createIssuePostSlice";
import styles from "./GetHelpModal.module.scss";
import ModalBox from "components/atoms/ModalBox/ModalBox";
import ColorButton from "components/atoms/ColorButton/ColorButton";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { NotificationType, showNotification } from "slices/notificationSlice";

type GetHelpModalPropType = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const Input = styled('input')({
  display: 'none',
});

const getHelpReasons = [
  {
    value: 'pickup_delayed',
    label: 'Pickup Delayed',
  },
  {
    value: 'order_cancellation',
    label: 'Order Cancellation',
  },
  {
    value: 'packing_material',
    label: 'Packing Material',
  },
  {
    value: 'order_processing',
    label: 'Order Processing',
  },
  {
    value: 'claims',
    label: 'Claims',
  },
  {
    value: 'returns_n_exchanges',
    label: 'Returns and Exchanges',
  },
  {
    value: 'payments',
    label: 'Payments',
  },
  {
    value: 'catalogue',
    label: 'Catalogue',
  },
  {
    value: 'fbv',
    label: 'Fulfilled by Vaaree'
  },
  {
    value: 'others',
    label: 'Others'
  }
];

type FormStateType = {
  reason: string;
  summary: string;
  description: string;
  images: File[];
}

const initialFormState:FormStateType = {
  reason: '',
  summary: '',
  description: '',
  images: [],
};


type FormContentPropType = {
  isMobile: boolean;
  formData: FormStateType;
  handleChange: (e: any) => void;
  handleRemoveImage: (idx: number) => void;
  handleImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  handleSubmit: (e: React.FormEvent) => void;
  imgObjUrls: string[];
}

const FormContent:React.FC<FormContentPropType> = (props: FormContentPropType) => {
  const {
    isMobile,
    formData,
    handleChange,
    handleRemoveImage,
    handleImageChange,
    onClose,
    handleSubmit,
    imgObjUrls,
  } = props;

  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={isMobile ? styles['swipeable-mobile-drawer']: undefined}>
        <div className={styles['get-help-header']}>
          Get Help
        </div>
        <Box component="form" className={styles['gethelp-form']}>
          <FormControl fullWidth>
            <InputLabel id="reason-select-label">Select Reason *</InputLabel>
            <Select
              className={styles['txt-input']}
              labelId="reason-select-label"
              id="reason-select"
              value={formData.reason}
              label="Select Reason *"
              required
              onChange={handleChange}
              name="reason"
            >
              {getHelpReasons.map((option) => (
                <MenuItem key={option.value} value={option.value} dense className={styles['select-option']}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField fullWidth required name="summary" onChange={handleChange} id="outlined-summary" label="Summary" className={styles['txt-input']} value={formData.summary} disabled={!formData.reason} />

          <TextField fullWidth required name="description" onChange={handleChange} id="outlined-description" label="Description" className={styles['txt-input']} multiline rows={4} value={formData.description} disabled={!formData.reason} />

          <div className={styles["image-upload"]}>
            <p className={styles['image-upload-label']}>Attach Image <span>Only .jpg or .png format</span></p>
            <div className={styles['image-list']}>
              {formData.images.map((img, i) => {
                return (
                  <Box className={styles['image-list-item']}>
                    <img
                      className="image-upload-preview"
                      src={imgObjUrls[i]}
                      alt={`Preview Image ${i}`}
                    />
                    <IconButton
                      className={styles['delete-btn']}
                      onClick={() => handleRemoveImage(i)}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </Box>
                );
              })}
              <Input
                accept="image/jpg, image/jpeg, image/png"
                id="upload-button"
                multiple
                type="file"
                onChange={handleImageChange}
                ref={fileInputRef}
              />
              <label htmlFor="upload-button" className={styles['upload-btn']} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                fileInputRef.current?.click();
              }}>
                <AddOutlinedIcon fontSize="large" />
              </label>
            </div>
          </div>
        </Box>

        <div className={styles['get-help-footer']}>
          <ColorButton bgColor="#FFF" className={styles['discard-button']} variant="outlined" onClick={onClose}>
            Discard
          </ColorButton>
          <ColorButton bgColor="#3361FF" bgHoverColor="#2E58E8" className={styles['submit-button']} onClick={handleSubmit} disabled={!formData.reason || !formData.summary || !formData.description}>
            Submit
          </ColorButton>
        </div>
      </div>
  );
}


const GetHelpModal = (props: GetHelpModalPropType) => {

  const dispatch = useAppDispatch();
  const {open, onClose, onOpen} = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [formData, setFormData] = useState<FormStateType>(initialFormState);
  const [imgObjUrls, setImgObjUrls] = useState<string[]>([]);

  useEffect(() => {
    if(open){
      setFormData(initialFormState);
      setImgObjUrls([]);
    }
  }, [open]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const images = Array.from(event.target.files);
      setFormData({
        ...formData,
        images: [...formData.images, ...images],
      });
      setImgObjUrls((prevVal) => [...prevVal, ...images.map(img => URL.createObjectURL(img))]);
    }
    event.target.value = '';
  };

  const handleRemoveImage = (index: number) => {
    setFormData({
      ...formData,
      images: formData.images.filter((_, i) => i !== index),
    });
    setImgObjUrls((prevVal) => {
      return prevVal.filter((url, i) => {
        if(i === index){
          URL.revokeObjectURL(url);
        }
        return i !== index;
      });
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    if(formData.images.length > 5){
      dispatch(showNotification({
        type: NotificationType.Info,
        message: 'Maximum 5 images can be uploaded',
      }));
      return;
    }
    e.preventDefault();
    dispatch(createIssueApi(formData));
    onClose();
  };

  const handleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }
    open ? onOpen() : onClose();
  };


  if(isMobile){
    return (
      <SwipeableDrawer
        anchor={"bottom"}
        open={open}
        onClose={handleDrawer(false)}
        onOpen={handleDrawer(true)}
        className={styles['drawer']}
      >
        <FormContent
          isMobile={isMobile}
          formData={formData}
          handleChange={handleChange}
          handleRemoveImage={handleRemoveImage}
          handleImageChange={handleImageChange}
          onClose={onClose}
          handleSubmit={handleSubmit}
          imgObjUrls={imgObjUrls}
        />
      </SwipeableDrawer>
    );
  }

  return (
    <ModalBox
      open={open}
      onClose={onClose}
      className={styles['get-help-modal']}
    >
      <FormContent
        isMobile={isMobile}
        formData={formData}
        handleChange={handleChange}
        handleRemoveImage={handleRemoveImage}
        handleImageChange={handleImageChange}
        onClose={onClose}
        handleSubmit={handleSubmit}
        imgObjUrls={imgObjUrls}
      />
    </ModalBox>
  );
}

export default GetHelpModal;
