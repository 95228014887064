import { Box, Checkbox, ClickAwayListener } from "@mui/material";
import styles from "./MultiSelectFilter.module.scss";
import LightTooltip from "components/atoms/LightTooltip/LightTooltip";
import { useEffect, useState } from "react";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import { csx } from "helpers/utils";
import ClickTooltip from "components/atoms/ClickTooltip/ClickTooltip";
import ColorButton from "components/atoms/ColorButton/ColorButton";

type Option = {label: string, value: string};

type PropType  = {
  label: React.ReactNode | JSX.Element;
  values: string[];
  options: Option[];
  allowAll?: Boolean;
  onSubmit: (values: string[]) => void;
}


const MultiSelectFilter:React.FC<PropType> = ({label, values, options, allowAll = false, onSubmit}) => {

  const [showOptions, setShowOptions] = useState(false);

  const [currentValues, setCurrentValues] = useState<string[]>([]);

  const optionLabel = options.filter(op => currentValues.includes(op.value)).map(op => op.label).join(', ');

  useEffect(() => {
    setCurrentValues(values);
  }, [values, showOptions]);

  const updateCurrentValues = (val: string) => {
    setCurrentValues(prevVal => {
      if(prevVal.includes(val)){
        return prevVal.filter(v => v !== val);
      }
      return [...prevVal, val];
    });
  }

  const selectAll = () => {
    setCurrentValues(options.map(option => option.value));
  };

  const deselectAll = () => {
    setCurrentValues([]);
  };

  return (
    <Box className={styles['multi-select-filter']}>
      <ClickTooltip
        className={styles['popover-style']}
        show={showOptions}
        showPopper={setShowOptions}
        placement="bottom-start"
        title={
          <>
            <div className={styles['options-section-wrapper']}>
              <div className={styles['options-wrapper']}>
                {allowAll && !!options.length ?
                  (<div 
                    className={csx(styles['filter-label'], styles['select-all'])}
                    onClick={currentValues.length === options.length ? deselectAll : selectAll}
                  >
                    <Checkbox
                      checked={currentValues.length === options.length}
                      className={styles['checkbox']}
                      size="small"
                    />
                    <span>{currentValues.length === options.length ? 'Deselect All' : 'Select All'}</span>
                  </div>)
                  :(<></>)
                }
                {options.map((option) => {
                  const selected = currentValues.includes(option.value);
                  return (
                    <div className={csx(styles['filter-label'], selected ? styles['selected']: undefined)} 
                      onClick={() => {
                        updateCurrentValues(option.value);
                      }}
                    >
                      <Checkbox checked={selected} className={styles['checkbox']} size="small" />
                      <span>{option.label}</span>
                    </div>
                  )
                })}
              </div>
              <div className={csx(styles['filter-label'], styles['submit-button-box'])}>
                <ColorButton
                  variant={'contained'} 
                  bgColor="#3361FF"
                  onClick={() => {
                    onSubmit(currentValues);
                    setShowOptions(false);
                  }}
                >Submit</ColorButton>
              </div>
            </div>
          </>
        }
      >
        <Box className={styles['multi-select-button']}
          onClick={() => options.length > 0 && setShowOptions((prevVal) => !prevVal)}
        >
          <p>{label}{optionLabel ? ': ': ''}<span>{optionLabel}</span></p>
          {showOptions ? <ExpandLessOutlinedIcon fontSize="small" /> : <ExpandMoreOutlinedIcon fontSize="small" />}
        </Box>
      </ClickTooltip>
    </Box>
  );
}

export default MultiSelectFilter;
