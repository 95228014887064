import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { getPercentVal } from "helpers/utils";

type OtsDateWiseData = {
  date: string;
  total_shipments: number;
  on_time_shipments_count: number;
  on_time_shipments_percent: number;
}

type OtsDataHash = {
  total_fbv_ots_percentage: number;
  total_seller_ots_percentage: number;
  seller_data: OtsDateWiseData[];
  fbv_data: OtsDateWiseData[];
  available_dates: string[];
}

type OtsDataPayload = {
  ots_data: OtsDataHash;
}

type OtsData = {
  totalSellerOtsPercentage: string;
  totalFbvOtsPercentage: string;
  otsDays: String[];
  onTimeSellerOtsData: (number|null)[];
  onTimeFbvOtsData: (number|null)[];
  fbvDaysCount: number;
  sellerDaysCount: number;
}

type ProductSalesHash = {
  products_live_count: number;
  products_live_instock_count: number;
  unique_products_sold_count: number;
  total_quantities_sold: number;
  total_value_sold: number;
}

type ProductSalesPayload = {
  product_sales: ProductSalesHash;
}

type ProductSales = {
  unique_products_live_count: number;
  unique_products_live_instock_count: number;
  unique_products_live_instock_count_percent: string;
  unique_products_sold_count: number;
  unique_products_sold_count_percent: string;
  total_quantity_sold_count: number;
  total_value_sold_count: number;
  average_selling_price: number;
}

type OpenOrdersSummaryPayload = {
  total_open_orders_in_given_range_count: number;
  total_sla_breached_orders_in_given_range_count: number;
  total_open_orders_count: number;
  total_sla_breached_orders_count: number;
}

type OpenOrdersSummary = {
  total_open_orders_in_given_range_count: number;
  total_sla_breached_orders_in_given_range_count: number;
  sla_breached_order_in_given_range_percent: string;
  total_open_orders_count: number;
  total_sla_breached_orders_count: number;
  sla_breached_order_count_percent: string;
}

type InventorySummary = {
  oos_count: number;
  near_to_oos_count: number;
}

type LossOfRevenueSummary = {
  oos_count: number;
  lor: string;
}

type PaymentSummary = {
  last_payment_amount: string;
  next_payment_amount: string;
}

type ReasonsDataHash = {
  reason: string,
  exchange_cnt: number,
  return_cnt: number,
  return_loss: number,
  exchange_loss: number,
}
type ReturnExchangeSummary = {
  delivered_count: number,
  delivered_revenue: number,
  return_count: number,
  return_loss: number,
  exchange_count: number,
  exchange_loss: number,
  reasons_data: ReasonsDataHash[]
}

export type RtoCategoryHash = {
  category: string,
  revenue: number,
  count: number
}

type RtoSummary = {
  total_shipped_shipments: number,
  total_rto_shipments: number,
  total_in_transit_shipments: number,
  total_shipped_shipment_revenue: number,
  total_rto_shipment_revenue: number,
  total_in_transit_shipment_revenue: number,
  rto_category_summary: RtoCategoryHash[]
}

type ProductReturnHash = {
  sku: string,
  title: string,
  price: number,
  total_quantity: number,
  img_url: string,
}

type ProductReturnSummary = {
  summary : ProductReturnHash[]
}

type FbvStockChargesSummary = {
  live_fbv_sku_count: number,
  net_sale_quantity: number,
  total_pickpack_charges: number,
  total_storage_charges: number,
}

type ErrorResponse = {
  errors: string;
};

type DashboardApiState = {
  otsData?: OtsData | null;
  otsDataStatus: "idle" | "loading" | "failed";
  otsDataError: string | null;
  productSales?: ProductSales | null;
  productSalesStatus: "idle" | "loading" | "failed";
  productSalesError: string | null;
  openOrdersSummary?: OpenOrdersSummary | null;
  openOrdersSummaryStatus:  "idle" | "loading" | "failed";
  openOrdersSummaryError: string | null;
  inventorySummary?: InventorySummary | null;
  inventorySummaryStatus: "idle" | "loading" | "failed";
  inventorySummaryError: string | null;
  lossOfRevenueSummary?: LossOfRevenueSummary | null;
  lossOfRevenueSummaryStatus:  "idle" | "loading" | "failed";
  lossOfRevenueSummaryError: string | null;
  paymentSummary?: PaymentSummary | null;
  paymentSummaryStatus:  "idle" | "loading" | "failed";
  paymentSummaryError: string | null;
  returnExchangeSummary?: ReturnExchangeSummary | null;
  returnExchangeSummaryStatus:  "idle" | "loading" | "failed";
  returnExchangeSummaryError: string | null;
  rtoSummary?: RtoSummary | null;
  rtoSummaryStatus:  "idle" | "loading" | "failed";
  rtoSummaryError: string | null;
  productReturnSummary?: ProductReturnSummary | null;
  productReturnSummaryStatus:  "idle" | "loading" | "failed";
  productReturnSummaryError: string | null;
  fbvStockChargesSummary?: FbvStockChargesSummary | null;
  fbvStockChargesSummaryStatus:  "idle" | "loading" | "failed";
  fbvStockChargesSummaryError: string | null;
};

const initialState: DashboardApiState = {
  otsData: null,
  otsDataStatus: "loading",
  otsDataError: null,
  productSalesStatus: "loading",
  productSalesError: null,
  openOrdersSummaryStatus: "loading",
  openOrdersSummaryError: null,
  inventorySummaryStatus: "loading",
  inventorySummaryError: null,
  lossOfRevenueSummaryStatus: "loading",
  lossOfRevenueSummaryError: null,
  paymentSummaryStatus: "loading",
  paymentSummaryError: null,
  returnExchangeSummaryStatus: "loading",
  returnExchangeSummaryError: null,
  rtoSummaryStatus: 'loading',
  rtoSummaryError: null,
  productReturnSummary: null,
  productReturnSummaryStatus: "loading",
  productReturnSummaryError:null,
  fbvStockChargesSummary: null,
  fbvStockChargesSummaryStatus: "loading",
  fbvStockChargesSummaryError: null,
};

export const otsApi = createAsyncThunk(
  "otsApi",
  async ({startDate, endDate, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get("/forge/dashboards/on_time_shipment?from_date=" + startDate + "&to_date=" + endDate, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const productSalesApi = createAsyncThunk(
  "productSalesApi",
  async ({startDate, endDate, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get("/forge/dashboards/product_sales_summary?from_date=" + startDate + "&to_date=" + endDate, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const inventorySummaryApi = createAsyncThunk(
  "inventorySummaryApi",
  async ({startDate, endDate, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get("/forge/dashboards/inventory_summary?from_date=" + startDate + "&to_date=" + endDate, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const openOrdersSummaryApi = createAsyncThunk(
  "openOrdersSummaryApi",
  async ({startDate, endDate, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get("/forge/dashboards/open_orders_summary?from_date=" + startDate + "&to_date=" + endDate, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const lossOfRevenueSummaryApi = createAsyncThunk(
  "lossOfRevenueSummaryApi",
  async ({headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get("/forge/dashboards/loss_of_revenue_summary", config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
)

export const returnExchangeSummaryApi = createAsyncThunk(
  "returnExchangeSummaryApi",
  async ({startDate, endDate, headers} : any, { rejectWithValue }) => {
    try {
      const config: AxiosRequestConfig = {
        headers: headers || {},
      };
      const response = await axiosInstance.get("/forge/dashboards/return_exchange_summary?from_date=" + startDate + "&to_date=" + endDate, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
)

export const rtoSummaryApi = createAsyncThunk(
  "rtoSummaryApi",
  async ({startDate, endDate, headers} : any, { rejectWithValue }) => {
    try {
      const config: AxiosRequestConfig = {
        headers: headers || {},
      };
      const response = await axiosInstance.get("/forge/dashboards/rto_summary?from_date=" + startDate + "&to_date=" + endDate, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
)

export const paymentSummaryApi = createAsyncThunk(
  "paymentSummaryApi",
  async ({headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get("/forge/dashboards/payments_summary", config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
)


export const productReturnSummaryApi = createAsyncThunk(
  "productReturnSummaryApi",
  async ({startDate, endDate, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get("/forge/dashboards/product_returns_summary?from_date=" + startDate + "&to_date=" + endDate, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
)

export const fbvStockChargesSummaryApi = createAsyncThunk(
  "fbvStockChargesSummaryApi",
  async ({startDate, endDate, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get("/forge/dashboards/fbv_stock_fee_summary?from_date=" + startDate + "&to_date=" + endDate, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
)

export const productReturnSummaryDownloadApi = createAsyncThunk(
  "productReturnSummaryDownloadApi",
  async ({startDate, endDate, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get("/forge/dashboards/product_returns_summary.csv?from_date=" + startDate + "&to_date=" + endDate, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
)

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(otsApi.pending, (state) => {
        state.otsDataStatus = "loading";
        state.otsDataError = null;
      })
      .addCase(
        otsApi.fulfilled,
        (state, action: PayloadAction<OtsDataPayload>) => {
          state.otsDataStatus = "idle";

          // processing OTS data - Required for Dashboard Chart
          const ots_data = action.payload.ots_data;

          let totalSellerOtsPercentage:string  = 'NA';
          let totalFbvOtsPercentage:string  = 'NA';
          let otsDays: String[] = [];
          let onTimeSellerOtsData: (number|null)[] = [];
          let onTimeFbvOtsData: (number|null)[] = [];
          let fbvDaysCount: number =  0;
          let sellerDaysCount: number = 0;

          otsDays = ots_data.available_dates;

          const total_dates = otsDays.length;
          for(let i = 0 ; i < total_dates ; i += 1){
            if(ots_data.fbv_data[i].total_shipments > 0){
              fbvDaysCount += 1;
            }
            if(ots_data.seller_data[i].total_shipments > 0){
              sellerDaysCount += 1;
            }
            onTimeFbvOtsData.push(
              ots_data.fbv_data[i].total_shipments > 0 ? ots_data.fbv_data[i].on_time_shipments_percent: null
            );
            onTimeSellerOtsData.push(
              ots_data.seller_data[i].total_shipments > 0 ? ots_data.seller_data[i].on_time_shipments_percent: null
            );
          }

          if(fbvDaysCount > 0){
            totalFbvOtsPercentage = ots_data.total_fbv_ots_percentage + '%';
          }
          if(sellerDaysCount > 0){
            totalSellerOtsPercentage = ots_data.total_seller_ots_percentage + '%';
          }
          state.otsData = {
            totalSellerOtsPercentage,
            totalFbvOtsPercentage,
            otsDays,
            onTimeSellerOtsData,
            onTimeFbvOtsData,
            fbvDaysCount,
            sellerDaysCount,
          }
        }
      )
      .addCase(otsApi.rejected, (state, action) => {
        state.otsDataStatus = "failed";
        if (action.payload) {
          state.otsDataError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.otsDataError = action.error.message || "Error occured";
        }
      })
      .addCase(productSalesApi.pending, (state) => {
        state.productSalesStatus = "loading";
        state.productSalesError = null;
      })
      .addCase(
        productSalesApi.fulfilled,
        (state, action: PayloadAction<ProductSalesPayload>) => {
          state.productSalesStatus = "idle";

          // processing Sales data - Required for Dashboard Chart
          var product_sales = action.payload.product_sales;
          var unique_products_live_count: number = 0;
          var unique_products_live_instock_count: number = 0;
          var unique_products_live_instock_count_percent: string = 'NA';
          var unique_products_sold_count: number = 0;
          var unique_products_sold_count_percent: string = 'NA';
          var total_quantity_sold_count: number = 0;
          var total_value_sold_count: number = 0;
          var average_selling_price: number = 0;
          unique_products_live_count = product_sales.products_live_count;
          unique_products_live_instock_count = product_sales.products_live_instock_count;
          unique_products_live_instock_count_percent = '(' + Math.round(getPercentVal(product_sales.products_live_instock_count , unique_products_live_count)) + '%)';
          unique_products_sold_count = product_sales.unique_products_sold_count;
          unique_products_sold_count_percent = '(' + Math.round(getPercentVal(product_sales.unique_products_sold_count, unique_products_live_count)) + '%)';
          total_quantity_sold_count = product_sales.total_quantities_sold;
          total_value_sold_count = product_sales.total_value_sold;
          average_selling_price = total_quantity_sold_count ? ( total_value_sold_count / total_quantity_sold_count): 0
          state.productSales = {
            unique_products_live_count: unique_products_live_count,
            unique_products_live_instock_count: unique_products_live_instock_count,
            unique_products_live_instock_count_percent: unique_products_live_instock_count_percent,
            unique_products_sold_count: unique_products_sold_count,
            unique_products_sold_count_percent: unique_products_sold_count_percent,
            total_quantity_sold_count: total_quantity_sold_count,
            total_value_sold_count: total_value_sold_count,
            average_selling_price: average_selling_price
          }
        }
      )
      .addCase(productSalesApi.rejected, (state, action) => {
        state.productSalesStatus = "failed";
        if (action.payload) {
          state.productSalesError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.productSalesError = action.error.message || "Error occured";
        }
      })
      .addCase(openOrdersSummaryApi.pending, (state) => {
        state.openOrdersSummaryStatus = "loading";
        state.openOrdersSummaryError = null;
      })
      .addCase(
        openOrdersSummaryApi.fulfilled,
        (state, action: PayloadAction<OpenOrdersSummaryPayload>) => {
          state.openOrdersSummaryStatus = "idle";
          
          // processing Open Orders data - Required for Dashboard Chart
          var total_open_orders_in_given_range_count: number = action.payload.total_open_orders_in_given_range_count;
          var total_sla_breached_orders_in_given_range_count: number = action.payload.total_sla_breached_orders_in_given_range_count;
          var sla_breached_order_in_given_range_percent = '(' + Math.round(getPercentVal(total_sla_breached_orders_in_given_range_count, total_open_orders_in_given_range_count)) + '%)';
          var total_open_orders_count: number = action.payload.total_open_orders_count;
          var total_sla_breached_orders_count: number = action.payload.total_sla_breached_orders_count;
          var sla_breached_order_count_percent: string = '(' + Math.round(getPercentVal(total_sla_breached_orders_count, total_open_orders_count)) + '%)';
          state.openOrdersSummary = {
            total_open_orders_in_given_range_count: total_open_orders_in_given_range_count,
            total_sla_breached_orders_in_given_range_count: total_sla_breached_orders_in_given_range_count,
            sla_breached_order_in_given_range_percent: sla_breached_order_in_given_range_percent,
            total_open_orders_count: total_open_orders_count,
            total_sla_breached_orders_count: total_sla_breached_orders_count,
            sla_breached_order_count_percent: sla_breached_order_count_percent
          }
        }
      )
      .addCase(openOrdersSummaryApi.rejected, (state, action) => {
        state.openOrdersSummaryStatus = "failed";
        if (action.payload) {
          state.openOrdersSummaryError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.openOrdersSummaryError = action.error.message || "Error occured";
        }
      })
      .addCase(inventorySummaryApi.pending, (state) => {
        state.inventorySummaryStatus = "loading";
        state.inventorySummaryError = null;
      })
      .addCase(
        inventorySummaryApi.fulfilled,
        (state, action: PayloadAction<InventorySummary>) => {
          state.inventorySummaryStatus = "idle";
          state.inventorySummary = action.payload;
        }
      )
      .addCase(inventorySummaryApi.rejected, (state, action) => {
        state.inventorySummaryStatus = "failed";
        if (action.payload) {
          state.inventorySummaryError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.inventorySummaryError = action.error.message || "Error occured";
        }
      })
      .addCase(lossOfRevenueSummaryApi.pending, (state) => {
        state.lossOfRevenueSummaryStatus = "loading";
        state.lossOfRevenueSummaryError = null;
      })
      .addCase(
        lossOfRevenueSummaryApi.fulfilled,
        (state, action: PayloadAction<LossOfRevenueSummary>) => {
          state.lossOfRevenueSummaryStatus = "idle";
          state.lossOfRevenueSummary = action.payload;
        }
      )
      .addCase(lossOfRevenueSummaryApi.rejected, (state, action) => {
        state.lossOfRevenueSummaryStatus = "failed";
        if (action.payload) {
          state.lossOfRevenueSummaryError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.lossOfRevenueSummaryError = action.error.message || "Error occured";
        }
      })
      .addCase(returnExchangeSummaryApi.pending, (state) => {
        state.returnExchangeSummaryStatus = "loading";
        state.returnExchangeSummaryError = null;
      })
      .addCase(
        returnExchangeSummaryApi.fulfilled,
        (state, action: PayloadAction<ReturnExchangeSummary>) => {
          state.returnExchangeSummaryStatus = "idle";
          state.returnExchangeSummary = action.payload as ReturnExchangeSummary;
        }
      )
      .addCase(returnExchangeSummaryApi.rejected, (state, action) => {
        state.returnExchangeSummaryStatus = "failed";
        if (action.payload) {
          state.returnExchangeSummaryError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.returnExchangeSummaryError = action.error.message || "Error occured";
        }
      })
      .addCase(rtoSummaryApi.pending, (state) => {
        state.rtoSummaryStatus = "loading";
        state.rtoSummaryError = null;
      })
      .addCase(
        rtoSummaryApi.fulfilled,
        (state, action: PayloadAction<RtoSummary>) => {
          state.rtoSummaryStatus = "idle";
          let rtoSummaryData = action.payload as RtoSummary;
          let rtoCategorySummary = rtoSummaryData.rto_category_summary;
          rtoCategorySummary = rtoCategorySummary.sort((a, b) => {
            return b.count - a.count;
          });
          if(rtoCategorySummary.length > 3){
            rtoCategorySummary[3].category = 'Others'
            for(let i = 4 ; i < rtoCategorySummary.length; i += 1){
              rtoCategorySummary[3].count += rtoCategorySummary[i].count;
              rtoCategorySummary[3].revenue += rtoCategorySummary[i].revenue;
            }
          }
          rtoSummaryData.rto_category_summary = rtoCategorySummary.slice(0, 4);
          state.rtoSummary = rtoSummaryData;
        }
      )
      .addCase(rtoSummaryApi.rejected, (state, action) => {
        state.rtoSummaryStatus = "failed";
        if (action.payload) {
          state.rtoSummaryError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.rtoSummaryError = action.error.message || "Error occured";
        }
      })
      .addCase(paymentSummaryApi.pending, (state) => {
        state.paymentSummaryStatus = "loading";
        state.paymentSummaryError = null;
      })
      .addCase(
        paymentSummaryApi.fulfilled,
        (state, action: PayloadAction<PaymentSummary>) => {
          state.paymentSummaryStatus = "idle";
          state.paymentSummary = action.payload;
        }
      )
      .addCase(paymentSummaryApi.rejected, (state, action) => {
        state.paymentSummaryStatus = "failed";
        if (action.payload) {
          state.paymentSummaryError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.paymentSummaryError = action.error.message || "Error occured";
        }
      })
      .addCase(productReturnSummaryApi.pending, (state) => {
        state.productReturnSummaryStatus = "loading";
        state.productReturnSummaryError = null;
      })
      .addCase(
        productReturnSummaryApi.fulfilled,
        (state, action: PayloadAction<ProductReturnSummary>) => {
          state.productReturnSummaryStatus = "idle";
          state.productReturnSummary  = action.payload as ProductReturnSummary;
        }
      )
      .addCase(productReturnSummaryApi.rejected, (state, action) => {
        state.productReturnSummaryStatus = "failed";
        if (action.payload) {
          state.productReturnSummaryError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.productReturnSummaryError = action.error.message || "Error occured";
        }
      })
      .addCase(fbvStockChargesSummaryApi.pending, (state) => {
        state.fbvStockChargesSummaryStatus = "loading";
        state.fbvStockChargesSummaryError = null;
      })
      .addCase(
        fbvStockChargesSummaryApi.fulfilled,
        (state, action: PayloadAction<FbvStockChargesSummary>) => {
          state.fbvStockChargesSummaryStatus = "idle";
          state.fbvStockChargesSummary  = action.payload as FbvStockChargesSummary;
        }
      )
      .addCase(fbvStockChargesSummaryApi.rejected, (state, action) => {
        state.fbvStockChargesSummaryStatus = "failed";
        if (action.payload) {
          state.fbvStockChargesSummaryError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.fbvStockChargesSummaryError = action.error.message || "Error occured";
        }
      })
  }
});

export default dashboardSlice.reducer;
