import { useEffect, useState } from "react";
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import { fbvStockChargesSummaryApi, otsApi, productSalesApi, inventorySummaryApi, openOrdersSummaryApi, lossOfRevenueSummaryApi, returnExchangeSummaryApi, paymentSummaryApi, rtoSummaryApi, productReturnSummaryApi } from "../../slices/dashboardSlice"
import {Box, Container, Typography, useMediaQuery, useTheme} from '@mui/material';

import {
  setStartDateFilter,
  setEndDateFilter,
  resetFilter,
} from "slices/filtersSlice";
import styles from './Dashboard.module.scss';

import { triggerMixpanelEvent, MIXPANEL_EVENT_TYPES } from "hooks/mixpanel_hook";
import { resetAuth } from "slices/authSlice";
import OTSChart from "./OTSChart";
import OpenOrdersChart from "./OpenOrdersChart";
import ProductSalesChart from "./ProductSalesChart";
import PaymentSummaryChart from "./PaymentSummaryChart";
import LORChart from "./LORChart";
import InventoryChart from "./InventoryChart";
import ReturnExchangeChart from "./ReturnExchangeChart";
import ProductReturnSummaryChart from "./ProductReturnSummaryChart";
import RTOSummaryChart from "./RTOSummaryChart";
import { csx } from "helpers/utils";
import DateRangeSelector, { DateRangeLabel } from "components/molecules/DateRangeSelector/DateRangeSelector";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import { DateRangeType } from "./../../constants";
import FbvStorageChargesChart from "./FbvStorageChargesChart";


const PAGE_TITLE = 'Dashboard';

const Dashboard = () => {

  const dispatch = useAppDispatch();

  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);

  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);

  const startDateFilter = useAppSelector((state: RootState) => state.filters.startDateFilter);
  const endDateFilter = useAppSelector((state: RootState) => state.filters.endDateFilter);

  const handleStartEndDateFilterChanged = (ranges: DateRangeType) => {
    dispatch(setStartDateFilter(ranges.startDate as Date));
    dispatch(setEndDateFilter(ranges.endDate as Date));
  };

  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT,
      {
        page_link: window.location.href,
        page_title: PAGE_TITLE
      }
    );
  }, []);

  

  useEffect(() => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      dispatch(otsApi({startDate: startDateFilter, endDate: endDateFilter, headers: headers}));
      dispatch(productSalesApi({startDate: startDateFilter, endDate: endDateFilter, headers: headers}));
      dispatch(inventorySummaryApi({startDate: startDateFilter, endDate: endDateFilter, headers: headers}));
      dispatch(openOrdersSummaryApi({startDate: startDateFilter, endDate: endDateFilter, headers: headers}));
      dispatch(lossOfRevenueSummaryApi({headers: headers}));
      dispatch(returnExchangeSummaryApi({startDate: startDateFilter, endDate: endDateFilter, headers: headers}));
      dispatch(productReturnSummaryApi({startDate: startDateFilter, endDate: endDateFilter, headers: headers}));
      dispatch(rtoSummaryApi({startDate: startDateFilter, endDate: endDateFilter, headers: headers}));
      dispatch(paymentSummaryApi({headers: headers}));
      dispatch(fbvStockChargesSummaryApi({startDate: startDateFilter, endDate: endDateFilter, headers: headers}));
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  }, [dispatch, startDateFilter, endDateFilter]);

  return (
    <Box className={styles['dashboard-wrapper']}>
      <MainHeader label="Dashboard">
        <DateRangeLabel
          startDate={startDateFilter}
          endDate={endDateFilter}
          onClick={() => setShowDateRangeFilter(true)}
        />
      </MainHeader>

      <Box className={styles['charts-container']}>
        <OTSChart />
        <OpenOrdersChart />
        <ProductSalesChart variant="sku-info" />
        <ProductSalesChart variant="sales-info" />
        <ProductSalesChart variant="complete-info" />
        <InventoryChart />
        <LORChart />
        <ReturnExchangeChart />
        <RTOSummaryChart />
        <PaymentSummaryChart />
        {fbv && <FbvStorageChargesChart />}
        <ProductReturnSummaryChart />
      </Box>
      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={startDateFilter}
        endDate={endDateFilter}
        updateDateRange={handleStartEndDateFilterChanged}
        header={'Select Date Range'}
        onOpen={() => {setShowDateRangeFilter(true)}}
      />
    </Box>
  );
};

export default Dashboard;