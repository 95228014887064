import { isDesktop, isMobile } from "react-device-detect";
const mixpanel = (window as any).mixpanel;

const isDev = () => process.env.REACT_APP_ENV !== 'production';
const deviceType = isDesktop ? 'Desktop' : (isMobile ? 'Mobile' : 'Tablet');

const initMixpanelIdentifier = (id: string) => {
  if(isDev()) return;
  mixpanel.identify(id);
}

const triggerMixpanelEvent = (eventType: string, eventProperties: Record<string, any>) => {
  if(isDev()) return;
  mixpanel.track(eventType, {
    ...eventProperties,
    platform: deviceType,
  });
}

const MIXPANEL_EVENT_TYPES = {
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  DOWNLOAD: 'Download',
  PAGE_VISIT: 'Page Visit',
  CHART_CLICK: 'Chart Clicked',
  REPORT_VIEWED: 'Report Viewed',
  REPORT_INTERACTION: 'Report Interaction'
}

export {mixpanel, initMixpanelIdentifier, triggerMixpanelEvent, MIXPANEL_EVENT_TYPES}