import styles from "./MobileNavigator.module.scss";
import { Box} from "@mui/material";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';  
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { useLocation, useNavigate } from "react-router-dom";
import {ROUTE_REPORT_MAP, ROUTES} from "../../../constants";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "hooks/mixpanel_hook";

type NavIconPropType = {
  label: string;
  icon: JSX.Element;
  selected: boolean;
}

const NavIcon: React.FC<NavIconPropType> = ({label, icon, selected}) => {
  return (
    <Box className={`${styles.navIconWrapper} ${selected ? styles.selected : ''}`}>
      {icon}
      <p className={styles.navLabel}>{label}</p>
      {selected && <div className={styles.backDropCircle} />}
    </Box>
  )
}

const bottomNavOptions = [
  {label: 'Dashboard' , icon: <HomeOutlinedIcon />, route: ROUTES.DASHBOARD},
  {label: 'Orders' , icon: <LocalShippingOutlinedIcon />, route: ROUTES.ORDER_TRACKING},
  {label: 'Products' , icon: <GridViewIcon />, route: ROUTES.PRODUCT_CATALOGUE},
  {label: 'Payments' , icon: <CurrencyRupeeOutlinedIcon />, route: ROUTES.PAYMENT},
  {label: 'Reports' , icon: <TextSnippetOutlinedIcon />, route: ROUTES.REPORT_LIST}
]

const MobileNavigator: React.FC = () => {

  const navigate = useNavigate();
  
  const location = useLocation();

  const isSelected = (route: string) => {
    if(route !== ROUTES.REPORT_LIST){
      return route === location.pathname;
    }
    return ![ROUTES.DASHBOARD, ROUTES.ORDER_TRACKING, ROUTES.PRODUCT_CATALOGUE, ROUTES.PAYMENT].includes(location.pathname);
  }

  const triggerReportViewedEvent = (path: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_VIEWED, 
      {
        report_name: ROUTE_REPORT_MAP[path],
        source: 'Navigation'
      }
    );
  }

  const routeToPage = (route: string) => {
    if(route !== ROUTES.REPORT_LIST && route !== ROUTES.DASHBOARD)
      triggerReportViewedEvent(route)
    navigate(route);
  }

  return (
    <Box className={styles.bottomNavigator}>
      {bottomNavOptions.map((navItem) => {
        const selected = isSelected(navItem.route);
        return <Box 
            className={`${styles.navItem } ${selected ? styles.selected: ''}`} 
            onClick={() => routeToPage(navItem.route)}
          >
            <NavIcon label={navItem.label} icon={navItem.icon} selected={selected} />
            {selected && (
                <div className={styles.backDropPad} />
            )}
          </Box>
      })}
    </Box>
  )
}

export default MobileNavigator;