import { Box, Container, Link, ThemeProvider, Typography, createTheme, useMediaQuery, useTheme } from "@mui/material";
import styles from './Footer.module.scss';


import XIcon from '@mui/icons-material/X';
import { FacebookOutlined } from "@mui/icons-material";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import YouTubeIcon from '@mui/icons-material/YouTube';
import vaareeLogo from "../../../images/vaaree_logo.png";
import { csx } from "helpers/utils";
import vaareeHorizontalIcon from "images/vaaree_logo_horizontal.png";


const Footer = () => {
  
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const vLogo = isMobile ? vaareeHorizontalIcon : vaareeLogo;
  
  return (
    <Box className={styles.footerWrapper} data-testid='footer-section' >
      <Box className={styles.container}>
        <Box className={styles.leftSection}>
          <Box className={styles.vaareeInfo}>
            <img src={vLogo} alt="Vaaree Logo" />
            <Typography className={styles.txt}>
              Visit <Link href="https://vaaree.com/" className={styles.link}>Vaaree.com</Link>
            </Typography>
          </Box>
          <Box className={styles.mailLink}>
            <Typography className={styles.txt}>For any help, you can email:</Typography>
            <Link className={styles.link} href="mailto:procurement@vaaree.com">procurement@vaaree.com</Link>
          </Box>
        </Box>
        <Box className={styles.rightSection} sx={{display: 'flex', alignItems: 'center'}}>
          <Typography className={csx(styles.txt, styles.socialLinkTxt)}>
            Social Media Links:
          </Typography>
          <Link href='https://www.facebook.com/vaareehome' target={'_blank'} rel={'noopener'}>
            <FacebookOutlined className={styles.icon} />
          </Link>
          <Link href='https://www.instagram.com/vaareehome/' target={'_blank'} rel={'noopener'}>
            <InstagramIcon className={styles.icon} />
          </Link>
          <Link href='https://in.linkedin.com/company/vaareehome' target={'_blank'} rel={'noopener'}>
            <LinkedInIcon className={styles.icon} />
          </Link>
          <Link href='https://www.pinterest.ca/vaareehome/' target={'_blank'} rel={'noopener'}>
            <PinterestIcon className={styles.icon} />
          </Link>
          <Link href='https://twitter.com/vaareehome' target={'_blank'} rel={'noopener'}>
            <XIcon className={styles.icon} />
          </Link>
          <Link href='https://www.youtube.com/channel/UCGW-YhVqqn11TgamrNNPVqw' target={'_blank'} rel={'noopener'}>
            <YouTubeIcon className={styles.icon}  />
          </Link>
        </Box>
      </Box>
    </Box>
)};

export default Footer;
