import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  createTheme,
  ThemeProvider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { useAppDispatch } from "../../hooks/redux-hooks";
import { login } from "../../slices/authSlice";
import {
  showNotification,
  NotificationType,
} from "../../slices/notificationSlice";

import forgeDesktopBanner from "images/forge_by_vaaree_desktop.png"
import heroBanner from "images/hero_banner_1.png"
import Footer from "../../components/atoms/Footer/Footer";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styles from "./Auth.module.scss";

const Login = () => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [portal, setPortal] = useState("forge");

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseUpDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleLogin = async () => {
    // This is only a basic validation of inputs. Improve this as needed.
    if (email && password) {
      try {
        await dispatch(
          login({
            email,
            password,
            portal
          })
        ).unwrap();
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(
        showNotification({
          message: "Please provide email and password",
          type: NotificationType.Error,
        })
      );
    }
  };

  return (
    <Box className={styles.authWrapper}>
      <Box className={styles.mainContent}>
        <Box className={styles.bannerSection}>
          <img className={styles.headerBanner} src={forgeDesktopBanner} alt="Forge desktop banner" />
          <img className={styles.heroBanner} src={heroBanner} alt="Forge login hero banner" />
        </Box>
        <Box className={styles.inputSection}>
          <Box className={styles.inputForm}>
            <Typography className={styles.heading}>Login Now</Typography>
            <Typography className={styles.subHeading}>Welcome Back</Typography>
            <TextField
              className={styles.txtInput}
              required
              autoFocus
              id="email"
              type="email"
              label="Email Id"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormControl className={styles.txtInput} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password *</InputLabel>
              <OutlinedInput
                required
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseUpDownPassword}
                      onMouseUp={handleMouseUpDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password *"
              />
            </FormControl>
            <Typography className={styles.forgotPassTxt}>
              Forgot Password? <Link href="/forgot-password" className={styles.link}>Click here</Link>
            </Typography>
            <Button
              className={styles.actionBtn}
              variant="contained"
              onClick={handleLogin}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Login;