import styles from "./Dashboard.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Link} from "react-router-dom";
import { Switch, Typography } from "@mui/material";
import LightTooltip from "../../components/atoms/LightTooltip/LightTooltip";
import { csx, getPercentVal } from "../../helpers/utils";
import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { formatCurrency, lakhFormat } from "../../helpers/formatUtils";
import CategoryChartLegend from "../../components/atoms/CategoryChartLegend/CategoryChartLegend";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import ChartHeader from "components/atoms/ChartHeader/ChartHeader";
import NamedToggle from "components/atoms/NamedToggle/NamedToggle";
import { resetFilter, setEndDateFilter, setRtoReportOrderedAtEndDateFilter, setRtoReportOrderedAtStartDateFilter, setStartDateFilter } from "slices/filtersSlice";
import { DEFAULT_BLACK_COLOR, ROUTE_REPORT_MAP } from "../../constants";


const chartColorPallete = ['#839A51' , '#DE8C2B' , '#4F76FE' , DEFAULT_BLACK_COLOR];

const SemiProgressBar = (props: any) => {
  const {value, total, label , showRevenue} = props;
  const _value = showRevenue ? (value >= 1e5 ? `₹${(value / 1e5).toFixed(2)}L` : formatCurrency(value)) : value.toString();
  return (
    <div className={styles['semi-concentric-chart']}>
      <div className={styles['full-path']}>
        <PieChart
          colors={['#D9D9D9']}
          series={[
            {
              data: [{id: 0, label: '' , value: 100}],
              innerRadius: 56,
              outerRadius: 72,
              paddingAngle: 0,
              cornerRadius: 9,
              startAngle: -90,
              endAngle: 90,
              cx: 75,
            }
          ]}
          height={144}
          width={160}
          slotProps={{
            legend: {
              hidden: true, 
            },
            popper: {
              hidden: true,
            }
          }}
        />
      </div>
      <div className={styles['progress-path']}>
        <PieChart
          colors={['#33BFFF']}
          series={[
            {
              data: [{id: 0, label: label , value: value}],
              innerRadius: 56,
              outerRadius: 72,
              paddingAngle: 0,
              cornerRadius: 9,
              startAngle: -90,
              endAngle: -90 + ((value / total) * 180),
              cx: 75,
              valueFormatter: (el) => {
                return _value;
              },
            }
          ]}
          height={144}
          width={160}
          slotProps={{
            legend: {
              hidden: true, 
            },
          }}
        />
      </div>
      <p className={styles['semi-chart-label']}>{label}</p>
      <div className={styles['semi-chart-footer']}>
        <p className={styles['value']}>{`${_value}`}</p>
        <p className={styles['percentage']}>{`(${getPercentVal(value, total).toFixed(1)}%)`}</p>
      </div>
    </div>
  )
}

const RTOSummaryChart = () => {

  const [showRevenue, setShowRevenue] = React.useState<boolean>(false);


  const dispatch = useAppDispatch();

  const startDate = useAppSelector((state) => state.filters.startDateFilter);
  const endDate = useAppSelector((state) => state.filters.endDateFilter);

  const dashboardData = useAppSelector((state) => state.dashboard);

  const handleChartClick = (chart_type: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.CHART_CLICK,
      {chart_type}
    );
  }

  const triggerReportViewedEvent = (path: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_VIEWED,
      {
        report_name: ROUTE_REPORT_MAP[path],
        source: 'Dashboard'
      }
    );
  }

  const chartName = 'rto-summary-chart';

  return (
    <div className={csx(styles['chart-report-wrapper'], styles[chartName])} onClick={() => handleChartClick('rto_summary')}>
      {dashboardData.rtoSummaryStatus === 'loading' && <p className={styles['load-err-txt']}>Loading...</p>}
      {dashboardData.rtoSummaryStatus === 'failed' && <p className={styles['load-err-txt']}>Error: {dashboardData.rtoSummaryError}</p>}
      {dashboardData.rtoSummaryStatus === 'idle' && dashboardData.rtoSummary && (
        <>
          <ChartHeader
            headerTxt={'RTO summary'}
            tooltipTxt={'Comprehensive insights of RTO shipments along with a categorized breakdown of RTOs.'}
            link={'/rto-report'}
            onClick={() => {
              dispatch(resetFilter());
              dispatch(setStartDateFilter(startDate));
              dispatch(setEndDateFilter(endDate));
              dispatch(setRtoReportOrderedAtStartDateFilter(startDate));
              dispatch(setRtoReportOrderedAtEndDateFilter(endDate));
              triggerReportViewedEvent('/rto-report')
            }}
          />
          <div className={styles['sub-heading-with-toggle']}>
            <div className={styles['label-value-section']}>
              <p className={styles['label']}>Shipment Shipped</p>
              <p className={styles['value']}>{showRevenue ? lakhFormat(dashboardData.rtoSummary.total_shipped_shipment_revenue || 0) : dashboardData.rtoSummary.total_shipped_shipments}</p>
            </div>
            <NamedToggle
              falseLabel={'Quantity'}
              trueLabel={'Revenue'}
              value={showRevenue}
              onChange={(val) => {setShowRevenue(val)}}
            />
          </div>
          <div className={styles['sub-chart-wrapper']}>
            <SemiProgressBar 
              value={showRevenue ? dashboardData.rtoSummary.total_rto_shipment_revenue : dashboardData.rtoSummary.total_rto_shipments} 
              total={showRevenue ? dashboardData.rtoSummary.total_shipped_shipment_revenue : dashboardData.rtoSummary.total_shipped_shipments} 
              label={'RTO shipments'}
              showRevenue={showRevenue}
            />
            <SemiProgressBar 
              value={showRevenue ? dashboardData.rtoSummary.total_in_transit_shipment_revenue : dashboardData.rtoSummary.total_in_transit_shipments}
              total={showRevenue ? dashboardData.rtoSummary.total_shipped_shipment_revenue : dashboardData.rtoSummary.total_shipped_shipments}
              label={'In transit shipments'}
              showRevenue={showRevenue}
            />
          </div>
          {dashboardData.rtoSummary.rto_category_summary.length > 1 && (
            <div className={styles["pie-chart-container"]}>
              <div className={styles['round-pie-chart']}>
                <PieChart
                  colors={chartColorPallete}
                  series={[
                    {
                      data: dashboardData.rtoSummary.rto_category_summary.map((item, idx) => 
                        ({id: idx, value: showRevenue ? item.revenue : item.count, label: item.category})
                      ) || [],
                      highlightScope: { faded: "global", highlighted: "item" },
                      innerRadius: 50,
                      outerRadius: 72,
                      valueFormatter: (el) => {
                        return showRevenue ? (el.value >= 1e5 ? `₹${(el.value / 1e5).toFixed(2)}L` : formatCurrency(el.value)) : el.value.toString();
                      },
                    },
                  ]}
                  margin={{left: 36}}
                  height={210}
                  width={210}
                  slotProps={{
                    legend: {
                      hidden: true, 
                    },
                  }}
                />
              </div>
              <div className={styles["pie-legend"]}>
                {dashboardData.rtoSummary.rto_category_summary.map((item, id) => {
                  return <CategoryChartLegend
                    showRevenue={showRevenue}
                    id={id}
                    total={dashboardData.rtoSummary?.total_rto_shipments || 0}
                    totalRevenue={dashboardData.rtoSummary?.total_rto_shipment_revenue || 0}
                    {...item}
                  />
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default RTOSummaryChart;