import { ReturnData } from "../../slices/returnsPenaltySlice";
import { ColumnPropType, FixedColumnPropType } from "../../components/organisms/TableManager/TableManager";
import styles from './PenaltyReports.module.scss';
import ProductThumbnail, { ProductThumbnailLite } from "../../components/atoms/ProductThumbnail/ProductThumbnail";
import { lakhFormat } from "../../helpers/formatUtils";
import MultiStepProgressPopover from "../../components/molecules/MultiStepPopover/MultiStepProgressPopover";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type ReturnsPenaltyColumnsPropType = {
  isMobile: boolean;
  showTrackingProgress: (steps: { label: string; value: string }[]) => void;
};

// Helper Component for Order Status Cell
const OrderStatusCell = ({
  row,
  isMobile,
  showTrackingProgress,
}: {
  row: ReturnData;
  isMobile: boolean;
  showTrackingProgress: (steps: { label: string; value: string }[]) => void;
}) => {
  const trackingSteps = [
    { label: 'In Transit:', value: row.return_date || '' },
    { label: 'Delivered:', value: row.return_delivered === 'Pending' ? '' : row.return_delivered },
  ];

  return (
    <div className={styles.orderAWBDetails}>
      <div className={styles.awbStatus}>
        <div>{row.return_delivered === 'Pending' ? 'In Transit' : 'Delivered'}</div>
        <div className={styles.awbNumber}>{`AWB: ${row.tracking_number || ''}`}</div>
      </div>
      {isMobile ? (
        <InfoOutlinedIcon
          fontSize="small"
          className={styles.orderStatus}
          onClick={() => showTrackingProgress(trackingSteps)}
        />
      ) : (
        <MultiStepProgressPopover steps={trackingSteps}>
          <InfoOutlinedIcon fontSize="small" className={styles.orderStatus} />
        </MultiStepProgressPopover>
      )}
    </div>
  );
};

// Fixed Column Configuration
export const fixedColumn: FixedColumnPropType<ReturnData> = {
  label: 'Product / SKU',
  accessor: 'sku_list',
  width: 120,
  maxWidth: 120,
  thresold: 170, // 290 - 120
  cell: ({ row }) => (
    <ProductThumbnailLite
      sku={row.sku_list || ''}
      src={row.thumbnail || ''}
      fbv_enabled={row.fbv_enabled}
      fbv_warehouse={row.fbv_warehouse}
    />
  ),
};

// Dynamic Columns Configuration
export const returnsPenaltyColumns = (
  props: ReturnsPenaltyColumnsPropType
): ColumnPropType<ReturnData>[] => [
  {
    label: 'Product / SKU',
    accessor: 'sku_list',
    minWidth: 290,
    width: 290,
    cell: ({ row }) => (
      <ProductThumbnail
        sku={row.sku_list || ''}
        title={row.product_title || ''}
        src={row.thumbnail || ''}
        fbv_enabled={row.fbv_enabled}
        fbv_warehouse={row.fbv_warehouse}
      />
    ),
  },
  {
    label: 'Returned Quantity',
    accessor: 'order_quantity',
    minWidth: 80,
  },
  {
    label: 'Return Date',
    accessor: 'return_date',
    minWidth: 120,
  },
  {
    label: 'Return Delivered',
    accessor: 'return_delivered',
    minWidth: 120,
  },
  {
    label: 'Reason',
    accessor: 'reason',
    minWidth: 160,
  },
  {
    label: 'Order No',
    accessor: 'order_number',
    minWidth: 80,
  },
  {
    label: 'Order Value',
    accessor: 'order_value',
    minWidth: 80,
    cell: ({ value }) => <>{lakhFormat(value)}</>,
  },
  {
    label: 'Return Status',
    accessor: 'tracking_number',
    minWidth: 150,
    cell: ({ row }) => (
      <OrderStatusCell
        row={row}
        isMobile={props.isMobile}
        showTrackingProgress={props.showTrackingProgress}
      />
    ),
  },
  {
    label: 'Penalty Amount',
    accessor: 'penalty_amount',
    minWidth: 80,
    cell: ({ value }) => <>{lakhFormat(value)}</>,
  },
];
