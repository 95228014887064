import { SalesReportDataHash } from "../../slices/salesReportSlice";
import {ColumnPropType, FixedColumnPropType} from "../../components/organisms/TableManager/TableManager";
import styles from "./SalesReport.module.scss";
import ProductThumbnail, { ProductThumbnailLite } from "../../components/atoms/ProductThumbnail/ProductThumbnail";
import { csx } from "../../helpers/utils";
import { lakhFormat } from "helpers/formatUtils";


export const fixedColumn:FixedColumnPropType<SalesReportDataHash> = {
  label: 'Product / SKU',
  accessor: 'product_variant_sku_code',
  width: 120,
  maxWidth: 120,
  thresold: (290 - 120), // calculated based on first colum of table
  cell: ({row}) => <ProductThumbnailLite 
    sku={row.product_variant_sku_code || ''}
    src={row.product_image}
    fbv_enabled={row.fbv_enabled}
    fbv_warehouse={row.fbv_warehouse}
  />
}


export const salesReportColumns = ():ColumnPropType<SalesReportDataHash>[] => [
  {
    label: 'Product / SKU',
    accessor: 'product_variant_sku_code',
    minWidth: 290,
    cell: ({row}) => <ProductThumbnail 
      sku={row.product_variant_sku_code || ''}
      title={row.product_title}
      src={row.product_image}
      fbv_enabled={row.fbv_enabled}
      fbv_warehouse={row.fbv_warehouse}
    />
  },
  {
    label: 'Category',
    accessor: 'l1_category',
    minWidth: 200,
    align: 'center',
  },
  {
    label: 'Quantity Sold',
    accessor: 'sold_quantity',
    minWidth: 350,
    sortable: true,
    align: 'center',
  },
  {
    label: 'Amount',
    accessor: 'sold_amount',
    minWidth: 250,
    sortable: true,
    cell: ({row}) => {
      return (
        <>
          {lakhFormat(row.sold_amount)}
          {row.product_inventory === 0 && (
            <span className={csx(styles.badge, styles.outOfStockBadge)}>
              Out of stock
            </span>
          )}
          {row.product_inventory > 0 && row.product_holding_days > 0 && row.product_holding_days <= 10 && (
            <span className={csx(styles.badge, styles.lowStockBadge)}>
              Low stock
            </span>
          )}
        </>
      )
    }
  },
];