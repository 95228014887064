import {ColumnPropType} from "../../components/organisms/TableManager/TableManager"
import { formatCurrency } from "../../helpers/formatUtils";
import {GSTRDataHash} from "../../slices/gstrSlice";
import styles from "./GSTRReport.module.scss";
import { checkCurrencyNA, checkNA, checkPercentNA } from "../../helpers/utils";

export const gstrReportColumns = (): ColumnPropType<GSTRDataHash>[]  =>  [
  {
    label: 'Payment Cycle',
    accessor: 'payment_cycle',
    minWidth: 150,
    cell: ({value}) => checkNA(value),
  },
  {
    label: 'Order Date',
    accessor: 'order_date',
    minWidth: 150,
    cell: ({value}) => checkNA(value),
  },
  {
    label: 'Order Number',
    accessor: 'order_number',
    minWidth: 150,
    cell: ({value}) => checkNA(value),
  },
  {
    label: 'Customer Details',
    accessor: 'unique_key',
    minWidth: 200,
    cell: ({row}) => {
      let {customer_name, customer_state} = row;
      return (
      <>
        <div className={styles["cell-row-1"]}>{checkNA(customer_name)}</div>
        <div className={styles["cell-row-2"]}>{checkNA(customer_state)}</div>
      </>
      )
    }
  },
  {
    label: 'Invoice Number',
    accessor: 'invoice_number',
    minWidth: 150,
    cell: ({value}) => checkNA(value),
  },
  {
    label: 'Order Status',
    accessor: 'order_status',
    minWidth: 150,
    cell: ({value}) => checkNA(value),
  },
  {
    label: 'SKU Code',
    accessor: 'sku',
    minWidth: 150,
    cell: ({value}) => checkNA(value),
  },
  {
    label: 'Taxable value of Goods',
    accessor: 'taxable_value_of_goods',
    minWidth: 150,
    cell: ({value}) => checkCurrencyNA(value),
  },
  {
    label: 'GST on Goods',
    accessor: 'unique_key',
    minWidth: 150,
    cell: ({row}) => {
      let {gst_on_goods, gst_rate} = row;

      return (
      <>
        <div className={styles["cell-row-1"]}>{checkCurrencyNA(gst_on_goods)}</div>
        <div className={styles["cell-row-2"]}>{checkPercentNA(gst_rate)}</div>
      </>
      )
    }
  },
  {
    label: 'Taxable value of COD',
    accessor: 'taxable_value_of_cod',
    minWidth: 150,
    cell: ({value}) => checkCurrencyNA(value),
  },
  {
    label: 'GST on COD',
    accessor: 'gst_on_cod',
    minWidth: 150,
    cell: ({value}) => checkCurrencyNA(value),
  },
  {
    label: 'Total Invoice Value',
    accessor: 'total_invoice_value',
    minWidth: 150,
    cell: ({value}) => checkCurrencyNA(value),
  },
  {
    label: 'TDS + TCS',
    accessor: 'unique_key',
    minWidth: 150,
    cell: ({row}) => {
      const {tds, tcs} = row;
      if(tds === null && tcs === null) return 'NA';
      return formatCurrency((tds || 0) + (tcs || 0));
    }
  },
  {
    label: 'Seller State',
    accessor: 'seller_state',
    minWidth: 150,
    cell: ({value}) => checkNA(value),
  },
  {
    label: 'Invoice Date',
    accessor: 'invoice_date',
    minWidth: 150,
    cell: ({value}) => checkNA(value),
  },
]