import styles from "./Dashboard.module.scss";
import { useAppSelector, useAppDispatch } from "hooks/redux-hooks";
import { Link} from "react-router-dom";
import { Typography } from "@mui/material";
import LightTooltip from "components/atoms/LightTooltip/LightTooltip";
import { resetFilter, setEndDateFilter, setOrderReportOrderCreatedAtEndDateFilter, setOrderReportOrderCreatedAtStartDateFilter, setOrderReportStatusFilter, setStartDateFilter } from "slices/filtersSlice";
import HazardIcon from "images/hazard.png";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "hooks/mixpanel_hook";
import ChartHeader from "components/atoms/ChartHeader/ChartHeader";
import { DataCol, DataRow } from "components/atoms/ChartData/ChartData";
import { csx } from "helpers/utils";
import { ROUTE_REPORT_MAP } from "../../constants";


const OpenOrdersChart = () => {
  
  const dispatch = useAppDispatch();
  const dashboardData = useAppSelector((state) => state.dashboard);

  const startDate = useAppSelector((state) => state.filters.startDateFilter);
  const endDate = useAppSelector((state) => state.filters.endDateFilter);

  const handleChartClick = (chart_type: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.CHART_CLICK,
      {chart_type}
    );
  }

  const triggerReportViewedEvent = (path: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_VIEWED,
      {
        report_name: ROUTE_REPORT_MAP[path],
        source: 'Dashboard'
      }
    );
  }

  const chartName = 'open-orders-chart';

  const too_in_range = dashboardData.openOrdersSummary?.total_open_orders_in_given_range_count || 0;
  const tsbo_in_range = dashboardData.openOrdersSummary?.total_sla_breached_orders_in_given_range_count || 0;
  const tsbop_in_range = dashboardData.openOrdersSummary?.sla_breached_order_in_given_range_percent || 0;
  const too = dashboardData.openOrdersSummary?.total_open_orders_count || 0;
  const tsbo = dashboardData.openOrdersSummary?.total_sla_breached_orders_count || 0;
  const tsbop =  dashboardData.openOrdersSummary?.sla_breached_order_count_percent || 0;

  return (
    <div className={csx(styles['chart-report-wrapper'], styles[chartName])} onClick={() => handleChartClick('order_summary')}>
      {dashboardData.openOrdersSummaryStatus === 'loading' && <p className={styles['load-err-txt']}>Loading...</p>}
      {dashboardData.openOrdersSummaryStatus === 'failed' && <p className={styles['load-err-txt']}>Error: {dashboardData.openOrdersSummaryError}</p>}
      {dashboardData.openOrdersSummaryStatus === 'idle' && dashboardData.openOrdersSummary && (
        <>
          <ChartHeader
            headerTxt={'Open Orders'}
            tooltipTxt={'These are orders that have been placed by customers but have not yet been fulfilled or shipped.'}
            link={'/order-tracking'}
            onClick={() => {
                dispatch(resetFilter());
                dispatch(setStartDateFilter(startDate));
                dispatch(setEndDateFilter(endDate));
                dispatch(setOrderReportOrderCreatedAtStartDateFilter(startDate));
                dispatch(setOrderReportOrderCreatedAtEndDateFilter(endDate));
                dispatch(setOrderReportStatusFilter('Open'));
                triggerReportViewedEvent('/order-tracking');
            }}
          />
          <div className={styles['data-row-wrapper']}>
            <p className={styles['data-row-heading']}>For selected date range</p>
            <DataRow
              children={[
                <DataCol
                  value={too_in_range}
                  label={'Total open orders'}
                />,
                <DataCol
                  valueClass={tsbo_in_range > 0 ? styles['data-value']: undefined}
                  value={
                    <>
                      {tsbo_in_range + " "}
                      <span className={styles['data-value-brackets']}>{tsbop_in_range}</span>
                      {tsbo_in_range > 0 && <img className={styles['hazard-logo']} src={HazardIcon} alt="Hazard Logo" />}
                    </>
                  }
                  label={'Orders where OTS SLA is breached'}
                />
              ]}
            />
          </div>
          <div className={styles['data-row-wrapper']}>
            <p className={styles['data-row-heading']}>For lifetime</p>
            <DataRow
              children={[
                <DataCol
                  value={too}
                  label={'Total open orders'}
                />,
                <DataCol
                  valueClass={tsbo > 0 ? styles['data-value'] : undefined}
                  value={
                    <>
                      {tsbo + " "}
                      <span className={styles['data-value-brackets']}>{tsbop}</span>
                      {tsbo > 0 && <img className={styles['hazard-logo']} src={HazardIcon} alt="Hazard Logo" /> }
                    </>
                  }
                  label={'Orders where OTS SLA is breached'}
                />
              ]}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default OpenOrdersChart;