import styles from "./Dashboard.module.scss";
import { useAppSelector, useAppDispatch } from "../../hooks/redux-hooks";
import { Link} from "react-router-dom";
import { Divider, Switch, Typography } from "@mui/material";
import LightTooltip from "../../components/atoms/LightTooltip/LightTooltip";
import { resetFilter, setEndDateFilter, setReturnsReportReturnedAtStartDateFilter, setStartDateFilter, setReturnsReportReturnedAtEndDateFilter, setExchangesReportExchangedAtStartDateFilter, setExchangesReportExchangedAtEndDateFilter } from "../../slices/filtersSlice";
import React from "react";
import CustomMultiStepProgressBar from "../../components/molecules/CustomMultiStepProgressBar/CustomMultiStepProgressBar";
import { csx, getPercentVal } from "../../helpers/utils";
import { lakhFormat } from "helpers/formatUtils";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import ChartHeader from "components/atoms/ChartHeader/ChartHeader";
import NamedToggle from "components/atoms/NamedToggle/NamedToggle";
import { RootState } from "store";
import { ROUTE_REPORT_MAP } from "../../constants";


const ReturnExchangeChart = () => {

  const dispatch = useAppDispatch();

  const [showReturnRevenue, setShowReturnRevenue] = React.useState<boolean>(false);
  
  const dashboardData = useAppSelector((state) => state.dashboard);

  const startDateFilter = useAppSelector((state: RootState) => state.filters.startDateFilter);
  const endDateFilter = useAppSelector((state: RootState) => state.filters.endDateFilter);

  const delivered_count = dashboardData.returnExchangeSummary?.delivered_count || 0;
  const delivered_revenue = dashboardData.returnExchangeSummary?.delivered_revenue || 0;
  const return_count = dashboardData.returnExchangeSummary?.return_count || 0;
  const return_loss = dashboardData.returnExchangeSummary?.return_loss || 0;
  const exchange_count = dashboardData.returnExchangeSummary?.exchange_count || 0;
  const exchange_loss = dashboardData.returnExchangeSummary?.exchange_loss || 0;
  const reasons_data = dashboardData.returnExchangeSummary?.reasons_data || [];

  const handleChartClick = (chart_type: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.CHART_CLICK,
      {chart_type}
    );
  }

  const triggerReportViewedEvent = (path: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_VIEWED,
      {
        report_name: ROUTE_REPORT_MAP[path],
        source: 'Dashboard'
      }
    );
  }

  const chartName = 'return-exchange-chart';

  return (
    <div className={csx(styles['chart-report-wrapper'], styles[chartName])} onClick={() => handleChartClick('return_exchange_summary')}>
      {dashboardData.returnExchangeSummaryStatus === 'loading' && <p className={styles['load-err-txt']}>Loading...</p>}
      {dashboardData.returnExchangeSummaryStatus === 'failed' && <p className={styles['load-err-txt']}>Error: {dashboardData.returnExchangeSummaryError}</p>}
      {dashboardData.returnExchangeSummaryStatus === 'idle' && dashboardData.returnExchangeSummary && (
        <>
          <ChartHeader
            headerTxt={'Return/Exchange Requests'}
            tooltipTxt={'Explore the breakdown of returns and exchange requests categorized by their types'}
            link={'/return-exchange-report/returns'}
            onClick={() => {
              dispatch(resetFilter());
              dispatch(setStartDateFilter(startDateFilter));
              dispatch(setEndDateFilter(endDateFilter));
              dispatch(setReturnsReportReturnedAtStartDateFilter(startDateFilter));
              dispatch(setReturnsReportReturnedAtEndDateFilter(endDateFilter));
              dispatch(setExchangesReportExchangedAtStartDateFilter(startDateFilter));
              dispatch(setExchangesReportExchangedAtEndDateFilter(endDateFilter));
              triggerReportViewedEvent('/return-exchange-report/returns')
            }}
          />

          <div className={styles['sub-heading-with-toggle']}>
            <div className={styles['label-value-section']}>
              <p className={styles['label']}>Order Item Delivered</p>
              <p className={styles['value']}>{showReturnRevenue ? lakhFormat(dashboardData.returnExchangeSummary?.delivered_revenue || 0) : dashboardData.returnExchangeSummary?.delivered_count || 0}</p>
            </div>
            <NamedToggle
              falseLabel={'Quantity'}
              trueLabel={'Value'}
              value={showReturnRevenue}
              onChange={(val) => {setShowReturnRevenue(val)}}
            />
          </div>

          <div className={styles['return-exchange-info-subheader']}>
            <div className={styles['section']}>
              <div className={styles['color-code']} style={{backgroundColor: '#33BFFF'}}/>
              <p>Return: <span>{`${showReturnRevenue ? lakhFormat(return_loss, 0) : return_count} (${getPercentVal(showReturnRevenue ? return_loss : return_count, showReturnRevenue ? delivered_revenue : delivered_count).toFixed(2)}%)`}</span></p>
            </div>
            <div className={styles['section']}>
              <div className={styles['color-code']} style={{backgroundColor: '#FEA252'}}/>
              <p>Exchange: <span>{`${showReturnRevenue ? lakhFormat(exchange_loss, 0) : exchange_count} (${getPercentVal(showReturnRevenue ? exchange_loss : exchange_count, showReturnRevenue ? delivered_revenue : delivered_count).toFixed(2)}%)`}</span></p>
            </div>
          </div>

          <CustomMultiStepProgressBar
            height={16}
            steps={[
              {color: '#33BFFF' , percent: getPercentVal(showReturnRevenue ? return_loss : return_count, showReturnRevenue ? delivered_revenue : delivered_count) },
              {color: '#FEA252' , percent: getPercentVal(showReturnRevenue ? exchange_loss : exchange_count, showReturnRevenue ? delivered_revenue : delivered_count) }
            ]}
          />

          <Divider variant="middle" />

          <div className={styles['reason-header']}>
            <p>Reason</p>
            <p>Returns</p>
            <p>Exchanges</p>
          </div>

          <div className={styles['reasons-list']}>
            {reasons_data.map((data) => {
              return (
                <>
                  <span className={styles['reason']} >{data.reason}</span>
                  <span className={styles['return-category']}>{showReturnRevenue ? lakhFormat(data.return_loss, 0) : data.return_cnt}</span>
                  <span className={styles['exchange-category']}>{showReturnRevenue ? lakhFormat(data.exchange_loss, 0) : data.exchange_cnt}</span>
                </>
              )
            })}
          </div>
        </>
      )}
    </div>
  );

}

export default ReturnExchangeChart;