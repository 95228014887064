import { Box, SwipeableDrawer, useMediaQuery, useTheme, TextField } from "@mui/material";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import styles from "./DateRangeSelector.module.scss";
import ModalBox from "components/atoms/ModalBox/ModalBox";
import { DateRangePicker, RangeKeyDict } from "react-date-range";
import { forwardRef, useEffect, useState } from "react";
import { disableFutureDates } from "helpers/utils";
import ColorButton from "components/atoms/ColorButton/ColorButton";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { NotificationType, showNotification } from "slices/notificationSlice";

type DateType = {
  startDate: Date | undefined;
  endDate: Date | undefined;
};

type PropType = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  disabledDates?: (date: Date) => boolean;
  updateDateRange: (dateRange: DateType) => void;
  header?: React.ReactNode;
  allowClear?: boolean;
} & DateType;

const DateRangeSelector: React.FC<PropType> = ({
  startDate,
  endDate,
  open,
  onClose,
  disabledDates = disableFutureDates,
  updateDateRange,
  header = null,
  allowClear = false,
  onOpen,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentDates, setCurrentDates] = useState<DateType>({
    startDate: undefined,
    endDate: undefined,
  });

  useEffect(() => {
    if (startDate === undefined || endDate === undefined) {
      setCurrentDates({
        startDate: new Date(new Date().setDate(new Date().getDate() - 15)),
        endDate: new Date(),
      });
    } else {
      setCurrentDates({ startDate, endDate });
    }
  }, [startDate, endDate]);

  const onSubmit = () => {
    if (currentDates.startDate && currentDates.endDate && currentDates.endDate < currentDates.startDate) {
      dispatch(showNotification({
        type: NotificationType.Error,
        message: 'End date should be bigger than the start date',
      }));
      return; 
    }
    onClose();
    updateDateRange({
      startDate: currentDates.startDate,
      endDate: currentDates.endDate,
    });
  };

  const onClear = () => {
    onClose();
    updateDateRange({
      startDate: undefined,
      endDate: undefined,
    });
  };

  const handleDateRangeChange = (ranges: RangeKeyDict) => {
    setCurrentDates({
      startDate: ranges.selection.startDate as Date | undefined,
      endDate: ranges.selection.endDate as Date | undefined,
    });
  };

  const handleInputChange = (field: 'startDate' | 'endDate') => (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(event.target.value);
    if (!isNaN(date.getTime())) { // Valid date
      setCurrentDates((prevDates) => ({
        ...prevDates,
        [field]: date,
      }));
    }
  };

  const handleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    open ? onOpen() : onClose();
  };

  if (isMobile) {
    return (
      <SwipeableDrawer
        anchor={"bottom"}
        open={open}
        onClose={handleDrawer(false)}
        onOpen={handleDrawer(true)}
      >
        <div className={isMobile ? styles['swipeable-mobile-drawer'] : undefined}>
          <div className={styles['picker-header']}>
            {header}
          </div>
          <DateRangePicker
            ranges={[{ startDate: currentDates.startDate, endDate: currentDates.endDate, key: 'selection' }]}
            onChange={handleDateRangeChange}
            moveRangeOnFirstSelection={false}
            showDateDisplay={false}
            showMonthAndYearPickers={false}
            showPreview={false}
            disabledDay={disabledDates}
            inputRanges={[]}
            classNames={isMobile ? {
              dateRangePickerWrapper: styles.dateRangePickerWrapper,
            } : {}}
          />
          <div className={styles.customDateInputWrapperMobile}>
            <TextField
              className={styles.customDateInput}
              InputProps={{
                style: {
                  fontSize: 'small',
                  height: '40px',
                },
                inputProps: {
                  max: currentDates.endDate ? currentDates.endDate.toISOString().substring(0, 10) : undefined,
                }
              }}
              label="Start Date"
              type="date"
              onChange={handleInputChange('startDate')}
              value={currentDates.startDate ? currentDates.startDate.toISOString().substring(0, 10) : ''}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              className={styles.customDateInput}
              InputProps={{
                style: {
                  fontSize: 'small',
                  height: '40px',
                },
                inputProps: {
                  min: currentDates.startDate ? currentDates.startDate.toISOString().substring(0, 10) : undefined,
                }
              }}
              label="End Date"
              type="date"
              onChange={handleInputChange('endDate')}
              value={currentDates.endDate ? currentDates.endDate.toISOString().substring(0, 10) : ''}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className={styles['picker-footer']}>
            {allowClear && (
              <ColorButton bgColor="#FFF" className={styles['clear-button']} variant="outlined" onClick={onClear}>
                Clear
              </ColorButton>
            )}
            <ColorButton bgColor="#3361FF" bgHoverColor="#2E58E8" className={styles['submit-button']} onClick={onSubmit}>
              Apply
            </ColorButton>
          </div>
        </div>
      </SwipeableDrawer>
    );
  }

  return (
    <ModalBox
      open={open}
      onClose={onClose}
      className={styles['picker-modal']}
    >
      <div className={isMobile ? styles['swipeable-mobile-drawer'] : undefined}>
        <div className={styles['picker-header']}>
          {header}
        </div>
        <DateRangePicker
          ranges={[{ startDate: currentDates.startDate, endDate: currentDates.endDate, key: 'selection' }]}
          onChange={handleDateRangeChange}
          moveRangeOnFirstSelection={false}
          showDateDisplay={false}
          showMonthAndYearPickers={false}
          showPreview={false}
          disabledDay={disabledDates}
          inputRanges={[]}
          classNames={isMobile ? {
            dateRangePickerWrapper: styles.dateRangePickerWrapper,
          } : {}}
        />
        <div className={styles.dateFooterWrapper}>
          <TextField
            className={styles.customDateInput}
            InputProps={{
              style: {
                fontSize: 'small',
                height: '40px',
              },
              inputProps: {
                max: currentDates.endDate ? currentDates.endDate.toISOString().substring(0, 10) : undefined,
              }
            }}
            label="Start Date"
            type="date"
            onChange={handleInputChange('startDate')}
            value={currentDates.startDate ? currentDates.startDate.toISOString().substring(0, 10) : ''}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            className={styles.customDateInput}
            InputProps={{
              style: {
                fontSize: 'small',
                height: '40px',
              },
              inputProps: {
                min: currentDates.startDate ? currentDates.startDate.toISOString().substring(0, 10) : undefined,
              }
            }}
            label="End Date"
            type="date"
            onChange={handleInputChange('endDate')}
            value={currentDates.endDate ? currentDates.endDate.toISOString().substring(0, 10) : ''}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={styles['picker-footer']}>
            {allowClear && (
              <ColorButton bgColor="#FFF" className={styles['clear-button']} variant="outlined" onClick={onClear}>
                Clear
              </ColorButton>
            )}
            <ColorButton bgColor="#3361FF" bgHoverColor="#2E58E8" className={styles['submit-button']} onClick={onSubmit}>
              Apply
            </ColorButton>
          </div>
        </div>
        
      </div>
    </ModalBox>
  );
}

export default DateRangeSelector;

type DateRangeLabelPropType = {
  onClick: () => void;
  of?: string;
  isMonthYear?: boolean;
  noDateLabel?: string;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
}

export const DateRangeLabel = forwardRef<HTMLDivElement, DateRangeLabelPropType>(
  ({ startDate, endDate, onClick, of = '', isMonthYear, noDateLabel }, ref) => {
    let label = '';
    if (startDate && endDate) {
      if (isMonthYear) {
        label = `${startDate.toLocaleString('default', { month: 'long' })}, ${startDate.getFullYear()}`;
      } else {
        label = `${startDate.getDate()} ${startDate.toLocaleString('default', { month: 'short' })} - ${endDate.getDate()} ${endDate.toLocaleString('default', { month: 'short' })}`;
      }
    } else {
      label = noDateLabel ? noDateLabel : 'NA - NA';
    }

    return (
      <Box ref={ref} className={styles.dateRangeLabel} onClick={onClick}>
        <CalendarTodayIcon className={styles.icon} />
        <Box>
          <p>{label}</p>
          {of && <p>{of}</p>}
        </Box>
      </Box>
    );
  }
);