import styles from "./Dashboard.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Link} from "react-router-dom";
import { Typography } from "@mui/material";
import LightTooltip from "../../components/atoms/LightTooltip/LightTooltip";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { csx } from "helpers/utils";
import ChartHeader from "components/atoms/ChartHeader/ChartHeader";
import { DataCol, DataRow } from "components/atoms/ChartData/ChartData";
import { resetFilter, setEndDateFilter, setStartDateFilter } from "slices/filtersSlice";
import { ROUTE_REPORT_MAP } from "../../constants";

const PaymentSummaryChart = () => {
  
  const dashboardData = useAppSelector((state) => state.dashboard);

  const dispatch = useAppDispatch();

  const startDate = useAppSelector((state) => state.filters.startDateFilter);
  const endDate = useAppSelector((state) => state.filters.endDateFilter);

  const handleChartClick = (chart_type: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.CHART_CLICK,
      {chart_type}
    );
  }

  const triggerReportViewedEvent = (path: string) => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_VIEWED,
      {
        report_name: ROUTE_REPORT_MAP[path],
        source: 'Dashboard'
      }
    );
  }

  const chartName = 'payment-summary-chart';

  return (
    <div className={csx(styles['chart-report-wrapper'], styles[chartName])} onClick={() => handleChartClick('payment_summary')}>
      {dashboardData.paymentSummaryStatus === 'loading' && <p className={styles['load-err-txt']}>Loading...</p>}
      {dashboardData.paymentSummaryStatus === 'failed' && <p className={styles['load-err-txt']}>Error: {dashboardData.paymentSummaryError}</p>}
      {dashboardData.paymentSummaryStatus === 'idle' && dashboardData.paymentSummary && (
        <>
          <ChartHeader
            headerTxt={'Payments Recon Summary'}
            tooltipTxt={'Concised overview of past and upcoming Payments.'}
            link={'/payment-report'}
            onClick={() => {
              dispatch(resetFilter());
              dispatch(setStartDateFilter(startDate));
              dispatch(setEndDateFilter(endDate));  
              triggerReportViewedEvent('/payment-report');
            }}
          />
          <DataRow 
            children={[
              <DataCol 
                value={dashboardData.paymentSummary?.last_payment_amount} 
                label={'Last Payment'}
              />,
              <DataCol 
                value={dashboardData.paymentSummary?.next_payment_amount}
                label={'Awaiting Payment'}
              />
            ]}
          />
        </>
      )}
    </div>
  );
}

export default PaymentSummaryChart;