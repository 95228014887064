import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError , AxiosRequestConfig} from "axios";
import { sortRecords } from "../helpers/utils";

export type ExchangeDataHash = {
  return_id: number,
  return_date: string,
  return_delivered: string,
  order_number: string,
  sku_list: string,
  reason: string,
  tracking_number: string,
  thumbnail: string,
  product_title: string,
  order_value: number,
  order_quantity: number,
  unique_key: number,
  fbv_enabled: boolean,
  fbv_warehouse: string,
}

type ExchangesApiResponse = {
  exchanges_data: ExchangeDataHash[]
}

type ExchangesApiState = {
  exchangesTrackingData?: ExchangesApiResponse | null;
  exchangesTrackingStatus: "idle" | "loading" | "failed";
  exchangesTrackingError: string | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof ExchangeDataHash | null;
};

const initialState: ExchangesApiState = {
  exchangesTrackingData: null,
  exchangesTrackingStatus: "loading",
  exchangesTrackingError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};

const formatRequest = (requestData: any, download:boolean = false): string => {
  let url = `/forge/returns/exchanges_tracking${download ? '.csv':''}?`;
  url += `q[requested_at_gteq]=${requestData.startDateFilter}&`;
  url += `q[requested_at_lteq]=${requestData.endDateFilter}&`;
  url += `q[delivered_at_gteq]=${requestData.exchangesReportReceivedAtStartDateFilter}&`;
  url += `q[delivered_at_lteq]=${requestData.exchangesReportReceivedAtEndDateFilter}&`;
  url += `q[order_number_eq]=${requestData.exchangesReportOrderNumberFilter}&`;
  url += `q[return_items_sku_eq]=${requestData.exchangesReportSKUFilter}&`;
  if(!!requestData.exchangesReportFbvWarehouseFilter.length){
    url += `q[return_items_order_item_vendor_location_id_null]=false&`;
  }
  for(let i = 0 ; i < requestData.exchangesReportReasonsFilter.length ; i++){
    const reason = requestData.exchangesReportReasonsFilter[i];
    url += `q[reason_in][]=${reason}&`;
  }
  url += `q[pickup_tracking_number_eq]=${requestData.exchangesReportTrackingNumberFilter}`;
  requestData.exchangesReportFbvWarehouseFilter.forEach((warehouseName: string) => {
    url += `&q[return_items_order_item_vendor_location_warehouse_name_in][]=${warehouseName}`;
  });
  return url;
}

export const exchangesTrackingApi = createAsyncThunk(
  "exchangesTrackingApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const formattedRequestUrl = formatRequest(requestData);
    try {
      const config: AxiosRequestConfig = {
        headers: headers || {},
      };
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;
      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const exchangeTrackingDownloadReportApi = createAsyncThunk(
  "exchangeTrackingDownloadReportApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData, true);
    try {
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

const exchangesTrackingSlice = createSlice({
  name: 'exchangesTracking',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof ExchangeDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.exchangesTrackingData && column){
        const sorted = sortRecords(state.exchangesTrackingData.exchanges_data, column, direction);
        state.exchangesTrackingData.exchanges_data = sorted as ExchangeDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(exchangesTrackingApi.pending, (state) => {
        state.exchangesTrackingStatus = "loading";
        state.exchangesTrackingError = null;
      })
      .addCase(
        exchangesTrackingApi.fulfilled,
        (state, action: PayloadAction<ExchangesApiResponse>) => {
          state.exchangesTrackingStatus = "idle";
          state.exchangesTrackingData = action.payload;
        }
      )
      .addCase(exchangesTrackingApi.rejected, (state, action) => {
        state.exchangesTrackingStatus = "failed";
        if (action.payload) {
          state.exchangesTrackingError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.exchangesTrackingError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = exchangesTrackingSlice.actions;
export default exchangesTrackingSlice.reducer;
