import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

export type ProductDataHash = {
  id: string | number;
  created_at: string;
  sku: string | null;
  vin: string | null;
  variant_title: string | null;
  inventory_item_cost: number | null;
  compare_at_price: number | null;
  price: number | null;
  inventory_quantity: number | null;
  fbv_enabled: boolean;
  fbv_warehouse: string;
  hsn_code: number | null;
  set_of: number | null;
  capacity: number | null;
  capacity_unit: string | null;
  weight: number | null;
  weight_unit: string | null;
  title: string | null;
  published_at: string | null;
  live: boolean;
  status: string;
  category: string | null;
  sub_category: string | null;
  image_src: string | null;
  length: number | null;
  width: number | null;
  height: number | null;
  gst_type: string | null;
  gst_percentage_available: number;
  non_replenished_at:string | null
}

type ProductCatalogueApiResponse = {
  available_categories: string[];
  product_variants: ProductDataHash[];
  active_product_variants: ProductDataHash[];
  draft_product_variants: ProductDataHash[];
}

type ProductCatalogueApiState = {
  productCatalogueData?: ProductCatalogueApiResponse | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof ProductDataHash | null;
  productCatalogueApiStatus: "idle" | "loading" | "failed";
  productCatalogueApiError: string | null;
};

const initialState: ProductCatalogueApiState = {
  productCatalogueData: null,
  productCatalogueApiStatus: "loading",
  productCatalogueApiError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};

const formatRequest = (requestData: any, download:boolean = false): string => {
  let url = `/forge/inventories/products${download ? '.csv' : ''}?`;
  url += `q[product_created_at_gteq]=${requestData.productCatalogueCreatedAtStartDateFilter}&`;
  url += `q[product_created_at_lteq]=${requestData.productCatalogueCreatedAtEndDateFilter}&`;
  url += `q[product_primary_l1_collection_cont]=${requestData.productCatalogueCategoryFilter}&`;
  url += `q[product_title_cont]=${requestData.productCatalogueTitleFilter}&`;
  url += `q[sku_eq]=${requestData.productCatalogueSkuFilter}&`;
  url += `q[hsn_code_eq]=${requestData.productCatalogueHsnCodeFilter}&`;
  url += `q[fbv_enabled_eq]=${!!requestData.productCatalogueFbvWarehouseFilter.length}&`;
  url += `q[vin_eq]=${requestData.productCatalogueVinFilter}`;
  requestData.productCatalogueFbvWarehouseFilter.forEach((warehouseName: string) => {
    url += `&fbv_warehouse[]=${warehouseName}`;
  });
  return url;
}

export const productCatalogueApi = createAsyncThunk(
  "productCatalogueApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData);
    try {
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;
      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const productCatalogueDownloadApi = createAsyncThunk(
  "productCatalogueDownloadApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    const formattedRequestUrl = formatRequest(requestData, true);
    try {
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

const productCatalogueSlice = createSlice({
  name: 'productCatalogue',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof ProductDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.productCatalogueData?.product_variants && column){
        const sorted = sortRecords(state.productCatalogueData?.product_variants, column, direction);
        state.productCatalogueData.product_variants = sorted as ProductDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(productCatalogueApi.pending, (state) => {
        state.productCatalogueApiStatus = "loading";
        state.productCatalogueApiError = null;
      })
      .addCase(
        productCatalogueApi.fulfilled,
        (state, action: PayloadAction<ProductCatalogueApiResponse>) => {
          state.productCatalogueApiStatus = "idle";
          state.productCatalogueData = {
            available_categories: action.payload.available_categories,
            product_variants: action.payload.product_variants.reverse(),
            active_product_variants: action.payload.product_variants.filter((pv) => pv.live).reverse(),
            draft_product_variants: action.payload.product_variants.filter((pv) => !pv.live).reverse(),
          }
        }
      )
      .addCase(productCatalogueApi.rejected, (state, action) => {
        state.productCatalogueApiStatus = "failed";
        if (action.payload) {
          state.productCatalogueApiError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.productCatalogueApiError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = productCatalogueSlice.actions;
export default productCatalogueSlice.reducer;
