import { Box, ClickAwayListener, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import {ColumnPropType} from "../../components/organisms/TableManager/TableManager"
import {VendorReconDataHash} from "../../slices/paymentReportSlice";
import styles from "./PaymentReport.module.scss";
import { GetApp } from "@mui/icons-material";
import { formatCurrency } from "../../helpers/formatUtils";
import { useState } from "react";
import cloud_download_icon from "images/cloud_download_icon.png";
import { csx } from "helpers/utils";
import ClickTooltip from "components/atoms/ClickTooltip/ClickTooltip";

type PaymentReportColumnsPropType = {
  downloadVendorPaymentDetailReport: (unique_key: number) => void;
  downloadVendorInvoices: (unique_key: number) => void;
}

type DownloadActionPropType = {
  label: string;
  disabled: boolean;
  paymentId: number;
  downloadAction: (paymentId: number) => void;
  disabledMessage: string;
}

const DownloadAction:React.FC<DownloadActionPropType> = (props) => {
  const {label, disabled, paymentId, downloadAction, disabledMessage} = props;
  const [showActionTooltip, setShowActionTooltip] = useState<boolean>(false);

  if (disabled) {
    return (
      <ClickTooltip
        title={<p className={styles.tooltipTitle}>{disabledMessage}</p>}
        show={showActionTooltip}
        showPopper={setShowActionTooltip}
        placement={'bottom-start'}
      >
        <Box className={csx(styles.downloadCTA, styles.disabled)} onClick={() => setShowActionTooltip(true) }>
          <img src={cloud_download_icon} alt={label} />
          <p>{label}</p>
        </Box>
      </ClickTooltip>
    )
  }

  return (
    <Box className={styles.downloadCTA} onClick={() => downloadAction(paymentId) }>
      <img src={cloud_download_icon} alt={label} />
      <p>{label}</p>
    </Box>
  );
}

export const paymentReportColumns = (props: PaymentReportColumnsPropType): ColumnPropType<VendorReconDataHash>[]  =>  [
  {
    label: 'Payment Date',
    accessor: 'payment_date',
    minWidth: 130
  },
  {
    label: 'Payment ID',
    accessor: 'payment_id',
    minWidth: 140
  },
  {
    label: 'Payment Status',
    accessor: 'status',
    minWidth: 125,
    cell: ({value}) => {
      return <>
        {value === 'Upcoming' && (
          <span className={styles["orange-pill"]}>
            {value}
          </span>
        )}
        {(value === 'Paid' || value === 'Partially Paid') && (
          <span className={styles["light-green-pill"]}>
            {value}
          </span>
        )}
        {(value !== 'Upcoming' && value !== 'Paid' && value !== 'Partially Paid') && (
          <span className={styles["gray-pill"]}>
            {value}
          </span>
        )}
      </>
    }
  },
  {
    label: 'Payment Cycle',
    accessor: 'payment_cycle',
    minWidth: 120
  },
  {
    label: 'Bank Reference ID',
    accessor: 'bank_reference_id',
    minWidth: 150
  },
  {
    label: 'Invoice Number',
    accessor: 'invoice_number',
    minWidth: 135
  },
  {
    label: 'Payment Amount',
    accessor: 'payment_amount',
    minWidth: 150,
    sortable: true,
    cell: ({value}) => {
      return <>
        {formatCurrency(value)}
      </>
    }
  },
  {
    label: 'AWB Summary',
    accessor: 'awb_count',
    minWidth: 100,
    sortable: true,
  },
  {
    label: 'Action',
    accessor: 'unique_key',
    align: 'center',
    minWidth: 210,
    cell: ({row}) => {
      const {invoices_present, report_download_link_present, unique_key} = row;
      return <Box className={styles.downloadActions}>
        <DownloadAction 
          label={'Invoice'} 
          disabled={!invoices_present} 
          paymentId={unique_key}
          downloadAction={props.downloadVendorInvoices}
          disabledMessage={`Since the invoice for the specified payment cycle has not been generated yet, you will not be able to download it.`}
        />
        <Divider orientation="vertical" />
        <DownloadAction
          label={'Payment Details'}
          disabled={!report_download_link_present}
          paymentId={unique_key}
          downloadAction={props.downloadVendorPaymentDetailReport}
          disabledMessage={`Since the payout details hasn't been generated yet, you won't be able to download it.`}
        />
      </Box>
    }
  },
]