import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";

export type MRPLabelDataHash = {
  id: string | number;
  unique_key: string | number;
  filename: string;
  status: string;
  created_at: string;
  total_count: number | null;
  success_count: number | null;
  error_count: number | null;
  processed_file_present: boolean;
  mrp_labels_file_present: boolean;
}

type MRPLabelsData = MRPLabelDataHash[];

type MRPLabelsTrackingApiState = {
  mrpLabelsData?: MRPLabelsData | null;
  mrpLabelsTrackingStatus: "idle" | "loading" | "failed";
  mrpLabelsTrackingError: string | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof MRPLabelDataHash | null;
};

const initialState: MRPLabelsTrackingApiState = {
  mrpLabelsData: null,
  mrpLabelsTrackingStatus: "loading",
  mrpLabelsTrackingError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};

export const MRPLabelsTrackingApi = createAsyncThunk(
  "MRPLabelsTrackingApi",
  async ({headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get("/forge/mdm_requests/mrp_labels_request_list", config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const downloadProcessedFile = createAsyncThunk(
  "downloadProcessedFile",
  async ({requestId, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get(`/forge/mdm_requests/${requestId}/download_processed_file`, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); 
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const downloadMRPLabels = createAsyncThunk(
  "downloadMRPLabels",
  async ({requestId, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
      responseType: 'blob',  
    };
    try {
      const response = await axiosInstance.get(`/forge/mdm_requests/${requestId}/download_mrp_label_file`, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);



export const bulkGenerateMrpLabelsApi = createAsyncThunk(
    "bulkGenerateMrpLabelsApi",
    async ({payload, headers} : any, { rejectWithValue }) => {
      const config: AxiosRequestConfig = {
        headers: headers || {},
      };
      try {
        const response = await axiosInstance.post(`/forge/mdm_requests/bulk_generate_mrp_label`, payload, config);
        const resData = response.data;
  
        return resData;
      } catch (error) {
        if (error instanceof AxiosError && error.response && error.response.status === 401) {
          return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
        }
        if (error instanceof AxiosError && error.response) {
          const errorResponse = error.response.data;
  
          return rejectWithValue(errorResponse);
        }
  
        throw error;
      }
    }
  );


const mrpLabelSlice = createSlice({
  name: 'mrpLabels',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof MRPLabelDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      if(state.mrpLabelsData && column){
        const sorted = sortRecords(state.mrpLabelsData, column, direction);
        state.mrpLabelsData = sorted as MRPLabelDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(MRPLabelsTrackingApi.pending, (state) => {
        state.mrpLabelsTrackingStatus = "loading";
        state.mrpLabelsTrackingError = null;
      })
      .addCase(
        MRPLabelsTrackingApi.fulfilled,
        (state, action: PayloadAction<MRPLabelsData>) => {
          state.mrpLabelsTrackingStatus = "idle";
          state.mrpLabelsData = action.payload;
        }
      )
      .addCase(MRPLabelsTrackingApi.rejected, (state, action) => {
        state.mrpLabelsTrackingStatus = "failed";
        if (action.payload) {
          state.mrpLabelsTrackingError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.mrpLabelsTrackingError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = mrpLabelSlice.actions;
export default mrpLabelSlice.reducer;
